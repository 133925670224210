import antdLocale from 'antd/lib/locale/ko_KR';

export const koKR = {
    ...antdLocale,
    Empty: {
        description: '검색 결과가 없습니다.',
    },
};

const language = {
    /** common */
    'Common.All': '전체',
    'Common.All.ByName': '{name} 전체',
    'Common.Login': '로그인',
    'Common.Logout': '로그아웃',
    'Common.Language': 'Language',
    'Common.Unit.Percent': '%',
    'Common.Unit.Percent.Value': '{value} %',
    'Common.Unit.Distance.M': 'm',
    'Common.Unit.Distance.Km': 'km',
    'Common.Unit.Point': '점',
    'Common.Unit.Number': '개',
    'Common.Unit.Number.Value': '{value} 개',
    'Common.Unit.Money': '円',
    'Common.Unit.Money.Value': '{value}円',
    'Common.Unit.Money.Personal': '円 (개인)',
    'Common.Unit.Money.Business': '円 (비즈)',
    'Common.Unit.MoneyPerMinute': '円/분',
    'Common.Unit.MoneyPerMinute.Value': '{value}円/분',
    'Common.Unit.Money.10000': '万円',
    'Common.Unit.Vehicle.Count': '대',
    'Common.Unit.Vehicle.Count.Value': '{value} 대',
    'Common.Unit.People.Count': '명',
    'Common.Unit.People.Count.Value': '{value} 명',
    'Common.Unit.Case.Count': '건',
    'Common.Unit.Case.Count.Value': '{value} 건',
    'Common.Unit.Seconds': '초',
    'Common.Unit.Seconds.Value': '{value} 초',
    'Common.Unit.Minute': '분',
    'Common.Unit.Minute.Value': '{value} 분',
    'Common.Unit.Hour': '시간',
    'Common.Unit.Hour.Value': '{value} 시간',
    'Common.Unit.Hour.Short': '시',
    'Common.Unit.Days': '일',
    'Common.Unit.Count': '회',
    'Common.Unit.Months': '개월',
    'Common.Unit.Months.Value': '{value} 개월',
    'Common.Unit.Month': '월',
    'Common.Unit.Year': '년',
    'Common.Unit.Floor': '층',
    'Common.Unit.MoneyPerDay': '円/1박',
    'Common.Unit.24Hours' : '24시간',
    'Common.Unit.Specify' : '지정',
    'Intro.Title.Admin': 'Fleet Management System',
    'Intro.Title.Admin.2': 'FMS',
    'Intro.Title.Client.Admin': '클라이언트 관리자 시스템',
    'Intro.Title.Client.Admin.2': '클라이언트',
    'Intro.Desc.Client.Admin':
        '업무용 차량 관리에 들어가는 비용과 시간,<br />MOCEAN Car Sharing와 함께 효율적으로 관리해보세요',
    'Common.Main.GettingStart': '시작하기',
    // 'Common.Main.Title':
    //     '단기부터?중장기까지{br}실시간으로?비교하고?간편하게?예약',
    // 'Common.Main.SubTitle': '예약에서 매출 정산까지 더욱 손쉽게 이용해보세요',
    'Common.Footer.Policy1': '이용약관',
    'Common.Footer.Policy2': '개인정보 처리방침',
    'Common.Footer.Policy1.JP': '회원약관',
    'Common.Footer.Policy2.JP': 'FMS 이용약관',
    'Common.Footer.Copyright': 'ⓒ Kia CORP. ALL RIGHTS RESERVED.',
    'Common.Footer.Address1':
        '<span className="title">사업자 </span> 기아㈜ <span className="title">대표</span> 송호성, 최준영 <span className="title">사업자등록번호</span> 119-81-02316',
    'Common.Footer.Address2':
        '<span className="title">주소</span>06797 서울특별시 서초구 헌릉로 12 (양재동) <span className="title">대표번호</span> 1833-4964',
    'Common.Inquiry.Period': '조회기간',
    'Common.Active.Period': '사용기간',
    'Common.UseDate.Period': '적용기간',
    'Common.Msg.Key': '메시지 키',
    'Common.Gender.Male': '남자',
    'Common.Gender.female': '여자',
    'Common.YN.Y': 'Y',
    'Common.YN.N': 'N',
    'Common.YN.Yes': '예',
    'Common.YN.No': '아니오',
    'Common.YN.Use': '사용',
    // 'Common.YN.Unuse': '미사용',
    'Common.YN.Notuse': '사용안함',
    'Common.YN.Exist': '있음',
    'Common.YN.NotExist': '없음',
    'Common.Use.YN': '사용여부',
    'Common.Use.YN.All': '사용여부 전체',
    'Common.YN.Show': '노출',
    'Common.YN.Hide': '숨김',
    'Common.Title.Placeholder': '제목 입력',
    'Common.Title.Use': '사용여부',
    'Common.User.Create': '등록자',
    'Common.User.Update': '수정자',
    'Common.User.Update.Id': '수정자 ID',
    'Common.User.Business': '회원사',
    'Common.User.TelPhoneNumber': '전화번호',
    'Common.User.BusinessRegNum': '사업자번호',
    'Common.User.Adress': '주소',
    'Common.Customer.Name': '고객명',
    'Common.Date.Create': '등록일시',
    'Common.Date.Update': '수정일시',
    'Common.Date.Occurrence': '발생일시',
    'Common.Date.Amount': '금액',
    'Common.Day.Create': '등록일',
    'Common.Day.Update': '수정일',
    'Common.Day.SendDay': '발송일',
    'Common.Day.SendDate': '발송일시',
    'Common.Day.SendPeriod': '발송기간',
    'Common.Date.Placeholder': 'YYYY-MM-DD',
    'Common.User.ID': '회원 ID',
    'Common.User.Name': '회원명',
    'Common.User.PhoneNumber': '연락처',
    'Common.User.Email': '이메일',
    'Common.StartDate': '시작일자',
    'Common.EndDate': '종료일자',
    'Common.BaseInfo': '기본정보',
    'Common.YN.Essential': '필수',
    'Common.YN.Select': '선택',
    'Common.Status': '상태',
    'Common.ActivationStatusCd': '승인상태',
    'Common.Day.Join': '가입일',
    'Common.Input.ByName': '{name} 입력',
    'Common.Input.Placeholder.ByName': '{name} 입력',
    'Common.Role': '권한',
    'Common.KaKao.AddressSearch': '주소 검색',
    'Common.MapView': '지도 보기',
    'Common.User.Status': '회원상태',
    'Common.Date.LastUpdate': '최종 수정일',
    'Common.MobilityManager': 'MOCEAN 관리자',
    'Common.BusinessManager': '슈퍼 관리자',
    'Common.BusinessOperator': '부서 담당자',
    'Common.Partner': '위탁사',
    'Common.Login.Agree.Title': '개인정보 보호 준수 동의',
    'Common.Login.Agree.Content.1': 'MOCEAN Car Sharing에 접속 시',
    'Common.Login.Agree.Content.2':
        '기업/부서 담당자로서 모든 임직원의 개인정보 및 이용 내역 정보를 보호할 의무가 있으며 MOCEAN Car Sharing 관리 이외의 목적으로 외부에 유출하거나 사용할 수 없음에 동의하여야 합니다.',
    'Common.Login.Agree.Content.3':
        '미동의시 접속 및 페이지 사용이 불가합니다.',
    'Common.Login.Agree.Content.4':
        '상기 내용을 확인하였으며, 개인정보 보호 준수에 동의합니다.',
    'Common.StartAdmin.TermsUse.Title': '회원약관',
    'Common.StartAdmin.PrivacyPolicy.Title': 'FMS 이용약관',
    'Common.Login.AdminCommon.Title': '관리자 관련 약관 동의',
    'Common.Week,Monday': '월',
    'Common.Week,Tuesday': '화',
    'Common.Week,Wednesday': '수',
    'Common.Week,Thursday': '목',
    'Common.Week,Friday': '금',
    'Common.Week,Saturday': '토',
    'Common.Week,Sunday': '일',
    'Common.Bnb.Alram': '알림',
    'Common.SelectMessage': '선택하세요.',
    'Common.Authed': '인증',
    'Common.UnAuthed': '미인증',
    'Common.Refresh': '새로고침',
    'Common.Success': '성공',
    'Common.Failure': '실패',
    'Common.Dash': '-',
    'Common.Colon': ':',
    'Common.Image.Insert': '이미지 삽입',
    'Common.StartDate.Placeholder': '시작일자',
    'Common.EndDate.Placeholder': '종료일자',
    'Common.StartDateTime.Placeholder': '시작일시',
    'Common.EndDateTime.Placeholder': '종료일시',
    'Common.StartTime.Placeholder': '시작시간',
    'Common.EndTime.Placeholder': '종료시간',
    'Common.Column.No': 'No.',
    'Common.Uploader.Placeholder': '이미지 또는 PDF 파일',
    'Common.Placeholder.Suffix': '입력',
    'Common.Empty.Use.Department': '이용부서 미확인',
    'Common.Uploader.Limit.10': '※ 등록 사진 용량 10MB 이하',
    'Common.Stats.divider': '|',
    'Common.EmployeeRegNum': '사번',
    'Common.Footer.JP.Address1': '고객센터(☎) : +81-120-600-066',
    'Common.Footer.JP.Address2': '고객센터(＠) : mocean_customer@hyundai.com',
    'Common.Footer.JP.Address3': '사업자 번호 : 9010401092271',
    'Common.Footer.JP.Address4':
        '소재지 : 가나가와현 요코하마시 니시구 미나토미라이 3가 6번 1호 미나토미라이 센터 빌딩 16층',
    'Common.Footer.JP.Address5':
        '현대모빌리티 재팬 Hyundai Mobility Japan 주식회사',

    /** Message */
    // 'Message.Login.Alert.System.Error':
    //     '로그인을 위한 시스템 정보가 일치하지 않습니다',
    // 'Message.Auth.Check.Alert.SystemError':
    //     '잘못된 경로로 접속하셨습니다. 다시 로그인하여 주십시오.',
    'Message.Auth.Confirm.User.Delete': '회원탈퇴 하시겠습니까?',
    'Message.Auth.Confirm.Pwd.Change': '비밀번호 변경하시겠습니까?',
    'Message.Auth.Logout.Success':
        '안전하게 로그아웃되었습니다. 안녕히 가십시오',
    'Message.Auth.Alert.Expired':
        '정보 보호 및 안전한 사용을 위해 지정된 시간이 초과되어 자동 로그아웃되었습니다. 재로그인 하세요',
    'Message.Auth.Alert.Forbidden':
        '요청하신 기능을 수행할 수 있는 권한이 없습니다.',
    'Message.Alert.Timeout':
        '수행이 지연되고 있습니다. 잠시 후 다시 확인하여 주십시오.',
    'Message.Auth.Wait': '잠시만 기다려 주세요!',
    'Message.Join.Alert.SystemError':
        '일시적으로 장애가 발생하였습니다. 잠시 후 다시 로그인하여 주십시오.',
    'Message.Join.Alert.PrivateAdmin':
        '가입승인이 완료되었습니다. 로그아웃 후 재로그인 해주세요',
    'Message.Validator.Number': '숫자만 입력하세요.',
    'Message.Validator.Email': '이메일 형태로 입력하세요.',
    'Message.Validator.MaxNumber': '최대 {compareValue}까지 입력하세요.',
    'Message.Validator.MinNumber': '최소 {compareValue}이상 입력하세요.',
    'Message.Validator.MinNumber.0': '최소 0이상 입력하세요.',
    'Message.Validator.MinNumber.1': '최소 1이상 입력하세요.',
    'Message.Validator.MaxLength': '최대 {compareValue}자까지 입력하세요.',
    'Message.Validator.MinLength': '최소 {compareValue}자 이상 입력하세요.',
    'Message.Validator.MinMoneyCardPayment1000':
        '카드결제는 최소 2円 이상 입력하세요.',
    'Message.Validator.Required': '필수로 입력해야 하는 값입니다.',
    'Message.Validator.Select': '필수로 선택하세요.',
    'Message.Validator.Hour': '0부터 23까지 입력가능합니다.',
    'Message.Validator.Day': '1부터 31까지 입력가능합니다.',
    'Message.Validator.Percent': '1부터 100까지 입력가능합니다.',
    'Message.Validator.Percent.Less.Then.100': '100%이하로 입력하세요',
    'Message.Validator.Error': '필수 항목을 모두 입력하세요.',
    'Message.Validator.Latitude': '위도를 정확하게 입력하세요.',
    'Message.Validator.Longitude': '경도를 정확하게 입력하세요.',
    'Message.Validator.Telnum': '전화번호를 정확하게 입력하세요.',
    'Message.Validator.File.Size.Limit':
        '파일은 {size}MB까지 업로드 가능합니다',
    'Message.Validator.MM': '월을 정확하게 입력하세요.',
    'Message.Validator.YY': '년을 정확하게 입력하세요.',
    'Message.Validator.MaxDecimalPoint1': '소수점 1자리까지 입력 가능합니다.',
    'Message.Send.Kakaotalk.Confirm': 'e-mail을 전송하시겠습니까?',
    'Message.Send.Kakaotalk.Fail': 'e-mail을 전송할 수 없습니다.',
    'Message.Send.Kakaotalk.Success': 'e-mail을 전송하였습니다.',
    'Message.Logout.Hello': '안녕하세요, {user}님',
    'Message.Action.Fail': '기능을 수행할 수 없습니다.',
    'Message.Get.Fail': '조회할 수 없습니다.',
    'Message.ACL.Fail': '해당 권한으로 조회할 수 없습니다.',
    'Message.Confirm.Save': '저장하시겠습니까?',
    'Message.Success.Save': '저장하였습니다.',
    'Message.Failure.Save': '저장할 수 없습니다.',
    'Message.Confirm.Create': '등록하시겠습니까?',
    'Message.Success.Create': '등록하였습니다.',
    'Message.Failure.Create': '등록할 수 없습니다.',
    'Message.Confirm.Update': '수정하시겠습니까?',
    'Message.Success.Update': '수정하였습니다.',
    'Message.Failure.Update': '수정할 수 없습니다.',
    'Message.Confirm.Delete': '삭제하시겠습니까?',
    'Message.Success.Delete': '삭제하였습니다.',
    'Message.Failure.Delete': '삭제할 수 없습니다.',
    'Message.Confirm.Deletes': '{count}건을 삭제하시겠습니까?',
    'Message.Failure.Deletes':
        '일부를 삭제할 수 없습니다. 데이터를 확인하세요.',
    'Message.Alert.ContractHistory.Selected': '계약정보 이력을 선택하세요.',
    'Message.Alert.Delete.No.Checked': '삭제할 항목을 선택하세요.',
    'Message.Alert.Update.No.Checked': '수정할 항목을 선택하세요.',
    'Message.Alert.Create.No.Checked': '등록할 항목을 선택하세요.',
    'Message.Alert.Send.No.Checked': '발송할 항목을 선택하세요.',
    'Message.Alert.Duplicate.Checked': '이미 등록된 항목이 있습니다.',
    'Message.Alert.Agree.Checked': '필수 동의 항목에 동의해야 합니다.',
    'Message.Alert.Business.Selected':
        '회원사를 선택하여 검색 후 진행해주시기 바랍니다.',
    'Message.Alert.Dialog.Business.Selected': '회원사를 선택해주세요.',
    'Message.Alert.Dialog.Reservation.Selected': '대여 번호를 선택해주세요.',
    'Message.Alert.Dialog.User.Selected': '회원을 선택해주세요.',
    'Message.Alert.Dialog.Payment.Card.Selected': '결제 카드를 선택해주세요.',
    'Message.Alert.Dialog.Charge.Card.Selected':
        '충전/제휴 카드를 선택해주세요.',
    'Message.Alert.Dialog.Charge.Selected': '상품을 선택해주세요.',
    'Message.Alert.Dialog.Hipass.Card.Selected': 'ETC Card를 선택해주세요.',
    'Message.Alert.Dialog.Vehicle.Model.Selected': '차종을 선택해주세요.',
    'Message.Alert.Dialog.Vehicle.Selected': '차량을 선택해주세요.',
    'Message.Alert.Dialog.WibleZone.Selected': '스테이션을 선택해주세요.',
    'Message.Alert.Dialog.WibleZone.Guide.Selected':
        '앱 콘텐츠를 선택해주세요.',
    'Message.Alert.Dialog.Safety.Option.Selected': '안전 옵션을 선택해주세요.',
    'Message.Alert.Dialog.Subscribe.Selected': '구독 번호를 선택해주세요.',
    'Message.Alert.Dialog.Convenience.Option.Selected':
        '편의 옵션을 선택해주세요.',
    'Message.Confirm.Upload': '업로드하시겠습니까?',
    'Message.Success.Upload': '업로드하였습니다.',
    'Message.Failure.Upload': '업로드할 수 없습니다.',
    'Message.Failure.Download': '다운로드할 수 없습니다.',
    'Message.Confirm.Download': '다운로드하시겠습니까?',
    'Message.Confirm.Send': '전송하시겠습니까?',
    'Message.Success.Send': '전송하였습니다.',
    'Message.Failure.Send': '전송할 수 없습니다.',
    'Message.No.Data': '조회된 데이터가 없습니다.',
    'Message.Default.Error': '기능을 수행 할 수 없습니다.',
    'Message.Confirm.SubscribeCancel': '구독 취소를 진행하시겠습니까?',
    'Message.Success.SubscribeCancel': '구독 취소를 요청했습니다.',
    'Message.Failure.SubscribeCancel': '구독 취소에 실패했습니다.',
    'Message.Confirm.Terms': '약관 동의가 필요합니다.',
    'Message.Failure.Charge': '요금 조회에 실패했습니다.',
    'Message.URL.Empty': '제휴 정보를 모두 입력해 주시기 바랍니다.',
    'Message.URL.Copy.Success': 'URL을 복사 했습니다.',
    'Message.URL.Copy.Fail': 'URL 복사에 실패 했습니다.',
    'Message.Coupon.UsePeriodDate.Empty':
        '발급일 기준 적용일자를 입력해주시기 바랍니다.',
    'Message.Coupon.UsePeriodDate.Empty.Reverse':
        '사용기간을 입력해주시기 바랍니다.',
    'Message.Coupon.issueEndDate.Error': '쿠폰 발행 마감일은 쿠폰 사용 마감일 이후로 설정할 수 없습니다.',
    'Message.Notice.NoData': '새로운 알림이 없습니다.',
    'Message.Myinfo.PartnerId.Empty': '역할을 선택해 주시기 바랍니다.',
    'Message.Confirm.Command': '제어 명령을 실행 하시겠습니까?',
    'Message.Confirm.Command.Value': '{value}(으)로 변경 하시겠습니까?',
    'Message.Success.Command': '제어 명령을 실행 하였습니다.',
    'Message.Failure.Command': '제어 명령을 실행 할 수 없습니다.',
    'Message.Upload.Empty.Image': '등록할 이미지를 선택해주시기 바랍니다.',
    'Message.IssuedNumber.Empty': '발행 매수를 입력해주시기 바랍니다.',
    'Message.Confirm.Approve': '승인하시겠습니까?',
    'Message.Confirm.Deny': '반려하시겠습니까?',
    'Message.Success.Approve': '승인이 완료되었습니다.',
    'Message.Success.Deny': '반려가 완료되었습니다.',
    'Message.Failure.Approve': '승인에 실패했습니다.',
    'Message.Failure.Deny': '반려에 실패했습니다.',
    'Message.VocMgmt.UserNotSearch': '먼저 회원을 검색해야 합니다.',
    'Message.VocMgmt.MessageEmpty': '문자발송 내용을 입력해야 합니다.',
    'Message.VocMgmt.RequestSend': '문자를 발송 하시겠습니까?',
    'Message.VocMgmt.RequestSend.Success': '문자를 발송하였습니다.',
    'Message.VocMgmt.RequestSend.Fail': '문자를 발송에 실패하였습니다.',
    'Message.Search.NoData': '검색 결과가 없습니다.',
    'Message.Confirm.Modify.Cancel':
        '등록을 취소하겠습니까? 이 정보는 저장되지 않습니다.',
    'Message.Confirm.Regist.Cancel':
        '수정을 취소하겠습니까? 이 정보는 저장되지 않습니다.',
    'Message.Confirm.Reservation.Cancel': '예약을 취소 하시겠습니까?',

    /** 필수값 체크 메시지 */
    'Required.String.Empty': '입력값이 누락 되었습니다.',

    /** btn */
    'Btn.Selected': '선택완료',
    'Btn.Select': '선택',
    'Btn.Select.All': '전체선택',
    'Btn.AllSelect': '전체선택', // 임시로 추가
    'Common.Btn.AllSelect': '전체선택', // 임시로 추가 - 기존 멀티셀렉트에 있는
    'Btn.Agree.No': '취소',
    'Btn.Agree.Yes': '확인',
    'Btn.Select.File': '파일 선택',
    'Btn.Edit.File': '파일 수정',
    'Btn.Create': '등록',
    'Btn.NewCreate': '신규등록',
    'Btn.Add': '추가',
    'Btn.Save': '저장',
    'Btn.NotSelect': '설정 안함',
    'Btn.Modify': '수정',
    'Btn.Modify.Complete': '수정완료',
    'Btn.Edit': '편집',
    'Btn.Setup': '설정',
    'Btn.Setup.Complete': '설정완료',
    'Btn.Delete': '삭제',
    'Btn.Cancel': '취소',
    'Btn.Confirm': '확인',
    'Btn.Reset': '초기화',
    'Btn.List': '목록',
    'Btn.View': '보기',
    'Btn.Search': '검색',
    'Btn.Close': '닫기',
    'Btn.Send': '전송',
    'Btn.Duplicate.Check': '중복확인',
    'Btn.Leave.User': '퇴사',
    'Btn.Kakaotalk': 'LINE',
    'Btn.Fax': 'FAX',
    'Btn.Reservation.Regist': '대여등록',
    'Btn.MMS': '발송',
    'Btn.MMS.Send': '문자발송',
    'Btn.VersionUp': '버전업데이트',
    'Btn.Terms.Download': '약관 다운로드',
    'Btn.Deny': '반려',
    'Btn.Approve': '승인',
    'Btn.PendingApprove': '승인대기',
    'Btn.Date.Today': '오늘',
    'Btn.Date.Week.1': '1주',
    'Btn.Date.Month.1': '1개월',
    'Btn.Date.Month.3': '3개월',
    'Btn.Date.Month.6': '6개월',
    'Btn.Download': '다운로드',
    'Btn.Upload': '업로드',
    'Btn.FileDownload': '엑셀 다운로드',
    'Btn.FileUpload': '파일 업로드',
    'Btn.ExcelDownload': '엑셀 다운로드',
    'Btn.FileSearch': '파일 찾기',
    'Btn.DocumentDownload': '양식 다운로드',
    'Btn.Modify.Business': '회원사 정보 수정',
    'Btn.Modify.SuperAdmin': '슈퍼관리자 정보 수정',
    'Btn.Modify.Contract': '계약 정보 수정',
    'Btn.Coupon.Ledger': '발행대장',
    'Btn.Coupon.Affiliate': '제휴쿠폰',
    'Btn.OneToOne.Sms.Send': '1:1 문자발송',
    'Btn.Contract.Regist': '계약등록',
    'Btn.Contract.Add': '계약추가',
    'Btn.Process': '진행',
    'Btn.Reset.Terminal': '리셋',
    'Btn.SeveralCreate': '일괄등록',
    'Btn.Lookup': '조회',
    'Btn.Cancel.Subscribe': '구독취소',
    'Btn.Create.Complete': '등록완료',
    'Btn.Detail': '상세보기',
    'Btn.Detail.Info': '상세 정보',
    'Btn.Produce': '생성',
    'Btn.Produce.URL.Copy': 'URL 복사',
    'Btn.Change': '변경',
    'Btn.Email.All': '이메일 전체 발송',
    'Btn.Email.Selected': '이메일 선택 발송',
    'Btn.Agree': '동의',
    'Btn.Agree.All': '전체 동의 합니다',
    'Btn.X': 'X',
    'Btn.Today.Close': '24시간 보지 않기',
    'Btn.Notice.More': '알림 더보기',
    'Btn.Publish': '발행',
    'Btn.Preview': '미리보기',
    'Btn.Claim': '청구하기',
    'Btn.Pay': '결제하기',
    'Btn.PenaltyClear': '면제처리',

    /** code */
    'Code.Common': '공통코드',
    'Code.Content.Editor.Type.HTML': 'html',
    'Code.Content.Editor.Type.TXT': 'text',
    'Code.Faq.FaqCategoryId.SignUP': '가입문의',
    'Code.Faq.FaqCategoryId.Reservation': '예약문의',
    'Code.Faq.FaqCategoryId.Vehicle': '차량문의',
    'Code.Faq.FaqCategoryId.Pay': '결제문의',
    'Code.Faq.FaqCategoryId.Return': '반납문의',
    'Code.Menu.SystemType.System': '시스템',
    'Code.Menu.SystemType.SystemShort': '시스템단축메뉴',
    'Code.Menu.SystemType.Provider': '공급사',
    'Code.Menu.SystemType.Business': '회원사',
    'Code.Menu.SystemType.Probiz': '공급사+회원사',
    'Code.Menu.UrlType.Internal': '내부',
    'Code.Menu.UrlType.External': '외부',
    'Code.Menu.UrlType.Group': '메뉴그룹',
    'Code.Menu.UrlType.Js': 'JS',
    'Code.Menu.SYSTEM_ALL': '전체',
    'Code.Menu.SYSTEM_WILBE_BUSINESS': '회원사',
    'Code.Menu.SYSTEM_WILBE_PROVIDER': '공급사',
    'Code.Menu.SHORT': '시스템단축',
    'Code.Menu.BUSINESS': '회원사',
    'Code.Menu.PROVIDER': '공급사',
    'Code.Menu.MenuType.Screen': '화면',
    'Code.Menu.MenuType.Tab': '탭',
    'Code.Menu.MenuType.Button': '버튼',
    'Code.Terms.Group': '그룹',
    'Code.Terms.Group.PrivateRental': '개인 대여약관',
    'Code.Terms.Group.BusinessRental': '업무 대여약관',
    'Code.Terms.Group.WibleBizAdmin': 'MOCEAN Car Sharing FMS 약관',
    'Code.Terms.Group.WibleBizApp': 'MOCEAN Car Sharing App 약관',
    'Code.Terms.Group.MemberAdmin': '클라이언트 어드민 약관',
    'Code.Terms.Type': '유형',
    'Code.Terms.Type.Join': '회원가입',
    'Code.Terms.Type.Join.ServiceUse': '이용 약관(필수)',
    'Code.Terms.Type.Join.PrivacyConsigment':
        '개인정보 수집 및 이용 동의(필수)',
    // 'Code.Terms.Type.Join.LocationBased': '위치기반 서비스 이용 동의(필수)',
    'Code.Terms.Type.Join.3rdParty':
        '개인정보(고유식별정보 포함) 제3자 제공 동의(필수)',
    'Code.Terms.Type.Join.LocationBusiness': '위치정보 이용약관(필수)',
    'Code.Terms.Type.Join.Marketing': '마케팅 정보 수신 동의(선택)',
    // 'Code.Terms.Type.Join.CarRent': '자동차대여 약관(필수)',
    // 'Code.Terms.Type.Join.PrivacyUse': '개인 정보 활용 동의(선택)',
    'Code.Terms.Type.BizRequest': '기업상담',
    'Code.Terms.Type.BizRequest.PrivacyConsigment':
        '개인정보 수집 및 이용 동의(필수)',
    'Code.Terms.Type.BizJoin': '비즈니스 프로필 생성',
    'Code.Terms.Type.BizJoin.Profile': '비즈니스 프로필 이용 약관(필수)',
    'Code.Terms.Type.BizJoin.PrivacyUse': '개인정보 수집 및 이용 동의(필수)',
    'Code.Terms.Type.Payment': '결제정보 등록',
    'Code.Terms.Type.Payment.ServiceUse':
        '블루월넛 전자지급 결제대행 서비스 이용약관(필수)',
    'Code.Terms.Type.Payment.Privacy':
        '블루월넛 개인정보 수집 및 이용 동의(필수)',
    'Code.Terms.Type.Payment.3rdParty':
        '블루월넛 개인정보 제 3자 제공 동의(필수)',
    'Code.Terms.Type.Payment.Subscription': '블루월넛 자동결제 약관(필수)',
    // 'Code.Terms.Type.Payment.Efinance': '전자 금융거래 이용약관(필수)',
    // 'Code.Terms.Type.Payment.PrivacyUse': '개인 정보 활용 동의(필수)',
    'Code.Terms.Type.Driver': '면허등록',
    'Code.Terms.Type.Driver.LicenseUse': '고유식별정보 수집 및 이용동의(필수)',
    'Code.Terms.Type.Rent': '대여',
    'Code.Terms.Type.Rent.CarRent':
        '자동차 대여 약관 (표준약관 기반 특약)(필수)',
    'Code.Terms.Type.Rent.PrivacyProcess': '개인정보 수집 및 이용 동의(필수)',
    // 'Code.Terms.Type.Rent.Insurance': '차량손해면책제도 이용약관(필수)',
    'Code.Terms.Type.Rent.3rdParty':
        '개인정보(고유식별정보 포함) 제3자 제공 동의(필수)',
    'Code.Terms.Type.Rent.LocationUse': '위치정보 이용약관(필수)',
    'Code.Terms.Type.Rent.Kia.Use': 'Kia Connect 이용약관(필수)',
    'Code.Terms.Type.Rent.Location.Business':
        'Kia Connect 위치정보사업 이용약관 (필수)',
    'Code.Terms.Type.Rent.Location.Based':
        'Kia Connect 위치기반서비스 이용약관(필수)',
    'Code.Terms.Type.Rent.Kia.Privacy': 'Kia Connect 개인정보 처리방침(필수)',
    'Code.Terms.Type.Withdrawal': '회원탈퇴',
    // 'Code.Terms.Type.Withdrawal.Secession': '탈퇴약관',
    'Code.Terms.Type.MoceanWithdrawal': '통합회원탈퇴',
    // 'Code.Terms.Type.MoceanWithdrawal.Secession': '통합회원탈퇴',
    'Code.Terms.Type.StartAdmin': '게시약관(공통)',
    // 'Code.Terms.Type.StartAdmin.AdminUse': '이용약관',
    'Code.Terms.Type.StartAdmin.AdminPrivacy': '개인정보 처리방침',
    'Code.Terms.Type.UseNoti': '로그인 이용동의 안내',
    'Code.Terms.Type.UseNoti.Noti': '로그인 이용동의 안내',
    'Code.CustomerService.StatusCd.Request': '대기',
    'Code.CustomerService.StatusCd.Processing': '처리중',
    'Code.CustomerService.StatusCd.Pending': '보류',
    'Code.CustomerService.StatusCd.Closed': '완료',
    'Code.CustomerService.StatusCd.Etc': '기타',
    'Code.Operator.ActivationStatusCd.Guest': '손님',
    'Code.Operator.ActivationStatusCd.PendingApprove': '승인대기',
    'Code.Operator.ActivationStatusCd.Deny': '승인거부',
    'Code.Operator.ActivationStatusCd.Normal': '승인',
    'Code.Operator.ActivationStatusCd.Inactive': '비활성',
    'Code.Operator.ActivationStatusCd.Blocked': '차단',
    'Code.Operator.ActivationStatusCd.Leave': '회원탈퇴',
    'Code.Operator.SystemType.B2BG': 'MOCEAN',
    'Code.Operator.SystemType.PARTNER': '위탁사',
    'Code.CodeManagement.GroupType': '코드그룹',
    'Code.CodeManagement.ValueType': '코드항목',
    'Code.MessageTemplate.SendProgram.Wiblebiz': 'MOCEAN Car Sharing',
    'Code.MessageTemplate.SendProgram.App': '앱',
    'Code.MessageTemplate.SendProgram.All': '공통',
    'Code.MessageTemplate.MessageClassCd.0': 'LINE',
    'Code.MessageTemplate.MessageClassCd.2': 'SMS/LMS',
    'Code.MessageTemplate.MessageClassCd.5': '푸시',
    'Code.MessageTemplate.MessageClassCd.6': '메일',
    'Code.MessageTemplate.MessageTypeCd.Reservation': '예약',
    'Code.MessageTemplate.MessageTypeCd.Rent': '대여',
    'Code.MessageTemplate.MessageTypeCd.Return': '반납',
    'Code.MessageTemplate.MessageTypeCd.Claim': '청구',
    'Code.MessageTemplate.MessageTypeCd.Receivable': '미수',
    'Code.MessageTemplate.MessageTypeCd.Maintenance': '차량유지',
    'Code.MessageTemplate.MessageTypeCd.Etc': '기타',
    'Code.MessageTemplate.UseYn.0': '사용중지',
    'Code.MessageTemplate.UseYn.1': '사용',
    'Code.MessageTemplate.AdmsgYn.N': '일반',
    'Code.MessageTemplate.AdmsgYn.Y': '광고',
    'Code.Trade.TradeType.Sale': '매각',
    'Code.Trade.TradeType.Scrap': '폐차',
    'Code.PassiveMessage.Manager': '담당자',
    'Code.PassiveMessage.CreateSendType.Realtime': '실시간',
    'Code.PassiveMessage.CreateSendType.Reservation': '예약',
    'Code.PassiveMessage.MsgTypeCd.Sms': 'SMS',
    'Code.PassiveMessage.MsgTypeCd.Lms': 'LMS',
    'Code.PassiveMessage.MsgTypeCd.Push': 'Push',
    'Code.PassiveMessage.MsgTypeCd.Email': 'Email',
    'Code.PassiveMessage.NoticeTypeCd.Normal': '일반',
    'Code.PassiveMessage.NoticeTypeCd.Advertising': '광고',
    'Code.PassiveMessage.NoticeTypeCd.Important': '중요',
    'Code.PassiveMessage.MsgStatus.1': '발송예약',
    'Code.PassiveMessage.MsgStatus.2': '발송취소',
    'Code.PassiveMessage.MsgStatus.3': '발송완료',
    'Code.HistoryMsgTemplate.Type.0': 'LINE',
    // 'Code.HistoryMsgTemplate.Type.2': 'SMS/LMS',
    'Code.HistoryMsgTemplate.Type.2': 'SMS',
    'Code.HistoryMsgTemplate.Type.3': 'LMS',
    'Code.HistoryMsgTemplate.Type.4': 'MMS',
    'Code.HistoryMsgTemplate.Type.5': '푸시',
    'Code.HistoryMsgTemplate.Type.6': '메일',
    'Code.HistoryMsgTemplate.MessageType.Reservation': '예약',
    'Code.HistoryMsgTemplate.MessageType.Subscribe': '구독',
    'Code.HistoryMsgTemplate.MessageType.Rent': '대여',
    'Code.HistoryMsgTemplate.MessageType.Return': '반납',
    'Code.HistoryMsgTemplate.MessageType.Claim': '청구',
    'Code.HistoryMsgTemplate.MessageType.Receivable': '미수',
    'Code.HistoryMsgTemplate.MessageType.Maintenance': '유지보수',
    'Code.HistoryMsgTemplate.MessageType.User': '사용자',
    'Code.HistoryMsgTemplate.MessageType.Noticontrol': '수동발송',
    'Code.HistoryMsgTemplate.MessageType.Etc': '기타',
    'Code.Receivable.ProfileType.Businiss': '비즈니스',
    'Code.Receivable.ProfileType.Person': '개인',
    'Code.Receivable.ReceivableType.ReserVation': '대여요금',
    'Code.Receivable.ReceivableType.Driving': '주행요금',
    'Code.Receivable.ReceivableType.Toll': '통행료',
    'Code.Receivable.ReceivableType.Penalty': '페널티',
    'Code.Program.Action.Inquiry': '읽기',
    'Code.Program.Action.Save': '쓰기',
    'Code.Program.Action.Del': '삭제',
    'Code.Program.Action.Down': '다운로드',
    'Code.Coupon.CouponTypeCd': '쿠폰타입',
    'Code.Coupon.CouponTypeCd.DOWN': '다운로드',
    'Code.Coupon.CouponTypeCd.RANDOM': '난수',
    'Code.Coupon.CouponTypeCd.CODE': '코드',
    'Code.Coupon.CouponTypeCd.REFERRAL': '추천인',
    'Code.Coupon.CouponTypeCd.SYSTEM': '시스템',
    'Code.Coupon.DiscountTypeCd': '할인타입',
    'Code.Coupon.DiscountTypeCd.TIME': '시간',
    'Code.Coupon.DiscountTypeCd.AMOUNT': '금액',
    'Code.Coupon.DiscountTypeCd.DISCOUNTRATE': '할인율',
    'Code.Coupon.DiscountTypeCd.ONEDAY': '할인율(1day+)',
    'Code.Coupon.DiscountTypeCd.FIXEDAMOUNT': '정액권',
    'Code.Coupon.Status': '상태',
    'Code.Coupon.Status.Y': '적용',
    'Code.Coupon.Status.N': '해제',
    'Code.Coupon.ProfileTypeCd': '유형',
    'Code.Coupon.ProfileTypeCd.BUSINESS': '비즈니스',
    'Code.Coupon.ProfileTypeCd.PERSONAL': '개인',
    'Code.Coupon.ProfileTypeCd.ALL': '공용',
    'Code.Coupon.Weekdays.Holidays': '평일/휴일',
    'Code.Coupon.Weekdays.WEEKDAY': '평일',
    'Code.Coupon.Weekdays.WEEKEND': '휴일',
    'Code.Coupon.Weekdays.ALL': '공용',
    'Code.Coupon.CouponClassCd': '자동발급구분',
    'Code.Coupon.CouponClassCd.Select': '자동발급 구분 선택',
    'Code.Coupon.CouponClassCd.RETURN_REWARD': '반납시 리워드쿠폰',
    'Code.Coupon.CouponClassCd.SIGN_UP': '회원가입 쿠폰',
    'Code.Coupon.CouponClassCd.REFERRAL': '친구초대 쿠폰',
    'Code.Coupon.CouponClassCd.LEVEL2_UP': '레벨업 쿠폰(Lv.2)',
    'Code.Coupon.CouponClassCd.LEVEL3_UP': '레벨업 쿠폰(Lv.3)',
    'Code.Coupon.CouponClassCd.SUPER': '레벨업 쿠폰(Super)',
    'Code.Coupon.CouponClassCd.LEVEL2_UP_MONTHLY': '레벨업 월간쿠폰(Lv.2)',
    'Code.Coupon.CouponClassCd.LEVEL3_UP_MONTHLY': '레벨업 월간쿠폰(Lv.3)',
    'Code.Coupon.CouponClassCd.SUPER _MONTHLY': '레벨업 월간쿠폰(Super)',
    'Code.Coupon.CouponClassCd.ALLIANCE': '제휴쿠폰',
    'Code.Coupon.CouponClassCd.VOC': '상담용 보상쿠폰',
    'Code.Coupon.LedgerType.Random': '난수 발행',
    'Code.Coupon.LedgerType.User': '회원 발급',
    'Code.CustomerUser.ActivationStatusCd.REGULAR': '정회원',
    'Code.CustomerUser.ActivationStatusCd.ASSOCIATE': '준회원',
    'Code.CustomerUser.ActivationStatusCd.LEAVE': '탈퇴회원',
    'Code.CustomerUser.ActivationStatusCd.GUEST': '손님',
    'Code.CustomerUser.ActivationStatusCd.G1': 'Greener Lv.1',
    'Code.CustomerUser.ActivationStatusCd.G2': 'Greener Lv.2',
    'Code.CustomerUser.ActivationStatusCd.G3': 'Greener Lv.3',
    'Code.CustomerUser.ActivationStatusCd.G0': 'Super Greener',
    'Code.Charge.ProfileTypeCd': '프로필 유형',
    'Code.Charge.ProfileTypeCd.BUSINESS': '비즈니스',
    'Code.Charge.ProfileTypeCd.PERSONAL': '개인',
    'Code.Charge.ChargeTypeCd': '요금 타입',
    'Code.Charge.ChargeTypeCd.TIMEBASE': 'Time-based',
    'Code.Charge.ChargeTypeCd.SPECIALONE': '특가(1회권)',
    'Code.Charge.ChargeTypeCd.SPECIALSUBSCRIPTION': '특가(구독권)',
    'Code.Charge.ChargeTypeCd.ONEDAYPLUS': '특가(1 Day+)',
    'Code.Charge.ChargeTypeCd.Space': '공간요금제', // JP
    'Code.Charge.ChargeTypeCd.Camping': '캠핑요금제', // JP
    'Code.Charge.ChargeGroupCd': '요금 그룹',
    'Code.Charge.ChargeGroupCd.WEEKDAY': '나이트 패키지', // JP
    'Code.Charge.ChargeGroupCd.WEEKEND': '주말 패키지', // JP
    'Code.Charge.ChargeGroupCd.SUPER': '프리미엄',
    'Code.Charge.ChargeSpecialType': '특가 요금 타입',
    'Code.Location.PersonalCd.OPEN': '개방형',
    'Code.Location.PersonalCd.CLOSED': '폐쇄형',
    'Code.Location.UseYn.Y': '운영',
    'Code.Location.UseYn.N': '미운영',
    'Code.Location.UndergroundYn.N': '지상',
    'Code.Location.UndergroundYn.Y': '지하',
    'Code.Location.IndoorYn.0': '미선택',
    'Code.Location.IndoorYn.1': '실외',
    'Code.Location.IndoorYn.2': '실내',
    'Code.Location.Radius.50': '50m',
    'Code.Location.Radius.100': '100m',
    'Code.Location.Radius.300': '300m',
    'Code.Location.Radius.500': '500m',
    'Code.AppVersion.Group.WibleBizApp': 'MOCEAN Car Sharing App',
    'Code.AppVersion.OS.iOS': 'iOS',
    'Code.AppVersion.OS.Android': '안드로이드',
    'Code.Penalty.PenaltyCategoryCd.Reservation': '예약 및 이용',
    'Code.Penalty.PenaltyCategoryCd.Return': '반납',
    'Code.Penalty.PenaltyCategoryCd.Zone': '스테이션 관리',
    'Code.Penalty.PenaltyCategoryCd.Cleaness': '차량 청결상태 유지',
    'Code.Penalty.PenaltyCategoryCd.Damage': '자동차 분실, 도난, 파손',
    'Code.Penalty.PenaltyCategoryCd.Compensation': '휴차보상료',
    'Code.Penalty.PenaltyId.A-RV01':
        '(예약자 미탑승 상태에서) 동승운전자 단독 운행 시',
    'Code.Penalty.PenaltyId.A-RV02': '제 3자 자동차운행 시',
    'Code.Penalty.PenaltyId.A-RV03': '음주 및 약물 복용 운행 시',
    'Code.Penalty.PenaltyId.B-RT01': '대여 종료시각 초과',
    'Code.Penalty.PenaltyId.B-RT02': '연료 경고등 점등 상태로 반납',
    'Code.Penalty.PenaltyId.B-RT03':
        '전기차 약정 충전량 미만 반납 (차량별 별도 정책)',
    'Code.Penalty.PenaltyId.B-RT04':
        '전기차 반납 시 충전기 미연결 (차량별 별도 정책)',
    'Code.Penalty.PenaltyId.B-RT05':
        '실내외 조명 미소등 등으로 인한 배터리 방전',
    'Code.Penalty.PenaltyId.B-RT06':
        '차량 문, 창문, 트렁크 등을 개방 반납하여 실내 훼손',
    'Code.Penalty.PenaltyId.B-RT07': '지정된 반납장소 주차 미이행',
    'Code.Penalty.PenaltyId.C-ZN01': '스테이션 내 쓰레기 무단 투기',
    'Code.Penalty.PenaltyId.C-ZN02':
        '본인 이동수단(차량, 오토바이, 자전거 등)을 스테이션에 주차',
    'Code.Penalty.PenaltyId.D-CL01':
        '차량 내 흡연 행위 발각(담배, 전자담배, 비타민 스틱 등)',
    'Code.Penalty.PenaltyId.D-CL02':
        '차량 내 쓰레기 및 오물 투기, 방치로 인해 오염, 악취 유발',
    'Code.Penalty.PenaltyId.D-CL03':
        '반려동물 동승하여 악취 유발 혹은 실내 오염 시',
    'Code.Penalty.PenaltyId.E-DM01': '대여기간 내 자동차 사고 및 파손 미신고',
    'Code.Penalty.PenaltyId.E-DM02': '자동차 임의 훼손, 수리 및 분해',
    'Code.Penalty.PenaltyId.E-DM03': '주유/충전/ETC Card 분실',
    'Code.Penalty.PenaltyId.E-DM04': '개인용도로 주유/충전/ETC Card 오남용',
    'Code.Penalty.PenaltyId.E-DM05': '자동차 키 분실 및 파손',
    'Code.Penalty.PenaltyId.E-DM06':
        '실내 부품(내비게이션,블랙박스,단말기 등) 임의/고의 파손 및 도난',
    'Code.Penalty.PenaltyId.F-CM01':
        '자동차 수리 시 혹은 수리가 불가능할 정도로 파손, 도난 시',
    'Code.Violation.Category.0': '신호위반',
    'Code.Violation.Category.1': '속도위반',
    'Code.Violation.Category.3': '주차위반',
    'Code.VocMgmt.Category.SIGN_UP': '가입/면허/탈퇴',
    'Code.VocMgmt.Category.RESERVATION': '예약/결제/취소/환불',
    'Code.VocMgmt.Category.RETURN': '운행/반납',
    'Code.VocMgmt.Category.INSURANCE': '보험/사고',
    'Code.VocMgmt.Category.WASH': '세차/주유/충전',
    'Code.VocMgmt.Category.COUPON': '쿠폰',
    'Code.VocMgmt.Category.DRIVING': '운행/반납',
    'Code.VocMgmt.Category.EVENT': '이벤트',
    'Code.VocMgmt.Category.BIZ_CONTRACT': '비즈니스 계약',
    'Code.VocMgmt.Category.ERROR': '오류',
    'Code.VocMgmt.Category.COMPLAINTS': '불만접수',
    'Code.VocMgmt.Category.ETC': '기타',
    'Code.VocMgmt.Result.WAIT': '대기중',
    'Code.VocMgmt.Result.ING': '처리중',
    'Code.VocMgmt.Result.HOLD': '보류',
    'Code.VocMgmt.Result.ETC': '기타',
    'Code.VocMgmt.Result.COMPLETED': '완료',
    'Code.VocMgmt.UserSearch.Input': '직접입력',
    'Code.VocMgmt.UserSearch.Search': '회원검색',
    'Code.Common.Y': 'Y',
    'Code.Common.N': 'N',
    'Code.Common.Possible': '가능',
    'Code.Common.Impossible': '불가능',
    'Code.CSCenterNotice.NoticeTypeCd.POLICY': '서비스정책',
    'Code.CSCenterNotice.NoticeTypeCd.MARKETING': '마케팅',
    'Code.CSCenterNotice.NoticeTypeCd.Etc': '기타',
    'Code.CSCenterNotice.MarketingTypeCd.Event': '이벤트',
    'Code.CSCenterNotice.MarketingTypeCd.Promo': '할인프로모션',
    'Code.CSCenterNotice.MarketingTypeCd.Etc': '기타',
    'Code.CSCenterNotice.MarketingPurposeCd.New': '신규회원유지',
    'Code.CSCenterNotice.MarketingPurposeCd.Sales': '매출증대',
    'Code.CSCenterNotice.MarketingPurposeCd.Etc': '기타',
    'Code.CSCenterNotice.ChannelCd.App': 'App 내 게시',
    'Code.CSCenterNotice.ChannelCd.Push': 'PUSH',
    'Code.CSCenterNotice.ChannelCd.Lms': 'LMS',
    'Code.CSCenterNotice.ChannelCd.Email': 'E-MAIL',
    'Code.CSCenterNotice.ChannelCd.Advertising': '광고',
    'Code.CSCenterNotice.ChannelCd.Etc': '기타',
    'Code.CommandType': '명령 타입',
    'Code.CommandType.0': 'UNKNOWN_COMMAND_TYPE',
    'Code.CommandType.1': '문열기',
    'Code.CommandType.2': '문잠금',
    'Code.CommandType.3': '경적',
    'Code.CommandType.4': '트렁크 오픈',
    'Code.CommandType.5': '예약정보 전송',
    'Code.CommandType.6': '반납요청',
    'Code.CommandType.7': '상태확인',
    'Code.CommandType.8': '단말기 업데이트',
    'Code.CommandType.9': '단말기 리셋',
    'Code.CommandType.10': 'fob 제어',
    'Code.CommandType.11': '시동차단 제어',
    'Code.CommandType.12': '비상등',
    'Code.CommandType.13': 'PubKey 발급',
    'Code.CommandType.14': 'SMS 발송',
    'Code.CommandType.15': '서비스허브 URL 변경',
    'Code.CommandType.16': 'GET_GASCARD',
    'Code.CommandType.41': 'AUDIO_LAN_DEFAULT',
    'Code.CommandType.43': 'PAUSE_PAUSE_STATE',
    'Code.CommandType.44': 'AUDIO_REQ_PLAY',
    'Code.CommandType.45': 'VEHICLE_FUELTYPE',
    'Code.CommandType.48': 'SET_INFO_STARTUSE',
    'Code.CommandType.52': 'SET_OVERALL',
    'Code.CommandType.53': 'PAUSE_RESUME_STATE',
    'Code.CommandState': '명령 상태',
    'Code.CommandState.1': '대기',
    'Code.CommandState.2': '요청',
    'Code.CommandState.3': '요청 실패',
    'Code.CommandState.4': '성공',
    'Code.CommandState.5': '실패',
    // 'Code.Bank.KB': 'KB국민',
    // 'Code.Bank.IBK': 'IBK기업은행',
    // 'Code.Bank.NH': 'NH농협은행',
    // 'Code.Bank.SH': '신한은행',
    // 'Code.Bank.KEB': 'KEB하나은행',
    // 'Code.Bank.WR': '우리은행',
    // 'Code.Bank.KDB': 'KDB산업은행',
    // 'Code.Bank.BS': '부산은행',
    // 'Code.Bank.DG': '대구은행',
    // 'Code.Bank.CITY': '한국씨티은행',
    // 'Code.Bank.SC': 'SC제일은행',
    // 'Code.Bank.KN': '경남은행',
    // 'Code.Bank.GJ': '광주은행',
    // 'Code.Bank.SEA': '수협은행',
    // 'Code.Bank.JB': '전북은행',
    // 'Code.Bank.JJ': '제주은행',
    // 'Code.Bank.KAKAO': '카카오뱅크',
    // 'Code.Bank.K': 'K뱅크',

    'Code.SALES_CLASS_CD': '매출 구분',
    'Code.SALES_CLASS_CD.RESERVATION': '대여요금',
    'Code.SALES_CLASS_CD.ADDITION': '연장 대여요금',
    'Code.SALES_CLASS_CD.SUBSCRIBE': '구독요금',
    'Code.SALES_CLASS_CD.DRIVING': '주행요금',
    'Code.SALES_CLASS_CD.TOLL': '통행요금',
    'Code.SALES_CLASS_CD.DELAY': '서비스 지연요금',
    'Code.SALES_CLASS_CD.PENALTY': '페널티',
    'Code.SALES_CLASS_CD.MEMBERSHIP': '멤버십요금',
    'Code.SALES_CLASS_CD.ACCIDENT': '사고처리',
    'Code.SALES_CLASS_CD.COMMISSION': '취소 수수료',
    'Code.SALES_CLASS_CD.INSURANCE': 'NOC보상 가입 요금',
    'Code.SALES_CLASS_CD.ETC': '기타',
    'Code.SALES_CLASS_CD.REFUND': '취소 후 재결제',

    'Code.PAYMENT_CLASS_CD': '결제 구분',
    'Code.PAYMENT_CLASS_CD.RESERVATION': '대여요금',
    'Code.PAYMENT_CLASS_CD.ADDITION': '연장 대여요금',
    'Code.PAYMENT_CLASS_CD.SUBSCRIBE': '구독요금',
    'Code.PAYMENT_CLASS_CD.DRIVING': '주행요금',
    'Code.PAYMENT_CLASS_CD.TOLL': '통행요금',
    'Code.PAYMENT_CLASS_CD.DELAY': '서비스 지연요금',
    'Code.PAYMENT_CLASS_CD.PENALTY': '페널티',
    'Code.PAYMENT_CLASS_CD.MEMBERSHIP': '멤버십요금',
    'Code.PAYMENT_CLASS_CD.ACCIDENT': '사고처리',
    'Code.PAYMENT_CLASS_CD.COMMISSION': '취소 수수료',
    'Code.PAYMENT_CLASS_CD.INSURANCE': 'NOC보상 가입 요금',
    'Code.PAYMENT_CLASS_CD.ETC': '기타',

    'Code.Subscribe.Status.Complete': '구독완료',
    'Code.Subscribe.Status.Start': '구독시작',
    'Code.Subscribe.Status.End': '구독종료',
    'Code.Subscribe.Status.Cancel': '구독취소',
    'Code.Subscribe.Status.Wait': '구독대기',
    'Code.Subscribe.Status.Fail': '실패',

    'Code.Subscribe.ClaimStaus': '청구상태',
    'Code.Subscribe.ClaimStaus.CLAIM': '결제 예정',
    'Code.Subscribe.ClaimStaus.CANCEL': '취소',
    'Code.Subscribe.ClaimStaus.COMPLETE': '결제 완료',

    'Code.Subscribe.PaymentStaus': '결제상태',
    'Code.Subscribe.PaymentStaus.PAYMENT': '결제',
    'Code.Subscribe.PaymentStaus.CANCEL': '결제취소',
    'Code.Subscribe.PaymentStaus.CLAIM': '청구',

    'Code.Subscribe.ExtendChargeTypeCd.OneDay': '1 Day+',
    'Code.Subscribe.ExtendChargeTypeCd.Timebased': '바로퇴근',

    'Code.Banner.BannerType.Banner': '배너',
    'Code.Banner.BannerType.Splash': '서비스 소개',
    'Code.Banner.BannerType.Popup.App': '팝업(앱)',
    'Code.Banner.BannerType.Popup.Admin': '팝업(어드민)',

    'Code.Batch.Categories.R': '기동',
    'Code.Batch.Categories.S': '중단',
    'Code.Batch.Categories.F': '실패',

    'Code.Customer.ActivationStatusCd.Associate': '준회원',
    'Code.Customer.ActivationStatusCd.Regular': '정회원',

    'Code.Business.Partner.TypeCd.AllSelect': '분류 전체',
    'Code.Business.Partner.TypeCd.Select': '분류선택',
    'Code.Business.Partner.TypeCd.VOC': '고객센터',
    // 'Code.Business.Partner.TypeCd.REFUEL': '충전/주유',
    // 'Code.Business.Partner.TypeCd.MAINTENANCE': '정비사',
    'Code.Business.Partner.TypeCd.WASH': '세차',
    // 'Code.Business.Partner.TypeCd.DEVICE': '장착기사',
    // 'Code.Business.Partner.TypeCd.INSURANCE': '보험사',
    // 'Code.Business.Partner.TypeCd.SHIPPING': '탁송업체',
    'Code.Business.Partner.TypeCd.GM': '위탁총괄',
    'Code.Business.Partner.TypeCd.FIELD_MANAGER': '필드매니저',
    'Code.Business.Partner.TypeCd.MAINTENANCE.PAID': '전담정비(보증/유상 수리)',
    'Code.Business.Partner.TypeCd.MAINTENANCE.INSURANCE':
        '전담정비(사고/보험 수리)',

    'Code.BannerMgmt.Scene.Drawer': '드로워',
    'Code.BannerMgmt.Scene.Wible': '스테이션',
    'Code.BannerMgmt.Scene.Map': '지도',
    // 'Code.BannerMgmt.Scene.Banner': '배너',
    'Code.BannerMgmt.Scene.Coupon': '쿠폰북',

    'Code.Business.Invite.AuthYn.Y': '비즈니스 프로필 생성',
    'Code.Business.Invite.AuthYn.N': '비즈니스 프로필 미생성',

    'Code.BusinessContract.LicenseTypeCd.Basic': 'BIZ 베이직',
    'Code.BusinessContract.LicenseTypeCd.Etc': '기타',
    'Code.BusinessContract.LicenseTypeCd.PremiumDiscount':
        'BIZ 프리미엄(할인형)',
    'Code.BusinessContract.LicenseTypeCd.PremiumEngagement':
        'BIZ 프리미엄(약정형)',
    'Code.BusinessContract.LicenseTypeCd.Unlimited': 'BIZ 무제한',

    'Code.Charge.SpecialPlanType.OFFWORK': '퇴출근',
    'Code.Charge.SpecialPlanType.LUNCH': '점심권',

    // 'Code.License.Type.Large': '1종대형',
    // 'Code.License.Type.MotorizedBicycle': '2종원자',
    // 'Code.License.Type.Normal': '1종보통',
    // 'Code.License.Type.SecondNormal': '2종보통',
    // 'Code.License.Type.SecondSmall': '2종소형',
    // 'Code.License.Type.Small': '1종소형',
    // 'Code.License.Type.SmallTrailer': '소형견인',
    // 'Code.License.Type.Trailer': '대형견인',
    // 'Code.License.Type.Wrecker': '구난차',

    'Code.VocMgmt.CategoryCd.Complain': '불만접수',
    'Code.VocMgmt.CategoryCd.Contact': '비즈니스계약',
    'Code.VocMgmt.CategoryCd.Error': '오류',
    'Code.VocMgmt.CategoryCd.Etc': '기타',
    'Code.VocMgmt.CategoryCd.Event': '이벤트',
    'Code.VocMgmt.CategoryCd.Fuel': '세차/주유/충전',
    'Code.VocMgmt.CategoryCd.Insurance': '보험/사고',
    'Code.VocMgmt.CategoryCd.Level': '쿠폰',
    'Code.VocMgmt.CategoryCd.Reservation': '예약/결제/취소/환불',
    'Code.VocMgmt.CategoryCd.SignUp': '가입/면허/탈퇴',

    'Code.VocMgmt.StatusCd.Closed': '완료',
    'Code.VocMgmt.StatusCd.Etc': '기타',
    'Code.VocMgmt.StatusCd.Pending': '보류',
    'Code.VocMgmt.StatusCd.Processing': '처리중',
    'Code.VocMgmt.StatusCd.Request': '대기중',

    'Code.BusinessNotification.Profile': '프로필',
    'Code.BusinessNotification.Payment': '결제',
    'Code.BusinessNotification.Accident': '사고',
    'Code.BusinessNotification.Penalty': '페널티',

    'Code.VehicleRepair.SelfVehicle.Type.1': '자차보험',
    'Code.VehicleRepair.SelfVehicle.Type.2': '일반유상',
    'Code.VehicleRepair.SelfVehicle.Type.3': '미수리',
    'Code.VehicleRepair.SelfVehicle.Type.4': '차후수리',

    'Code.Charge.PassPlanType': '구독권 특가 요금 타입',

    'Code.Maintenance.ManageType.Maintenance': '정비',
    'Code.Maintenance.ManageType.Refuel': '충전/주유',
    'Code.Maintenance.ManageType.Washing': '세차',
    'Code.Maintenance.Status.Complete': '작업완료',
    'Code.Maintenance.Status.Wait': '작업대기',
    'Code.Maintenance.Status.Work': '작업중',

    'Code.Vehicle.Transmission.Auto': '자동',
    'Code.Vehicle.Transmission.Manual': '수동',

    // 'Code.Vehicle.Mid.wible0001m': 'wible0001m',
    // 'Code.Vehicle.Mid.wible0002m': 'wible0002m',
    // 'Code.Vehicle.Mid.wible0003m': 'wible0003m',
    // 'Code.Vehicle.Mid.wible0004m': 'wible0004m',
    // 'Code.Vehicle.Mid.jecar0002m': 'jecar0002m',

    'Code.Vehicle.SafetyOptions': '안전옵션',
    'Code.Vehicle.ConvenienceOptions': '편의옵션',
    // 후방카메라, 에어백, AV시스템, 블루투스, 블랙박스, 드라이브 와이즈
    'Code.Vehicle.Options.Rearsensor': '후방카메라',
    'Code.Vehicle.Options.Airbag': '에어백',
    'Code.Vehicle.Options.AV': 'AV시스템',
    'Code.Vehicle.Options.Bluetooth': '블루투스',
    'Code.Vehicle.Options.Dashcam': '블랙박스',
    'Code.Vehicle.Options.IntelligentDrivingAssit': '드라이브 와이즈',
    // 내비게이션, 하이패스, GPS, USB, 에어컨, 열선시트, AUX, 휴대폰 무선충전시스템, 220V 인버터
    'Code.Vehicle.Options.Navigation': '내비게이션',
    'Code.Vehicle.Options.Hipass': '하이패스',
    'Code.Vehicle.Options.GPS': 'GPS',
    'Code.Vehicle.Options.USB': 'USB',
    'Code.Vehicle.Options.Airconditioner': '에어컨',
    'Code.Vehicle.Options.Heatedseat': '열선시트',
    'Code.Vehicle.Options.AUX': 'AUX',
    'Code.Vehicle.Options.Wirelesscharging': '휴대폰 무선충전시스템',
    'Code.Vehicle.Options.220V': '220V 인버터',

    'Code.Notice.Target.App': '개인',
    'Code.Notice.Target.AppBusiness': '비즈니스',
    'Code.Notice.Target.Business': '회원사(클라이언트어드민)',
    'Code.Notice.Target.Voc': '고객센터',

    'Code.Coupon.Target.BUSINESS': '비즈니스',
    'Code.Coupon.Target.': '개인',

    'Code.Role.System.Group.B2BG': 'MOCEAN',
    'Code.Role.System.Group.BUSINESS': '클라이언트 어드민',
    'Code.Role.System.Group.PARTNER': '위탁사',
    'Code.Role.System.Group.MIP_OPERATOR': '장착기사',

    'Code.Reservation.PaymentCardCd.Personal': '개인카드',
    'Code.ChargeRefuelCard.Company': '충전/제휴 카드사',
    // 'Code.ChargeRefuelCard.Company.ELECTRICITY': '전기차충전사업자',

    'Code.Extra.ChargeType.FIXED': '정액형',
    'Code.Extra.ChargeType.PROPORTION': '비례형',

    'Code.Location.contentType.STATION': '스테이션 안내',
    'Code.Location.contentType.WIFI': 'WIFI 테더링',
    'Code.Location.contentType.CAMPING': '캠핑요금제 안내',

    /** exception - common*/
    910001: '등록에 실패하였습니다.',
    910002: '수정에 실패하였습니다.',
    910003: '삭제에 실패하였습니다.',
    910004: '데이터 처리에 실패하였습니다.',
    910005: '데이터를 찾을수 없습니다.',
    920001: '로그인세션 조회에 실패하였습니다.',
    930001: '데이터 전환에 실패하였습니다.',
    930002: '데이터 전환을 찾을수 없습니다.',
    940001: '엑셀 다운로드에 실패하였습니다.',
    940002: '엑셀 업로드에 실패하였습니다.',
    940005: '10,000건을 초과하였습니다.{br}조회조건을 변경하신 후 다시 시도해 주세요.',
    950001: '파일을 찾을수 없습니다.',
    950002: '파일을 생성할수 없습니다.',
    950003: '파일을 삭제할수 없습니다.',
    950004: '파일 타입을 찾을수 없습니다.',
    950005: '파일 그룹 타입을 찾을수 없습니다.',
    950006: '파일 그룹 아이디를 찾을수 없습니다.',
    950007: '잘못된 파일 형식입니다.',
    950008: '파일을 열수 없습니다.',
    950009: '파일을 저장할 수 없습니다.',

    /** exception - handle(앞 2자리) + prossecer(뒤 4자리) (100001 는 handle 자체 에러)*/
    // 차량 관리 > 차량 등록/조회
    330002: '예약 이력이 있는 차량은 삭제 불가능합니다.',
    330003: '단말기 장착된 차량은 삭제 불가능합니다.',
    330004: '차량이 예약 중 입니다. 취소/반납 후 강제삭제 해주세요.',
    330005: '차량이 예약 중 입니다. 취소/반납 후 공급사 변경 해주세요.',

    // 차량 관리 > 차종(차량모델)
    340003: '매핑된 차량이 존재합니다.',
    340004: '매핑된 하위차종이 존재합니다.',
    340005: '매핑된 차종별 이용정보가 존재합니다.',

    // 차량 관리 > 단말기
    470001: '존재하지 않는 VIN값입니다.',

    // 차량 관리 > 유지관리/정비 이력
    550001: '작업시간이 아닙니다.',
    550002: '현재 이용중인 차량은 작업을 시작하실 수 없습니다.',
    550003: '이미 작업 예약된 차량입니다.',
    550005: '중복된 일정이 있습니다.',

    // 스테이션 관리 > 스테이션 등록/조회
    310001: '이용 불가능한 스테이션',
    310002: '사용가능한 차량이 아닙니다.',
    310003: '사용가능한 요금유형이 아닙니다.',
    310004: '계약정보를 찾을 수 없습니다.',
    310005: '요금의 보험정보를 찾을 수 없습니다.',
    310006: '차량의 스케줄 정보를 찾을 수 없습니다.',
    310008: '예약정보를 찾을 수 없습니다.',
    310009: '매핑된 차량이 있는 스테이션은 삭제 불가능합니다.',
    310010: '회원사 계약된 스테이션은 삭제 불가능합니다.',
    310012: '유효한 시간이 아닙니다.',
    310013: '예약/대여중인 차량입니다.',
    310014: '기존 예약 반납시간 지남(연장하기) 반납시간이 지났습니다. 반납 지연중에는 시간 연장을 할 수 없습니다.',
    310023: '공간요금제는 최대 3시간 까지만 대여하실수 있습니다. 확인 후 다시 예약해주세요.',
    313101: '회원사 상품정보를 찾을 수 없습니다.',
    313102: '대여정보를 찾을 수 없습니다.',
    313103: '보험정보를 찾을 수 없습니다.',

    // 시스템 관리 > 메뉴
    100001: '하위 메뉴가 존재하여 삭제할수 없습니다.',
    101001: '하위 프로그램이 존재하여 삭제할수 없습니다.',

    // 시스템 관리 > 코드
    122001: '이미 등록된 그룹키가 있습니다.',
    120001: '하위 코드가 존재하여 삭제할수 없습니다.',

    // 콘텐츠 관리 > 알림 템플릿
    151501: '템플릿코드 중복으로 등록에 실패하였습니다.',

    // 운영 관리 > 버전
    161901: '버전 중복으로 등록에 실패하였습니다.',
    // 슈퍼관리자 삭제
    512305: '회사 혹은 부서 내 담당자가 2인 이상일 경우에만, 비즈니스 프로필을 삭제가 가능합니다. 비즈니스 프로필을 삭제하고자 하시는 경우, 다른 임직원에게 권한을 이관하여 주세요.',
    // 기타 > 내 정보
    513101: '회사 혹은 부서 내 담당자가 2인 이상일 경우에만, 비즈니스 프로필 삭제가 가능 합니다. 비즈니스 프로필을 삭제하고자 하시는 경우, 다른 입직원에게 권한을 이관하여 주세요.',
    513102: '예약/대여중인 차량이 없을 경우에만, 비즈니스 프로필 삭제가 가능합니다. 비즈니스 프로필을 삭제하고자 하시는 경우, 대여 중인 차량을 반납하시거나 예약내역을 취소해 주십시오',

    // 회원가입 > 슈퍼관리자/부서담당자
    515102: '이용 권한이 없는 상태입니다. 사내 MOCEAN Car Sharing 담당자 또는 고객센터에 문의하세요.',
    515103: '관리자에게 문의하세요.[코드: {errorCode}]',
    // 내정보 > 권한이관
    515106: '관리자는 최소 1명 이상 지정되어야합니다.',

    // 회원/부서 관리 > 회원
    480001: '등록된 카드가 1개 이상인 경우 정회원 전환이 가능합니다.',
    // 740001: '대여중 정보가 존재하여 면허정보 삭제가 불가합니다.',
    // 740001: '대여중인 차량이 없을 경우에만, 면허정보 삭제가 가능합니다. 면허정보를 삭제하고자 하시는 경우, 대여 중인 차량을 반납해 주십시오.',
    740001: '대여 이용중에는 면허 정보 삭제가 불가능합니다.',

    // 회원/부서 관리 > 임직원 조회
    523101: '회사 혹은 부서 내 담당자가 2인 이상일 경우에만, 비즈니스 프로필 삭제가 가능 합니다. 비즈니스 프로필을 삭제하고자 하시는 경우, 다른 입직원에게 권한을 이관하여 주세요.',
    523102: '예약/대여중인 차량이 없을 경우에만, 비즈니스 프로필 삭제가 가능합니다. 비즈니스 프로필을 삭제하고자 하시는 경우, 대여 중인 차량을 반납하시거나 예약내역을 취소해 주십시오',

    // 회원/부서 관리 > 임직원 등록
    523105: '잘못된 이메일 양식입니다.',
    523111: '중복된 이메일입니다.',

    //회원/부서 관리 > 부서 관리
    382901: '중복된 부서명입니다.',
    382902: '회사 혹은 부서 내 담당자가 2인 이상일 경우에만, 비즈니스 프로필 삭제가 가능 합니다. 비즈니스 프로필을 삭제하고자 하시는 경우, 다른 입직원에게 권한을 이관하여 주세요.',
    382903: '예약/대여중인 차량이 없을 경우에만, 비즈니스 프로필 삭제가 가능합니다. 비즈니스 프로필을 삭제하고자 하시는 경우, 대여 중인 차량을 반납하시거나 예약내역을 취소해 주십시오',

    // 회원/부서 관리 > 페널티
    233502: '해당 페널티 항목과 위반사항으로 이미 등록된 정책이 존재합니다.',

    // 매출/정산 관리 > 쿠폰
    350001: '최대 발행할 수 있는 쿠폰 수량을 초과하였습니다.',
    350002: '이미 발행되어 수정 불가합니다.',
    350003: '이미 발행되어 삭제 불가합니다.',
    350004: '중복 발행할 수 있는 쿠폰 수량을 초과하였습니다.',
    354106: '쿠폰코드가 중복되어 등록이 불가합니다.',
    350021: '쿠폰정보가 맞지 않아, 발행할 수 없습니다.',
    410003: '제휴쿠폰 번호가 중복되어 등록이 불가합니다.',
    410004: '제휴쿠폰 이 이미 발급되었습니다.',

    // 대여/결제관리 > 대여관리
    403000: '이미 작업중인 요청입니다.',
    403001: '차량정보를 찾을 수 없습니다.',
    403002: '차량모델을 찾을 수 없습니다.',
    403003: '스테이션 정보를 찾을 수 없습니다.',
    403004: '스테이션 정보를 찾을 수 없습니다.',
    403005: '회원사 정보를 찾을 수 없습니다.',
    403006: '회원사 계약 정보를 찾을 수 없습니다.',
    403007: '대여회원 정보를 찾을 수 없습니다.',
    403008: '탑승자 정보를 찾을 수 없습니다.',
    403009: '대여회원의 운전면허증 정보를 찾을 수 없습니다.',
    403010: '탑승자의 운전면허증 정보를 찾을 수 없습니다.',
    403011: '대여회원의 운전면허증 유효성 검사에 실패하였습니다.',
    403012: '탑승자의 운전면허증 유효성 검사에 실패하였습니다.',
    403013: '요금제 정보를 찾을 수 없습니다.',
    403014: '대여 키 생성에 실패하였습니다.',
    403015: '예약할 수 없습니다.(403015)', // CORE
    403016: '결제카드 정보를 찾을 수 없습니다.',
    403017: '결제에 실패하였습니다.',
    403018: '취소 가능한 상태가 아닙니다.',
    403019: '연장 가능한 상태가 아닙니다.',
    403020: '이미 예약된 대여 정보가 있습니다.',
    403021: '제어이력이 존재하여 취소할 수 없습니다.',
    403022: '차량위치와 반납위치가 다릅니다.',
    403023: '차량상태 정보를 찾을 수 없습니다.',
    403024: '차량상태조건을 찾을 수 없습니다.',
    403025: '차량의 주행거리 정보를 찾을 수 없습니다.',
    403026: '차량의 잔여연료 정보를 찾을 수 없습니다.',
    403027: '차량 위치를 찾을 수 없습니다.',
    403028: '차량 위치값이 0입니다.',
    403029: '차량의 잔여 충전량을 찾을 수 없습니다.',
    403030: '회원의 페널티 정보가 생성되지 않았습니다.',
    403031: '회원의 리워드 쿠폰이 생성되지 않았습니다.',
    403032: '차량의 단말기 정보를 찾을 수 없습니다.',
    403033: '반납명령을 전송하는 데에 실패했습니다.',
    403034: '차량의 MID 정보를 찾을 수 없습니다.',
    403035: '청구정보 등록에 실패하였습니다.',
    403036: '미납금이 존재합니다.',
    403038: '부서정보를 찾을 수 없습니다.',
    403039: '반납 가능한 상태가 아닙니다.',
    403040: '이용 가능한 상태가 아닙니다.',
    403041: '차량이 미반납 상태입니다.',
    403042: '결제취소에 실패하였습니다.',
    403044: '예약종료시간이 지나 예약을 취소할 수 없습니다.',
    403045: '결합상품 키 생성에 실패하였습니다.',
    403046: '차량의 요청시간에 다른 예약이 존재합니다.',
    403047: '동승자가 페널티로 인해 이용중지 상태입니다.',
    403048: '차량의 충전기가 연결되어 있지 않습니다.',
    403049: '대여회원이 페널티로 인해 이용중지 상태입니다.',
    403050: '대여요금이 맞지 않습니다.',
    403051: '보험료가 맞지 않습니다.',
    403052: '쿠폰 할인금액이 맞지 않습니다.',
    403053: '멤버쉽 할인금액이 맞지 않습니다.',
    403054: '요금 할인금액이 맞지 않습니다.',
    403055: '결제 요금이 맞지 않습니다.',
    403056: '사용자에게 할당된 쿠폰이 아닙니다.',
    403057: '회원사 카드정보가 존재하지 않습니다.',
    403078: '시작일시가 기존 예약의 시작일시보다 큽니다.',
    403079: '반납일시가 기존 예약의 반납일시보다 작습니다.',
    403080: '잔여 약정 시간이 부족합니다.',
    403081: '예약요청시간이 남은 약정시간보다 큽니다.',
    403082: '예약상태가 변경되어 처리할 수 없습니다.',
    403083: '처리할 수 있는 시간이 아닙니다.', // [예약] 노쇼(시간검증)
    403084: '제어이력이 존재하여 처리할 수 없습니다.',
    403092: '대여회원의 여권 유효성 검사에 실패하였습니다.',
    403093: '탑승자의 여권 정보를 찾을 수 없습니다.',
    403094: '탑승자의 여권 유효성 검사에 실패하였습니다.',
    403095: '대여회원의 여권 정보를 찾을 수 없습니다.',

    503046: '차량에 예약정보가 있습니다.',
    503050: '대여요금이 맞지 않습니다.',
    503051: '보험료가 맞지 않습니다.',
    503052: '쿠폰 할인금액이 맞지 않습니다.',
    503053: '멤버쉽 할인금액이 맞지 않습니다.',
    503054: '요금 할인금액이 맞지 않습니다.',
    503055: '결제 요금이 맞지 않습니다.',
    503056: '사용자에게 할당된 쿠폰이 아닙니다.',
    503059: '대여가 이미 시작되었습니다.',
    503337: '차량의 스케줄 정보가 등록되어 있지 않습니다.',

    //대여/구독관리 > 구독취소
    503018: '취소가 가능한 상태가 아닙니다.',
    503316: '카드정보를 찾을 수 없습니다.',
    503060: '이미 취소된 구독입니다.',

    // 대여/결제관리 > 결제카드
    454002: '전자지급결제대행서비스(PG) 연동 오류로 등록 할 수 없습니다.',
    454003: '이미 등록된 카드가 존재하여 등록 할 수 없습니다.',
    454004: '유효하지 않은 카드 BIN 정보가 존재하여 등록 할 수 없습니다.',
    454005: '해당카드 사용불가 가맹점(타카드 사용요망)이므로 등록 할 수 없습니다.',
    454006: '전자지급결제대행서비스(PG) 연동 오류(카드정보 확인 필요)로 등록 할 수 없습니다.',
    454007: '전자지급결제대행서비스(PG) 연동 오류(카드정보 확인 필요)로 등록 할 수 없습니다.', // 임시 문구(BID 생성 오류)
    454008: '이미 등록된 카드가 존재하여 등록 할 수 없습니다.', // 임시 문구(중복 카드)

    // 대여/결제관리 > 결제카드
    413412: '삭제할 수 없습니다. 활성화 중인 대여 내역이 존재합니다.',
    413413: '삭제할 수 없습니다. 활성화 중인 구독 내역이 존재합니다.',

    // 콘텐츠관리 > 약관
    270002: '삭제할 수 없습니다. 이용중인 약관이 존재합니다.',

    //회원사 계약 등록
    292901: '슈퍼관리자는 5명까지 등록 가능합니다.',
    224201: '계약기간이 중복된 계약이 존재합니다.',

    //사고수리이력 등록
    490001: '이미 사고수리가 등록된 상태입니다.',

    // 콘텐츠관리 > 알림메시지 > 수동메시지
    690001: '엑셀 내 수신 대상이 없습니다.',

    // 차량 관리 > 공급사 등록/조회
    180001: '매핑된 차량이 있는 공급사는 삭제 불가능합니다.',

    // 운영 관리 > 위탁사
    610001: '매핑된 유지관리/정비 이력이 있는 위탁사는 삭제 불가능합니다.',

    // 정산
    757501: '결제된 월별/별도정산은 취소할 수 없습니다.',
    729999: '정산에 오류가 있습니다. 미수금/결제 내역을 확인해주세요',

    /** etc */
    undefined: ' ', // 지우지 마세요

    /** title */
    'Title.Dashboard': '대시보드',
    'Title.VehicleMgmt': '차량 관리',
    'Title.VehicleMgmt.Vehicle': '차량 등록/조회',
    'Title.Business.VehicleMgmt.Vehicle': '차량 조회',
    'Title.VehicleMgmt.Vehicle.Create': '차량 정보 등록',
    'Title.VehicleMgmt.Vehicle.Edit': '차량 정보 수정',
    'Title.VehicleMgmt.Vehicle.View': '차량 상세 정보',
    'Title.VehicleMgmt.VehicleMonitoring': '차량 관제',
    'Title.VehicleMgmt.VehicleDailyOperation': '차량 운행일지',
    'Title.VehicleMgmt.VehicleModel': '차종(차량모델)',
    'Title.VehicleMgmt.VehicleModel.Create': '차종(차량모델) 등록',
    'Title.VehicleMgmt.VehicleModel.Edit': '차종(차량모델) 수정',
    'Title.VehicleMgmt.VehicleModel.View': '차종(차량모델) 상세',
    'Title.VehicleMgmt.Terminal': '단말기',
    'Title.VehicleMgmt.Terminal.List': '단말기 관리',
    'Title.VehicleMgmt.Terminal.Create': '단말기 등록',
    'Title.VehicleMgmt.Terminal.Edit': '단말기 수정',
    'Title.VehicleMgmt.Terminal.View': '단말기 상세 정보',
    'Title.VehicleMgmt.Terminal.Verification': '단말기 장착 검증',
    'Title.VehicleMgmt.Terminal.Verification.History': '단말기 장착 검증 이력',
    'Title.VehicleMgmt.Refuel': '충전/주유 이력',
    'Title.VehicleMgmt.VehicleRepair': '사고수리 이력',
    'Title.VehicleMgmt.VehicleRepair.Regist': '등록',
    'Title.VehicleMgmt.VehicleRepair.Info': '사고 정보',
    'Title.VehicleMgmt.VehicleRepair.Rate': '상대차량 및 과실 정보',
    'Title.VehicleMgmt.VehicleRepair.Coverage': '보험 정보',
    'Title.VehicleMgmt.VehicleRepair.Work': '작업 내역',
    'Title.VehicleMgmt.VehicleRepair.Detail': '상세 정보',
    'Title.VehicleMgmt.VehicleRepair.Edit': '수정 정보',
    'Title.VehicleMgmt.VehicleRepair.Rental': '대여 정보',
    'Title.VehicleMgmt.VehicleRepair.Photo': '상세 사진 정보',
    'Title.VehicleMgmt.VehicleMaintenance': '유지관리/정비 이력',
    'Title.VehicleMgmt.VehicleMaintenance.Regist': '등록',
    'Title.VehicleMgmt.VehicleMaintenance.View': '상세 정보',
    'Title.VehicleMgmt.ControlHistory': '차량제어 이력',
    'Title.VehicleMgmt.ControlHistory.Server': '차량제어 이력(서버)',
    'Title.VehicleMgmt.ControlHistory.Server.Detail':
        '차량제어 이력(서버) 상세',
    'Title.VehicleMgmt.ControlHistory.Ble': '차량제어 이력(BLE)',
    'Title.VehicleMgmt.ControlHistory.Ble.Detail': '차량제어 이력(BLE) 상세',
    'Title.VehicleMgmt.ControlHistory.SubTitle.ReqDtl': '요청내역',
    'Title.VehicleMgmt.Trade': '매각',
    'Title.VehicleMgmt.Trade.Create': '처리 항목 등록',
    'Title.VehicleMgmt.Trade.Edit.Sale': '매각 정보',
    'Title.VehicleMgmt.Trade.Edit.Scrap': '폐차 정보',
    'Title.VehicleMgmt.Trade.Edit.SubTitle': '등록 정보',
    'Title.VehicleMgmt.Maintenance': '유지관리/정비 이력',
    'Title.VehicleMgmt.Provider': '공급사 등록/조회',
    'Title.VehicleMgmt.Provider.Create': '공급사 등록',
    'Title.VehicleMgmt.Provider.View': '공급사 상세',
    'Title.VehicleMgmt.Provider.Edit': '공급사 수정',
    'Title.VehicleMgmt.Provider.Info': '공급사 등록 정보',
    'Title.VehicleMgmt.Provider.VehicleInfo': '공급사 차량 정보',

    'Title.WibleZoneMgmt': '스테이션 관리',
    'Title.WibleZoneMgmt.Location': '스테이션 등록/조회',
    'Title.WibleZoneMgmt.Location.Create': '스테이션 등록',
    'Title.WibleZoneMgmt.Location.Edit': '스테이션 수정',
    'Title.WibleZoneMgmt.Location.View': '스테이션 상세',
    'Title.WibleZoneMgmt.Vehicle.Search': '스테이션 차량 리스트',
    'Title.WibleZoneMgmt.Garage': '차고지',

    'Title.ReservationMgmt': '대여/결제 관리',
    'Title.ReservationMgmt.ReservationSchedule': '대여 현황',
    'Title.ReservationMgmt.Reservation.Mgmt': '대여 관리',
    'Title.ReservationMgmt.Reservation.Mgmt.View': '대여 관리 상세',
    'Title.ReservationMgmt.Reservation.Mgmt.Create': '대여 등록',
    'Title.ReservationMgmt.Reservation': '대여 내역',
    'Title.ReservationMgmt.Reservation.View': '대여 내역 상세',
    'Title.ReservationMgmt.ReservationHistory': '대여 내역',
    'Title.ReservationMgmt.ReservationHistory.View': '대여 내역 상세',
    'Title.ReservationMgmt.ReservationPayment': '결제 내역',
    'Title.ReservationMgmt.ReservationPayment.Detail': '결제 내역 상세',
    'Title.ReservationMgmt.Card': '결제 카드',
    'Title.ReservationMgmt.Card.Create': '결제 카드 등록',
    'Title.ReservationMgmt.Card.Edit': '결제 카드 수정',
    'Title.ReservationMgmt.Card.View': '결제 카드 상세',
    'Title.ReservationMgmt.Card.Modify': '결제카드 변경',
    'Title.ReservationMgmt.Transit': '하이패스 이력',
    'Title.ReservationMgmt.Subscribe': '구독 내역',
    'Title.ReservationMgmt.Subscribe.Card.Modify': '카드 변경',
    'Title.ReservationMgmt.Subscribe.Detail': '구독내역 상세',
    'Title.ReservationMgmt.Hipass': 'ETC Card',
    'Title.ReservationMgmt.Hipass.Create': 'ETC Card 등록',
    'Title.ReservationMgmt.Hipass.Update': 'ETC Card 수정',
    'Title.ReservationMgmt.Hipass.View': 'ETC Card 상세',
    'Title.ReservationMgmt.AssociateCard': '충전/제휴 카드',
    'Title.ReservationMgmt.AssociateCard.Create': '충전/제휴 카드 등록',
    'Title.ReservationMgmt.AssociateCard.Update': '충전/제휴 카드 수정',
    'Title.ReservationMgmt.AssociateCard.View': '충전/제휴 카드 상세',

    'Title.BusinessMgmt': '회원사 관리',
    'Title.BusinessMgmt.Business': '회원사/계약관리',
    'Title.BusinessMgmt.Contract': '회원사/계약',
    'Title.BusinessMgmt.Regist': '회원사/계약 등록',
    'Title.BusinessMgmt.Regist.Info': '회원사 등록 정보',
    'Title.BusinessMgmt.Regist.SuperAdmin': '슈퍼관리자 등록',
    'Title.BusinessMgmt.Regist.Contract': '계약등록 정보',
    'Title.BusinessMgmt.ContractHist': '계약정보 이력',
    'Title.BusinessMgmt.Detail': '회원사/계약 상세',
    'Title.BusinessMgmt.Detail.SuperAdmin': '슈퍼관리자 정보',
    'Title.BusinessMgmt.Detail.Contract': '계약정보 이력',
    'Title.BusinessMgmt.Modify': '회원사/계약 수정',
    'Title.BusinessMgmt.CustomerService': '기업 상담이력',
    'Title.BusinessMgmt.CustomerService.Edit': '기업 상담이력 수정',
    'Title.BusinessMgmt.CustomerService.Create': '기업 상담등록',
    'Title.ContractMgmt': '계약관리',
    'Title.BusinessContract': '계약',
    'Title.BusinessContract.Product': '계약상품',
    'Title.BusinessContract.Detail': '계약상품 조회',
    'Title.BusinessContract.BusinessInfo': '회원사 정보',
    'Title.BusinessContract.ContractInfo': '계약정보',

    'Title.UserMgmt': '회원/부서 관리',
    'Title.UserMgmt.Business': '임직원/부서 관리',
    'Title.UserMgmt.Customer': '회원',
    'Title.UserMgmt.Customer.User': '회원',
    'Title.UserMgmt.Customer.WithdrawalUser': '탈퇴 회원',
    'Title.UserMgmt.Customer.Detail': '회원 상세',
    'Title.UserMgmt.Customer.Modify': '회원 수정',
    'Title.UserMgmt.Customer.SubTitle.PersonalInfo': '개인 정보',
    'Title.UserMgmt.Customer.SubTitle.MemberShipInfo': '운전면허 정보',
    'Title.UserMgmt.Customer.SubTitle.PassportInfo': '여권 정보',
    'Title.UserMgmt.Customer.SubTitle.BusinessProfileInfo':
        '비즈니스 프로필 정보',
    'Title.UserMgmt.Customer.SubTitle.PaymentUseDtl': '결제 및 이용내역',
    'Title.UserMgmt.Customer.SubTitle.CouponVocMgmtDtl': '쿠폰 및 상담내역',
    'Title.UserMgmt.Customer.SubTitle.Etc': '기타',
    'Title.UserMgmt.BusinessMember': '임직원 조회',
    'Title.UserMgmt.BusinessInvite': '임직원 등록',
    'Title.UserMgmt.BusinessInvite.Create': '등록 회원',
    'Title.UserMgmt.BusinessInvite.List': '등록 현황',
    'Title.UserMgmt.Department': '부서',
    'Title.UserMgmt.Department.Create': '부서 등록',
    'Title.UserMgmt.Department.View': '부서 상세',
    'Title.UserMgmt.Department.Edit': '부서 수정',
    'Title.UserMgmt.BusinessOperator': '회원사 권한',
    'Title.UserMgmt.BusinessOperator.View': '회원사 권한 상세',
    'Title.UserMgmt.BusinessOperator.Edit': '회원사 권한 수정',
    'Title.UserMgmt.BisinessManager': '어드민 권한',
    'Title.UserMgmt.BisinessManager.View': '어드민 권한 상세',
    'Title.UserMgmt.BisinessManager.Edit': '어드민 권한 수정',
    'Title.UserMgmt.Penalty': '페널티',
    'Title.UserMgmt.Penalty.List': '페널티 내역',
    'Title.UserMgmt.Penalty.View': '페널티 상세',
    'Title.UserMgmt.Penalty.Create': '페널티 등록',
    'Title.UserMgmt.Penalty.Update': '페널티 수정',
    'Title.UserMgmt.PenaltyPolicy': '페널티 정책',
    'Title.UserMgmt.PenaltyPolicy.View': '페널티 정책 상세',
    'Title.UserMgmt.PenaltyPolicy.Policy1': '페널티 정책1',
    'Title.UserMgmt.PenaltyPolicy.Create': '페널티 정책 등록',
    'Title.UserMgmt.PenaltyPolicy.Update': '페널티 정책 수정',
    'Title.UserMgmt.LicenseCheck': '운전면허 검증이력',
    'Title.UserMgmt.LicenseCheck.Detail': '운전면허 검증이력 상세',
    'Title.UserMgmt.LicenseInfo': '운전면허 조회',
    'Title.UserMgmt.LicenseInfo.View': '운전면허 상세',

    'Title.SalesMgmt': '매출/정산 관리',
    'Title.SalesMgmt.Sales': '매출',
    'Title.SalesMgmt.Sales.Detail': '상세 정보',
    'Title.SalesMgmt.Sales.Coupon': '쿠폰 매출',
    'Title.SalesMgmt.CouponSales': '쿠폰 매출',
    'Title.SalesMgmt.CouponSales.View': '쿠폰 매출 상세',
    'Title.SalesMgmt.Calculation': '정산',
    'Title.SalesMgmt.Calculation.Create': '정산 등록',
    'Title.SalesMgmt.Calculation.Edit': '정산 수정',
    'Title.SalesMgmt.Calculation.View': '정산 상세',
    'Title.SalesMgmt.Violation': '과태료',
    'Title.SalesMgmt.Violation.List': '과태료 관리',
    'Title.SalesMgmt.Violation.Regist': '과태료 등록',
    'Title.SalesMgmt.Violation.Modify': '과태료 수정',
    'Title.SalesMgmt.Coupon': '쿠폰',
    'Title.SalesMgmt.Coupon.Detail.Create': '쿠폰 등록',
    'Title.SalesMgmt.Coupon.Detail.Edit': '쿠폰 수정',
    'Title.SalesMgmt.Coupon.Detail.View': '쿠폰 상세',
    'Title.SalesMgmt.Receivable': '미수금',
    'Title.SalesMgmt.Receivable.View': '미수금 관리 상세',

    'Title.ProductMgmt': '상품 관리',
    'Title.ProductMgmt.Charge': '요금제',
    'Title.ProductMgmt.Charge.Create': '요금제 등록',
    'Title.ProductMgmt.Charge.View': '요금제 상세',
    'Title.ProductMgmt.Insurance': 'NOC보상',
    'Title.ProductMgmt.Insurance.Create': 'NOC보상 등록',
    'Title.ProductMgmt.Insurance.View': 'NOC보상 상세',
    'Title.ProductMgmt.Extra': '부가상품',
    'Title.ProductMgmt.Extra.View': '부가상품 상세',
    'Title.ProductMgmt.Extra.Create': '부가상품 등록',
    'Title.ProductMgmt.Extra.Edit': '부가상품 수정',
    'Title.ProductMgmt.Extra.Info': '기본정보',
    'Title.ProductMgmt.Extra.CargeInfo': '요금정보',

    'Title.ContentMgmt': '콘텐츠 관리',
    'Title.ContentMgmt.Notice': '공지/이벤트',
    'Title.ContentMgmt.Notice.SubName': '공지사항',
    'Title.ContentMgmt.Notice.Create': '공지 등록',
    'Title.ContentMgmt.Terms': '약관',
    'Title.ContentMgmt.Terms.Create': '약관 등록',
    'Title.ContentMgmt.Terms.Edit': '약관 수정',
    'Title.ContentMgmt.Terms.View': '약관 상세',
    'Title.ContentMgmt.MsgTemplate': '알림템플릿',
    'Title.ContentMgmt.MsgTemplate.Create': '알림템플릿 등록',
    'Title.ContentMgmt.MsgTemplate.View': '알림템플릿 상세',
    'Title.ContentMgmt.MsgTemplate.Edit': '알림템플릿 수정',
    'Title.ContentMgmt.NotiOrHistory': '알림/메시지 발송 이력',
    'Title.ContentMgmt.NotiOrHistory.History': '알림 발송 이력',
    'Title.ContentMgmt.NotiOrHistory.History.View':
        '알림/메시지 발송 이력 상세',
    'Title.ContentMgmt.NotiOrHistory.PassiveMessage': '수동 메시지',
    'Title.ContentMgmt.NotiOrHistory.PassiveMessage.Create': '수동 메시지 등록',
    'Title.ContentMgmt.NotiOrHistory.PassiveMessage.Edit': '수동 메시지 수정',
    'Title.ContentMgmt.NotiOrHistory.PassiveMessage.View': '수동 메시지 상세',
    'Title.ContentMgmt.Banner': '서비스 소개/배너/팝업',
    'Title.ContentMgmt.Banner.Regist': '서비스 소개/배너/팝업 등록',
    'Title.ContentMgmt.Banner.Modify': '서비스 소개/배너/팝업 상세',
    'Title.ContentMgmt.ModelInfo': '차종별 이용안내',
    'Title.ContentMgmt.ModelInfo.Create': '차종별 이용안내 등록',
    'Title.ContentMgmt.ModelInfo.Modify': '차종별 이용안내 수정',
    'Title.ContentMgmt.GuideLocation': '앱 콘텐츠 관리',
    'Title.ContentMgmt.GuideLocation.Create': '앱 콘텐츠 등록',
    'Title.ContentMgmt.GuideLocation.Modify': '앱 콘텐츠 수정',

    'Title.WorkMgmt': '운영 관리',
    'Title.WorkMgmt.Operator': '운영자',
    'Title.WorkMgmt.BusinessPartner': '위탁사',
    'Title.WorkMgmt.BusinessPartner.Create': '위탁사 등록',
    'Title.WorkMgmt.BusinessPartner.Edit': '위탁사 수정',
    'Title.WorkMgmt.BusinessPartner.View': '위탁사 상세',
    'Title.WorkMgmt.Admin': '어드민',
    'Title.WorkMgmt.AppVersion': '버전',
    'Title.WorkMgmt.AppVersion.Create': '버전 등록',
    'Title.WorkMgmt.AppVersion.Edit': '버전 수정',
    'Title.WorkMgmt.AppVersion.View': '버전 상세',
    'Title.WorkMgmt.GreenerPolicy': 'Greener 정책',
    'Title.WorkMgmt.Provider': '공급사',

    'Title.CSCenter': '고객센터',
    'Title.CSCenter.FaqMgmt': 'FAQ',
    'Title.CSCenter.FaqMgmt.Regist': 'FAQ 등록',
    'Title.CSCenter.FaqMgmt.Modify': 'FAQ 수정',
    'Title.CSCenter.VocMgmt': '1:1상담',
    'Title.CSCenter.VocMgmt.Regist': '상담내역 등록',
    'Title.CSCenter.VocMgmt.Modify': '상담내역 수정',
    'Title.CSCenter.ContactManager': '담당자 문의',
    'Title.CSCenter.Contact': '담당자 문의',
    'Title.CSCenter.Notice': '고객센터 공지',
    'Title.CSCenter.Notice.Regist': '고객센터 공지 등록',
    'Title.CSCenter.Notice.Modify': '고객센터 공지 수정',
    'Title.CSCenter.Notice.SubName': '공지사항',
    'Title.CSCenter.Notice.Create': '공지/이벤트 등록정보',

    'Title.SystemMgmt': '시스템 관리',
    'Title.SystemMgmt.Role': '권한',
    'Title.SystemMgmt.Role.Access': '접근권한',
    'Title.SystemMgmt.Code': '코드',
    'Title.SystemMgmt.Menu': '메뉴',
    'Title.SystemMgmt.Menu.Tree': '메뉴관리',
    'Title.SystemMgmt.Program': '프로그램',
    'Title.SystemMgmt.Batch': '배치',
    'Title.SystemMgmt.Batch.Schedule': '배치 스케쥴',
    'Title.SystemMgmt.Batch.Job.List': '스케쥴러 목록조회',
    'Title.SystemMgmt.Batch.Job': '스케쥴러 목록조회',
    'Title.SystemMgmt.Batch.Job.History': '배치 이력 조회',

    'Title.MyInfo': '내 정보',
    'Title.UserPolicy': '약관',

    'Title.Popup.Business.Search': '회원사 검색',
    'Title.Popup.User.Search': '회원 검색',
    'Title.Popup.VehicleModel.Search': '차종 검색',
    'Title.Popup.Vehicle.Search': '차량 검색',
    'Title.Popup.WibleZone.List': '스테이션 리스트',
    'Title.Popup.WibleZone.Search': '스테이션 검색',
    'Title.Popup.WibleZone.Selected': '선택된 스테이션',
    'Title.Popup.WibleZone.Guide.Search': '이용 상세안내 검색',
    'Title.Popup.Station.Guide.Search': '콘텐츠 검색',
    'Title.Popup.Station.Guide': '앱 콘텐츠',
    'Title.WebEditor.InsertLink': 'Custom insert hyperlink',
    'Title.WebEditor.ViewHtml': 'View html',
    'Title.WebEditor.InsertImage': 'Insert image',
    'Title.ImagePreview.Dialog': '상세 사진 정보',

    // 웹 에디터
    'WebEditor.InsertLink.Title': 'Title',
    'WebEditor.InsertLink.Href': 'Web address',
    'WebEditor.InsertLink.Target': 'New Window',
    'WebEditor.InsertLink.Target.Label': 'Open link in new window.',
    'WebEditor.InsertImage.Tab.File': 'Upload',
    'WebEditor.InsertImage.Tab.URL': 'By URL',
    'WebEditor.InsertImage.ImageFile': 'Image',
    'WebEditor.InsertImage.Src': 'Web Address',
    'WebEditor.InsertImage.AltText': 'Alternate Text',
    'WebEditor.InsertImage.Title': 'Title',
    'WebEditor.InsertImage.Width': 'Width',
    'WebEditor.InsertImage.Height': 'Height',

    //Report Designer
    'RD.Button.OperationHistory': '운행일지 리포트',
    'RD.Button.Transfer': '이관 신청서',
    'RD.Button.Contract': '임대차계약서',
    'RD.Button.Contract.Business': '임대차계약서(비즈)',
    'RD.Button.Contract.Personal': '임대차계약서(개인)',
    'RD.Button.ReservationPayment': '결제 내역 출력',
    'RD.Button.Usagebill.Business': '이용내역서(비즈)',
    'RD.Button.Usagebill.Personal': '이용내역서(개인)',
    'RD.Button.Customerbill': '고객 청구서 출력',
    'RD.Title.OperationHistory': '업무용 승용차 운행기록부',
    'RD.Title.Transfer': '이관신청서',
    'RD.Title.Contract': '임대차 계약서',
    'RD.Title.Usagebill': '이용내역서',
    'RD.Title.Customerbill': '고객 청구서',
    'RD.Usagebill.DateTimeDiff.Format': '총 {hour}시간 {minute}분',
    'RD.Usagebill.Payment.String': '{authDate} 결제 ({businessCardName})',
    'RD.Usagebill.Payment.Default': '미결제',
    'RD.Message.Alert.No.Data': '출력할 데이터가 존재하지 않습니다.',
    'RD.StartTime': '대여 시작',
    'RD.EndTime': '대여 종료',

    // 차량 관리 > 차량 등록/조회
    'Btn.Force.Delete': '강제삭제',
    'Message.Confirm.Force.Delete': '강제삭제를 하시겠습니까?',
    'Btn.Chnage.Provider': '공급사 변경',
    'Vehicle.Stats': '대여가능 {isAvailableCount}대 | 총 {totalRecord}대',
    'Vehicle.LicensePlateNumber': '차량번호',
    'Vehicle.Business.Placeholder': '회원사 입력',
    'Vehicle.Model.Name': '차종',
    'Vehicle.Model.Name.Select': '차종 선택',
    'Vehicle.Model.FuelType': '연료유형',
    'Vehicle.RefuelLiter': '충전/주유량',
    'Vehicle.InsuranceAge': '보험연령',
    'Vehicle.VehicleStatus.Wait': '대기',
    'Vehicle.VehicleStatus.Inuse': '이용중',
    'Vehicle.VehicleStatus.Refuel': '충전중',
    'Vehicle.VehicleStatus.Washing': '세차중',
    'Vehicle.VehicleStatus.Maintenance': '수리중',
    'Vehicle.VehicleStatus.Sale': '매각',
    'Vehicle.VehicleStatus.Scrap': '폐차',
    'Vehicle.VehicleStatus.LowVoltage': '저전압',
    'Vehicle.VehicleStatus.NotReturned': '반납지연',
    'Vehicle.VehicleStatus': '차량상태',
    'Vehicle.OperationStatus': '운영상태',
    'Vehicle.Used': '대여가능상태',
    'Vehicle.Available': '대여가능',
    'Vehicle.NotAvailable': '대여불가능',
    'Vehicle.WibleZone': '스테이션',
    'Vehicle.LicensePlateNumber.Placeholder': '차량번호 입력',
    'Vehicle.LicensePlateNumber.Alert': '차량번호 입력',
    'Vehicle.LicensePlateNumber.Alert.Duplicated': '중복 차량입니다.',
    'Vehicle.LicensePlateNumber.Alert.Success': '등록 가능한 차량입니다.',
    'Vehicle.Model.Name.Placeholder': '차종 입력',
    'Vehicle.Model.FuelType.AllSelect': '연료유형 전체',
    'Vehicle.InsuranceAge.AllSelect': '보험연령 전체',
    'Vehicle.InsuranceAge.26': '보험연령 26세 이상',
    'Vehicle.InsuranceAge.21': '보험연령 21세 이상',
    'Vehicle.InsuranceAge.26.Label': '26세 이상',
    'Vehicle.InsuranceAge.21.Label': '21세 이상',
    'Vehicle.VehicleStatus.AllSelect': '차량상태 전체',
    'Vehicle.OperationStatus.AllSelect': '운영상태 전체',
    'Vehicle.Used.AllSelect': '대여가능상태 전체',
    'Vehicle.WibleZone.AllSelect': '스테이션 전체',
    'Vehicle.Provider.AllSelect': '공급사 전체',
    'Vehicle.MID': 'MID',
    'Vehicle.MID.Select': 'MID 선택',
    'Vehicle.Account': '계좌번호',
    'Vehicle.SampleDownload.ConfirmMessage': '양식을 다운로드하시겠습니까?',
    'Vehicle.Upload.ConfirmMessage': '차량 일괄등록을 진행하시겠습니까?',
    'Vehicle.Upload.Success':
        '차량 일괄등록이 완료되었습니다.{br}차량 세부정보는 확인 및 수정해 주세요.',
    'Vehicle.Upload.Fail':
        '차량 일괄등록에 실패하였습니다.{br}세부정보는 실패건수를 클릭하여 확인해주세요.',
    'Vehicle.BasicInfo': '기본정보',
    'Vehicle.VIN': '차대번호(VIN)',
    'Vehicle.VIN.Placeholder': '영문, 숫자 17자리 입력',
    'Vehicle.VIN.Alert': '차대번호를 입력해 주세요.',
    'Vehicle.VIN.Alert.Duplicated': '중복 VIN입니다.',
    'Vehicle.VIN.Error.Message': '영문, 숫자 17자리 입력해 주세요.',
    'Vehicle.Alert.Success': '등록 가능합니다.',
    'Vehicle.Year': '차량연식',
    'Vehicle.Year.Alert': '차량연식은 1970~2100 사이의 값만 입력 가능합니다.',
    'Vehicle.Year.Placeholder': '연식 입력',
    'Vehicle.Model.FuelType.Placeholder': '연료유형 선택',
    'Vehicle.Trim': '트림',
    'Vehicle.Trim.Placeholder': '트림 입력',
    'Vehicle.Color.Outside': '색상-외장',
    'Vehicle.Color.Inside': '색상-내장',
    'Vehicle.Color.Placeholder': '색상 입력',
    'Vehicle.HiPassCard': 'ETC Card 정보',
    'Vehicle.HiPassCard.Label': 'ETC Card',
    'Vehicle.SearchHiPassCard.Placeholder': 'ETC Card 입력',
    'Vehicle.HiPassCard.Number': '카드번호',
    'Vehicle.HiPassCard.Placeholder': 'ETC Card 선택',
    'Vehicle.ChargeCard': '충전/제휴 카드 정보',
    'Vehicle.ChargeCard.Label': '충전/제휴 카드',
    'Vehicle.SpareKey': '비상 키 배치',
    'Vehicle.SearchChargeCard.Placeholder': '충전/제휴 카드 정보 입력',
    'Vehicle.ChargeCard.Placeholder': '충전/제휴 카드 선택',
    'Vehicle.WibleZone.Select': '스테이션 설정',
    'Vehicle.WibleZone.Placeholder': '스테이션 선택',
    'Vehicle.Used.Label': '대여가능 상태 설정',
    'Vehicle.Registration': '차량등록증',
    'Vehicle.Registration.Delete.Success': '차량등록증 삭제에 성공하였습니다.',
    'Vehicle.Registration.Upload.Success':
        '차량등록증 업로드에 성공하였습니다.',
    'Vehicle.Registration.Upload.Failure':
        '차량등록증 업로드에 실패하였습니다.',
    'Vehicle.Registration.Placeholder': '이미지 또는 PDF 파일',
    'Vehicle.RegistrationDate.Label': '차량등록일',
    'Vehicle.RegistrationDate.Placeholder': '차량등록일 선택',
    'Vehicle.InspectionDate': '점검일',
    'Vehicle.InspectionDate.Placeholder': '점검일 선택',
    'Vehicle.CarInspectionDate': '차검일',
    'Vehicle.CarInspectionDate.Placeholder': '차검일 선택',
    'Vehicle.Remark' : '비고',
    'Vehicle.Remark.Placeholder' : '비고 입력',
    'Vehicle.Inspection.Complete.Btn': '점검완료',
    'Vehicle.CarInspection.Complete.Btn': '차검완료',
    'Vehicle.InspectionDate.ConfirmMessage': '점검일을 갱신하겠습니까?',
    'Vehicle.CarInspectionDate.ConfirmMessage': '차검일을 갱신하겠습니까?',
    'Vehicle.InspectionDate.Confirm.Success': '점검일 갱신에 성공하였습니다.',
    'Vehicle.CarInspectionDate.Confirm.Success': '차검일 갱신에 성공하였습니다.',
    'Vehicle.InspectionDate.Confirm.Failure': '점검일 갱신에 실패하였습니다.',
    'Vehicle.CarInspectionDate.Confirm.Failure': '차검일 갱신에 실패하였습니다.',
    // 'Vehicle.InspectionDate': '점검기한',
    // 'Vehicle.CarInspectionDate': '차검기한',
    'Vehicle.InspectionDate.All': '점검기한 전체',
    'Vehicle.CarInspectionDate.All': '차검기한 전체',
    'Vehicle.InsuranceAge.Label': '보험연령 제한 설정',
    'Vehicle.Option': '옵션 정보',
    'Vehicle.Option.Label': '옵션',
    'Vehicle.Option.ColumnLabel': '옵션 항목',
    'Vehicle.Option.Select': '옵션 선택',
    'Vehicle.Option.Safety': '안전',
    'Vehicle.Option.Safety.Select': '안전 옵션 선택',
    'Vehicle.Option.Compose': '편의',
    'Vehicle.Option.Compose.Select': '편의 옵션 선택',
    'Vehicle.Option.Placeholder': '직접 입력',
    'Vehicle.Return.Charger': '차량반납 조건 (충전 정책)',
    'Vehicle.ChargerConnection.Yn': '충전기 연결 여부',
    'Vehicle.ChargerConnection.Yes': '충전중',
    'Vehicle.ChargerConnection.No': '충전중 아님',
    'Vehicle.ChargerConnection.Duty': '연결 후 반납 필수',
    'Vehicle.ChargerConnection.NotDuty': '연결 없이도 반납 가능',
    'Vehicle.ReturnCondition.Yn': '차량반납 조건 유무',
    'Vehicle.RewardPayments.Yn': '리워드 지급 여부',
    'Vehicle.RewardPayments.Yes': '지급',
    'Vehicle.RewardPayments.No': '미지급',
    'Vehicle.ReturnOption': '반납 시 부가 조건 설정',
    'Vehicle.ReturnOption.Label': '반납 시 부가 조건',
    'Vehicle.ReturnOption.Condition': '잔여 충전량 기준 반납조건',
    'Vehicle.ReturnOption.Penalty': '잔여 충전량에 따른 페널티',
    'Vehicle.Charge.Rest': '잔여량 기준',
    'Vehicle.Charge.Rest.View': '잔여량 기준 : ',
    'Vehicle.Charge.Warning': '※ 기준 미만일 경우 반납 불가',
    'Vehicle.Charge.Penalty.Warning': '※ 기준 미만일 경우 페널티 부과',
    'Vehicle.Charge.Warning.View': '(미만일 경우 반납 불가)',
    'Vehicle.Charge.Penalty.Warning.View': '(미만일 경우 페널티 부과)',
    'Vehicle.ServiceTime': '서비스 시간',
    'Vehicle.ServiceTime.Duplicate.Alert':
        '시간대 중복이 존재합니다.{br}시간설정을 다시 확인해주세요.',
    'Vehicle.ServiceTime.Select.ChargeAndInsurance': '상품 및 보험 선택',
    'Vehicle.ServiceTime.Select.Day': '판매구간',
    'Vehicle.ServiceTime.Pickup': '픽업',
    'Vehicle.ServiceTime.Return': '반납',
    'Vehicle.ServiceTime.HH': '시간',
    'Vehicle.ServiceTime.HH.Placeholder': '시간 입력',
    'Vehicle.ServiceTime.mm': '분',
    'Vehicle.ServiceTime.mm.Placeholder': '분 입력',
    'Vehicle.ServiceTime.mm.Alert': '0~59 사이의 값만 입력 가능합니다.',
    'Vehicle.ServiceTime.Select.Time': '이용시간',
    'Vehicle.ServiceTime.Pickup.Day': '픽업 가능 요일',
    'Vehicle.ServiceTime.Pickup.Time': '픽업 가능 시간',
    'Vehicle.RentalTime': '대여시간',
    'Vehicle.LicenseType': '상품(요금제, 보험)',
    'Vehicle.ServiceTime.Setting': '서비스 시간 설정',
    'Vehicle.Charge.Select': '상품 선택',
    'Vehicle.Insurance.Select': '보험 선택',
    'Vehicle.ServiceTime.Wave': '~',
    'Vehicle.ServiceTime.Plus': '+',
    'Vehicle.ServiceTime.Minus': '-',
    'Vehicle.ServiceTime.Type': '유형',
    'Vehicle.ServiceTime.Business': '업무용',
    'Vehicle.ServiceTime.Personal': '개인용',
    'Vehicle.ServiceTime.Weekday.Type': '대여시간유형',
    'Vehicle.ServiceTime.Weekday': '운영일',
    'Vehicle.ServiceTime.Weekend': '비운영일',
    'Vehicle.ServiceTime.Day': '요일',
    'Vehicle.ServiceTime.Day.Mon': '월',
    'Vehicle.ServiceTime.Day.Tue': '화',
    'Vehicle.ServiceTime.Day.Wed': '수',
    'Vehicle.ServiceTime.Day.Thu': '목',
    'Vehicle.ServiceTime.Day.Fri': '금',
    'Vehicle.ServiceTime.Day.Sat': '토',
    'Vehicle.ServiceTime.Day.Sun': '일',
    'Vehicle.ServiceTime.Weekday.SalesTime': '운영일 판매구간',
    'Vehicle.ServiceTime.Weekend.SalesTime': '비운영일 판매구간',
    'Vehicle.SalesTime.Y': '판매',
    'Vehicle.SalesTime.N': '판매안함',
    'Vehicle.Terminal': '단말기',
    'Vehicle.Terminal.Connect': '장착',
    'Vehicle.Terminal.DisConnect': '미장착',
    'Vehicle.WibleAndBusiness': '스테이션/회원사 연동 정보',
    'Vehicle.Business': '회원사/부서',
    'Vehicle.Business.Content': '{businessName} / {departmentName}',
    'Vehicle.CheckDuplication': '중복 체크',
    'Vehicle.CheckDuplication.VIN.Alert': 'VIN 중복 체크가 필요합니다.',
    'Vehicle.CheckDuplication.LicensePlateNumber.Alert':
        '차량번호 중복 체크가 필요합니다.',
    'Vehicle.Gearbox': '변속기',
    'Vehicle.FuelLevelPercentage': '충전/주유량',
    'Vehicle.Provider': '공급사',
    'Vehicle.Provider.Placeholder': '공급사 선택',
    'Vehicle.Insurance.Section': '보험 정보',
    'Vehicle.Insurance.Company': '보험사',
    'Vehicle.Insurance.Company.Input': '보험사 입력',
    'Vehicle.Insurance.Company.Krm': '공제회',
    'Vehicle.Insurance.Age': '보험 연령',
    'Vehicle.Insurance.Age.Select': '보험 연령 선택',
    'Vehicle.VehicleDamage.Registration': '자차 가입',
    'Vehicle.VehicleDamageInsurance': '자기차량손해',
    'Vehicle.VehicleDamageInsurance.Registration': '자기차량 손해가입',
    'Vehicle.Emergency.Registration': '긴급출동 특약여부',
    'Vehicle.PhysicalAccident': '자가신체사고',
    'Vehicle.PhysicalAccident.Placeholder': '자가신체사고 입력',
    'Vehicle.InterpersonalCompensation1': '대인배상1',
    'Vehicle.InterpersonalCompensation1.Placeholder': '대인배상1 입력',
    'Vehicle.InterpersonalCompensation2': '대인배상2',
    'Vehicle.InterpersonalCompensation2.Placeholder': '대인배상2 입력',
    'Vehicle.Reparation': '대물배상',
    'Vehicle.Reparation.Placeholder': '대물배상 입력',
    'Vehicle.UninsuredCarInjury': '무보험차 상해',
    'Vehicle.UninsuredCarInjury.Placeholder': '무보험차 상해 입력',
    'Vehicle.Interpersonal': '대인',
    'Vehicle.InterVehicle': '대물',
    'Vehicle.SelfDamage': '자손',
    'Vehicle.VehicleDamage': '자기차량손해',
    'Vehicle.VehicleDamage.Placeholder': '자기차량손해 입력',
    'Vehicle.Insurance.Limit': '보험 한도',
    'Vehicle.Insurance.SelfExposure': '자기부담금',
    'Vehicle.MultipleCreate': '일괄 등록',
    'Vehicle.ModelSelect': '차량 모델 선택',
    'Vehicle.SearchModel.Placeholder': '차량 모델 검색',
    'Vehicle.HiPassSelect': 'ETC Card 선택',
    'Vehicle.ChargeCardSelect': '충전/제휴 카드 선택',
    'Vehicle.Registration.Yn': '가입여부',
    'Vehicle.Registration.Yes': '가입',
    'Vehicle.Registration.No': '미 가입',
    'Vehicle.VehicleLocation': '차량 위치/제어',
    'Vehicle.Accident': '사고 수리 내역',
    'Vehicle.VehicleDailyOperation': '차량 운행일지',
    'Vehicle.CheckVehicleRegistration': '차량 등록증 확인',
    'Vehicle.TerminalDetail': '단말기 상세정보',
    'Vehicle.HiPassDetail': '하이패스 이력',
    'Vehicle.ChargeCardDetail': '충전/주유 이력',
    'Vehicle.CardSearch': '카드 조회',
    'Vehicle.Contract': '계약기간 (연장 포함)',
    'Vehicle.BleWifi': 'BLE/WIFI',
    'Vehicle.BleWifi.MacAddress': 'BLE Mac Address',
    'Vehicle.BleWifi.MacAddress.Manual': 'BLE Mac Address (수기)',
    'Vehicle.BleWifi.MacAddress.Placeholder': 'BLE MAC 주소를 입력하세요',
    'Vehicle.BleWifi.AccessPoint': 'WIFI Access Point',
    'Vehicle.BusinessName': '회원사',
    'Vehicle.Insurance': '보험정보',
    'Vehicle.CurrentLocation': '현 위치',
    'Vehicle.ExtraProduct.Info': '부가 상품 정보',
    'Vehicle.ExtraProduct': '부가상품',
    'Vehicle.ExtraProduct.Select': '부가상품 선택',
    'Vehicle.BLEMac': '- BLE MAC address',
    'Vehicle.Wifiname': '- WIFI Access name',

    // 차량 관리 > 차량 관제
    'VehicleMonitoring.SearchValue.Placeholder': '차량번호 또는 차종 입력',
    'VehicleMonitoring.Status': '상태',
    'VehicleMonitoring.LicensePlateNumber': '차량번호',
    'VehicleMonitoring.VehicleModelName': '차종',
    'VehicleMonitoring.LocationName': '스테이션',
    'VehicleMonitoring.Time': '일시',
    'VehicleMonitoring.UserName': '이름',
    'VehicleMonitoring.ControlCommand': '제어 명령',
    'VehicleMonitoring.Result': '결과',
    'VehicleMonitoring.ENG_ON': '운행중',
    'VehicleMonitoring.ENG_OFF': '정차중',
    'VehicleMonitoring.INCIDENT': '차량이상',
    'VehicleMonitoring.MAINTENANCE': '유지보수',
    'VehicleMonitoring.REFUEL': '충전',
    'VehicleMonitoring.NOT_RETURN': '반납지연',
    'VehicleMonitoring.VehicleDetail': '차량 상세',
    'VehicleMonitoring.CurrentLocation': '현재위치',
    'VehicleMonitoring.VehicleControl': '차량 제어',
    'VehicleMonitoring.ControlHistory': '제어 이력(서버)',
    'VehicleMonitoring.ControlHistory.Ble': '제어 이력(BLE)',
    'VehicleMonitoring.Command.OpenDoor': '문열기',
    'VehicleMonitoring.Command.CloseDoor': '문닫기',
    'VehicleMonitoring.Command.Horn': '경적/비상등',
    'VehicleMonitoring.Command.IGNProhibit': '시동차단',
    'VehicleMonitoring.Command.Immobilizer': 'FOB ON/OFF',
    'VehicleMonitoring.Command.Preventreign': '시동차단 ON/OFF',
    'VehicleMonitoring.CommandResultAddToast':
        '10초 후 다시 한번 확인해주세요.',
    'VehicleMonitoring.CommandResultAddToast2':
        '잠시 후 다시 한번 확인해주세요.',
    'VehicleMonitoring.VehicleStatus': '차량 상태',
    'VehicleMonitoring.ReConfirm': '재확인',
    'VehicleMonitoring.VehicleOperation': '차량 운행',
    'VehicleMonitoring.VehicleDailyOperation': '차량 운행일지',
    'VehicleMonitoring.FailCollectInformation': '정보 수집 실패',
    'VehicleMonitoring.IGNStatus': '시동상태',
    'VehicleMonitoring.FuelLevelPercentage': '충전/주유량',
    'VehicleMonitoring.Odometer': '총 주행거리',
    'VehicleMonitoring.OdoKilometer': '최근 이동거리',
    'VehicleMonitoring.TPMS': '타이어 공기압(psi)',
    'VehicleMonitoring.FrontLeft': '앞좌',
    'VehicleMonitoring.FrontRight': '앞우',
    'VehicleMonitoring.RearLeft': '뒤좌',
    'VehicleMonitoring.RearRight': '뒤우',
    'VehicleMonitoring.BusinessName': '대여 회원사',
    'VehicleMonitoring.BusinessName.Exclusive': '전용 회원사',
    'VehicleMonitoring.DepartmentName': '부서',
    'VehicleMonitoring.DepartmentName.Exclusive': '전용 부서',
    'VehicleMonitoring.BatteryVoltage': '12V 배터리(Voltage)',
    'VehicleMonitoring.TPMSFrontLeft': '앞바퀴/좌',
    'VehicleMonitoring.TPMSFrontRight': '앞바퀴/우',
    'VehicleMonitoring.TPMSRearLeft': '뒷바퀴/좌',
    'VehicleMonitoring.TPMSRearRight': '뒷바퀴/우',
    'VehicleMonitoring.EngineStarted': '시동상태',
    'VehicleMonitoring.DoorLocked': '도어락',
    'VehicleMonitoring.DoorLockFrontLeft': '운전석 도어락',
    'VehicleMonitoring.DoorLockFrontRight': '보조석 도어락',
    'VehicleMonitoring.DoorLockRearLeft': '뒷좌석/좌 도어락',
    'VehicleMonitoring.DoorLockRearRight': '뒷좌석/우 도어락',
    'VehicleMonitoring.DoorOpened': '문열림',
    'VehicleMonitoring.DoorOpenFrontLeft': '운전석 문열림',
    'VehicleMonitoring.DoorOpenFrontRight': '보조석 문열림',
    'VehicleMonitoring.DoorOpenRearLeft': '뒷좌석/좌 문열림',
    'VehicleMonitoring.DoorOpenRearRight': '뒷좌석/우 문열림',
    'VehicleMonitoring.HoodOpened': '본넷',
    'VehicleMonitoring.TrunkOpened': '트렁크',
    'VehicleMonitoring.HoodTrunkOpen': '본넷/트렁크 문열림',
    'VehicleMonitoring.FuelCapOpened': '충전/주유구',
    'VehicleMonitoring.HeadlightStatus': '전조등',
    'VehicleMonitoring.GearPosition': '기어',
    'VehicleMonitoring.Charge.Connected': '충전기 연결',
    'VehicleMonitoring.Charging.Remain.Time': '충전 잔여시간',
    'VehicleMonitoring.DTE': '주행가능거리',
    'VehicleMonitoring.BAT_SOC': '12V 배터리(SoC)',
    'VehicleMonitoring.ChargingYn': '충전중 여부',
    'Vehicle.State.Charging.Y': '충전중',
    'Vehicle.State.Charging.N': '-',
    'Vehicle.State.Charging.Connection.Y': '연결됨',
    'Vehicle.State.Charging.Connection.N': '-',
    'Vehicle.State.Charging.Remain.Time': '{hours, select, 0 {{minutes} 분} other {{hours} 시간 {minutes}분}}',
    'Vehicle.State.Update.Scheduled': '업데이트 예정',
    'Vehicle.State.RequestIng': '수집 중',
    'Vehicle.State.NA': 'N/A',
    'Vehicle.State.On': 'ON',
    'Vehicle.State.Off': 'OFF',
    'Vehicle.State.Lock': '잠김',
    'Vehicle.State.Unlock': '해제',
    'Vehicle.State.Open': '열림',
    'Vehicle.State.Close': '닫힘',
    'Vehicle.State.Gear.Drive': 'D',
    'Vehicle.State.Gear.EV.B': 'B',
    'Vehicle.State.Gear.0': 'P',
    'Vehicle.State.Gear.1': 'L',
    'Vehicle.State.Gear.2': '2',
    'Vehicle.State.Gear.3': '3',
    'Vehicle.State.Gear.4': 'DS Mode',
    'Vehicle.State.Gear.5': 'D',
    'Vehicle.State.Gear.6': 'N',
    'Vehicle.State.Gear.7': 'R',
    'Vehicle.State.Gear.8': 'Sports Mode',
    'Vehicle.State.Gear.9': 'Not Display at Cluster',
    'Vehicle.State.Gear.10': 'Sub-Rom Communication',
    'Vehicle.State.Gear.11': 'Sub-Rom Communication Error',
    'Vehicle.State.Gear.12': 'Paddle Shift',
    'Vehicle.State.Gear.13': 'Reserved',
    'Vehicle.State.Gear.14': 'Intermediate Position',
    'Vehicle.State.Gear.15': 'Fault',
    'Vehicle.State.cLightSwState.0': 'OFF',
    'Vehicle.State.cLightSwState.1': '후미등 ON',
    'Vehicle.State.cLightSwState.2': '하향등 ON',
    'Vehicle.State.cLightSwState.3': 'Auto',
    'VehicleMonitoring.RequestedTime': '요청일시',
    'VehicleMonitoring.FinishedTime': '응답일시',
    'VehicleMonitoring.CallbackCode': '콜백코드',
    'VehicleMonitoring.ResponseCode': '응답코드',

    // 차량 관리 > 차량 운행일지
    'VehicleDailyOperation.BusinessName': '회원사',
    'VehicleDailyOperation.BusinessName.Placeholder': '회원사 입력',
    'VehicleDailyOperation.DepartmentName': '부서',
    'VehicleDailyOperation.RenterName': '사용자',
    'VehicleDailyOperation.RenterName.Placeholder': '사용자 입력',
    'VehicleDailyOperation.ReservationTime': '사용일',
    'VehicleDailyOperation.ReservationTime.Label': '기간',
    'VehicleDailyOperation.LicensePlateNumber': '차량번호',
    'VehicleDailyOperation.LicensePlateNumber.Placeholder': '차량번호 입력',
    'VehicleDailyOperation.Provider': '공급사',
    'VehicleDailyOperation.ModelName': '차종',
    'VehicleDailyOperation.ModelName.Placeholder': '차종 입력',
    'VehicleDailyOperation.StartOdoKilometer': '주행 전 거리',
    'VehicleDailyOperation.EndOdoKilometer': '주행 후 거리',
    'VehicleDailyOperation.ChangeOdoKilometer': '주행 거리',
    'VehicleDailyOperation.RenterOdoKilometer': '사용거리',
    'VehicleDailyOperation.ReservationType': '예약 타입',
    'VehicleDailyOperation.ReservationType.BUSINESS': '업무용',
    'VehicleDailyOperation.ReservationType.PRIVATE': '개인용',
    'VehicleDailyOperation.TotalDistance': '주행 거리',
    'VehicleDailyOperation.RenterDistance': '업무용 사용거리',
    'VehicleDailyOperation.PersonalDistance': '출퇴근용',
    'VehicleDailyOperation.BusinessDistance': '일반업무용',
    // 'VehicleDailyOperation.RdHistoryCreate': '다운로드 사유',
    // 'VehicleDailyOperation.RdHistoryCreate.Text':
    //     '차량 운행일지 다운로드 사유를 입력하세요.',
    // 'VehicleDailyOperation.Description.Input': '사유 입력',
    // 'VehicleDailyOperation.Description.Placeholder': '내용을 입력하세요.',
    // 'VehicleDailyOperation.RdHistoryView': '리포트 다운로드 내역',
    // 'VehicleDailyOperation.RoleName': '관리자 계정',
    // 'VehicleDailyOperation.CreatorName': '관리자',
    // 'VehicleDailyOperation.DownloadTime': '다운로드 일시',
    // 'VehicleDailyOperation.Period': '기간',
    // 'VehicleDailyOperation.UserName': '사용자',
    // 'VehicleDailyOperation.Description': '사유',

    // 차량 관리 > 차종(차량모델)
    'VehicleModel.Standard': '대표 차종',
    'VehicleModel.Standard.Setup': '대표 차종 설정',
    'VehicleModel.StandardTag': '대표',
    'VehicleModel.Name': '차종',
    'VehicleModel.Name.Placeholder': '차종 입력',
    'VehicleModel.Name.Alert': '차종을 입력해 주세요.',
    'VehicleModel.CheckDuplication': '중복 체크',
    'VehicleModel.Brand': '제조사',
    'VehicleModel.Brand.Placeholder': '제조사 선택',
    'Brand.Type.Hyundai': '현대자동차',
    'Brand.Type.Kia': '기아자동차',
    'VehicleModel.Grade': '차급',
    'VehicleModel.GradeTypeNum': '차급(숫자형)',
    'VehicleModel.Grade.All': '차급 전체',
    'VehicleModel.Grade.Placeholder': '차급 선택',
    'Grade.Type.10': '경차',
    'Grade.Type.20': '소형 승용차',
    'Grade.Type.30': '준중형 승용차',
    'Grade.Type.40': '중형 승용차',
    'Grade.Type.50': '대형 승용차',
    'Grade.Type.60': 'SUV',
    'Grade.Type.70': 'VAN(승합차)',
    'Grade.Type.80': '전기차',
    'Grade.Type.90': '수입차',
    'Grade.Type.0': '알수없음',
    'VehicleModel.FuelType': '연료유형',
    'VehicleModel.FuelTypeNum': '연료유형(숫자형)',
    'VehicleModel.FuelType.All': '연료유형 전체',
    'VehicleModel.FuelType.Placeholder': '연료유형 선택',
    'Fuel.Type.Gasoline': '휘발유',
    'Fuel.Type.Diesel': '경유',
    'Fuel.Type.Ev': '전기',
    'Fuel.Type.Hybrid': '하이브리드',
    'Fuel.Type.PlugInHybrid': '플러그인 하이브리드',
    'Fuel.Type.HYDROGEN': '수소',
    'Fuel.Type.LPG': 'LPG',
    'Fuel.Type.LNG': 'LNG',
    'Fuel.Type.ALCOHOL': '알콜',
    'Fuel.Type.UNKNOWN': '알수없음',
    'VehicleModel.SeatingCapacity': '탑승인원(인승)',
    'VehicleModel.SeatingCapacity.Placeholder': '탑승인원 입력',
    'VehicleModel.DisplacementOrMaxPower': '배기량/출력',
    'VehicleModel.Displacement': '배기량(cc)',
    'VehicleModel.Displacement.Placeholder': 'ex) 1800',
    'VehicleModel.MaxPower': '출력(kW)',
    'VehicleModel.FuelTankCapacityOrBatteryCapacity': '연료탱크/배터리 용량',
    'VehicleModel.FuelTankCapacity': '연료탱크 용량',
    'VehicleModel.FuelTankCapacity.Placeholder': 'ex) 70',
    'VehicleModel.BatteryCapacity': '배터리 용량',
    'VehicleModel.FuelEfficiency': '연비(km/l)',
    'VehicleModel.FuelEfficiency.Placeholder': 'ex) 12.5',
    'VehicleModel.EvMileageComposite': '전비(km/kWh)',
    'VehicleModel.DeviceCode': '모델코드',
    'VehicleModel.DeviceCode.Placeholder': '모델코드 입력',
    'VehicleModel.Image': '차량 이미지',
    'VehicleModel.Image.Helper': '차량 이미지를 필수로 등록해야 합니다.',
    'VehicleModel.Grade.Label': '차급',
    'VehicleModel.Name.Input': '차종 입력',
    'VehicleModel.Name.Duplicate.Helper': '차종 중복체크 해주세요.',
    'VehicleModel.Name.Duplicate.Y': '중복된 차종입니다.',
    'VehicleModel.Name.Duplicate.N': '등록 가능한 차종입니다.',
    'VehicleModel.Image.File.extension': 'png, jpg 등록',
    'VehicleModel.Image.File.extension2': 'png, jpg, pdf 파일 업로드',
    'VehicleModel.Image.File.Select': '파일 선택',
    'VehicleModel.Image.Preview': '이미지 미리보기',
    'VehicleModel.Image.Desc':
        '차량 이미지는 대표 차종으로 설정 시 등록 가능합니다.',
    'VehicleModel.Provider': '공급사',
    'VehicleModel.Provider.All': '공급사 전체',
    'VehicleModel.Provider.Placeholder': '공급사 선택',
    'VehicleModel.BaseFee': '1일 기본요금',
    'VehicleModel.BaseFee.Placeholder': '1일 기본요금 입력',
    'VehicleModel.DelayFee': '서비스 지연요금',
    'VehicleModel.DelayFee.Placeholder': '서비스 지연요금 입력',
    'VehicleModel.FotaVer': 'FotaVer',
    'VehicleModel.FotaVer.Placeholder': 'FotaVer 입력',

    // 차량 관리 > 단말기
    'Terminal.LicensePlateNumber': '차량번호',
    'Terminal.VinNumber': 'VIN',
    'Terminal.ControlType': '단말기 타입',
    'Terminal.ControlType.MaasDevice': 'MAAS_DEVICE',
    'Terminal.NadID': 'USIM번호',
    'Terminal.Communication.State': '통신상태',
    'Terminal.Communication.State.Normal': '정상',
    'Terminal.Communication.State.Conflict': '충돌',
    'Terminal.Communication.State.NoData': 'ServiceHub 정보 없음',
    'Terminal.Communication.State.1': '대기',
    'Terminal.Communication.State.2': '장착',
    'Terminal.Status': '단말기 상태',
    'Terminal.Status.Normal': '정상',
    'Terminal.Status.Breakdown': '고장',
    'Terminal.Status.Repair': '수리',
    'Terminal.Status.Disposal': '폐기',
    'Terminal.DeviceState.5': '장착대기',
    'Terminal.HealthCheck': '단말상태 체크',
    'Terminal.InitialSetting': '단말기 초기설정',
    'Terminal.LicensePlateNumber.Placeholder': '차량번호 입력',
    'Terminal.VinNumber.Placeholder': 'VIN 입력',
    'Terminal.NadID.Placeholder': '전화번호 입력',
    'Terminal.controlType.AllSelect': '단말기 타입 전체',
    'Terminal.Communication.Status.AllSelect': '통신상태 전체',
    'Terminal.Status.AllSelect': '단말기 상태 전체',
    'Terminal.SearchWarning':
        '* 실물 단말정보를 검색하므로, 일치하지 않을 수 있습니다.',
    'Terminal.VinNumber.Input.Placeholder': '영문, 숫자 17자리 입력',
    'Terminal.LicensePlateNumber.Input.Placeholder':
        'VIN 입력 후 확인 버튼 클릭',
    'Terminal.TerminalType.Input.Placeholder': '단말기 타입 선택',
    'Terminal.SoftwareVersion': 'S/W Ver.',
    'Terminal.SoftwareVersion.Input.Placeholder': '소프트웨어 버전 입력',
    'Terminal.SerialNumber': 'Serial NO.',
    'Terminal.SerialNumber.Input.Placeholder': 'ex. CASD12434052DS888',
    'Terminal.Manufacturer': '제조사',
    'Terminal.Manufacturer.Input.Placeholder': '제조사 입력',
    'Terminal.DeliveryDate': '납품일',
    'Terminal.DeliveryDate.Placeholder': '납품일 선택',
    'Terminal.OrderDate': '발주 요청일',
    'Terminal.OrderDate.Placeholder': '발주 요청일 선택',
    'Terminal.WireService': '통신사',
    'Terminal.WireService.KT': 'KT',
    'Terminal.WireService.SKT': 'SKT',
    'Terminal.WireService.U+': 'U+',
    'Terminal.WireService.Input.Placeholder': '통신사 선택',
    'Terminal.ActivationDate': '통신 개통일',
    'Terminal.ActivationDate.Placeholder': '통신 개통일 선택',
    'Terminal.USIMNumber': 'USIM 번호',
    'Terminal.USIMNumber.Input.Placeholder': '전화번호 입력("-"없이 입력)',
    'Terminal.BasicInfo': '기본정보',
    'Terminal.WireServiceInfo': '통신사',
    'Terminal.Title.Status': '상태',
    'Terminal.ActivationDate.Label': '설치일자',
    'Terminal.DeviceState': '단말기 상태',
    'Terminal.DeviceState.Input.Placeholder': '단말기 상태 선택',
    'Terminal.ConnectionState': '통신 상태',
    'Terminal.Title.Vehicle': '차량 정보',
    'Terminal.Vehicle.Company': '공급사',
    'Terminal.FobYn': 'FOB 장착',
    'Terminal.HealthCheck.Dialog': '단말기 상태 체크',
    'Terminal.InitialSetting.Dialog': '단말기 장착 초기설정 시퀀스',
    'Terminal.ResponseTime': '응답시간',
    'Terminal.Status.Unavailable': '상태 확인 불가',
    'Terminal.BleAddress': 'BLE 주소 확인',
    'Terminal.BleAddress.DB': 'BLE 주소 확인(DB)',
    'Terminal.BleAddress.Unavailable': '주소 확인 불가',
    'Terminal.Fob.Status': 'FOB 키 상태',
    'Terminal.Fob.Status.Off': '키 상태 OFF',
    'Terminal.GPS.Check': 'GPS 체크',
    'Terminal.GPS.Check.Unavailable': 'GPS 수신 감도 양호하지 않음',
    'Terminal.Slave.Version': 'Slave 버전',
    'Terminal.Version.Unavailable': '버전 정보 확인 불가',
    'Terminal.Control.Version': 'Control ver.',
    'Terminal.Software.Version': 'F/W ver.',
    'Terminal.ControlStatus.Check': '차량 제어 상태 체크',
    'Terminal.FOTA': 'FOTA 서버',
    'Terminal.FOTA.Dev': '개발계',
    'Terminal.FOTA.Stg': '검증계',
    'Terminal.FOTA.Prd': '운영계',
    'Terminal.Update': 'FOTA 실시',
    'Terminal.Update.FirmWare': '펌웨어',
    'Terminal.Update.DB': 'DB',
    'Terminal.Update.FirmWareAndDB': '펌웨어+DB',
    'Terminal.HerbURLChange': '허브 URL 변경',
    'Terminal.HerbURLChange.Prd': '운영계 URL',
    'Terminal.HerbURLChange.Stg': '검증계 URL',
    'Terminal.Reset': '단말기 리셋',
    'Terminal.SMS': '단말기 SMS 전송',
    'Terminal.Ble.Update': '단말기 BLE 설정',
    'Terminal.Ble.Btn.Sync': 'SYNC',
    'Status.Match': '일치',
    'Status.NotMatch': '불일치',
    'Terminal.Ble.PubK.Get': '공개키 확인',
    'Terminal.Ble.PubK.Create': '공개키 등록',
    'Terminal.Ble.PubK.Update': '공개키 수정',
    'Terminal.Ble.PubK.Delete': '공개키 삭제',
    'Terminal.Ble.Msg.Success': 'BLE 처리가 성공하였습니다.',
    'Terminal.Ble.Msg.Fail': 'BLE 실패 되었습니다.',
    'Terminal.Ble.Msg.Fail.AlreadyExists':
        'BLE 등록된 공개키가 이미 존재합니다.',
    'Terminal.Ble.Msg.Fail.NotFound':
        '공개키가 없습니다.{br}공개키 등록을 먼저 진행해주세요.',
    'Terminal.Ble.Sync.Confirm': '싱크를 맞추시겠습니까?',
    'Terminal.Ble.Status.Fail': 'BLE 상태를 확인 할 수 없습니다.',
    'Message.Confirm.Terminal.InitialSetting.Process':
        '단말기 초기 설정을 진행 하시겠습니까?',
    'Message.Success.Terminal.InitialSetting.Process':
        '단말기 초기 설정을 진행 하였습니다.',
    'Message.Failure.Terminal.InitialSetting.Process':
        '단말기 초기 설정을 진행 할 수 없습니다.',
    'Message.Confirm.Terminal.InitialSetting.Delete':
        '단말기 초기 설정을 삭제 하시겠습니까?',
    'Message.Success.Terminal.InitialSetting.Delete':
        '단말기 초기 설정을 삭제 하였습니다.',
    'Message.Failure.Terminal.InitialSetting.Delete':
        '단말기 초기 설정을 삭제 할 수 없습니다.',
    'Message.Confirm.Terminal.Update.FirmWare':
        '단말기 펌웨어를 업데이트 하시겠습니까?',
    'Message.Success.Terminal.Update.FirmWare':
        '단말기 펌웨어를 업데이트 하였습니다.',
    'Message.Failure.Terminal.Update.FirmWare':
        '단말기 펌웨어를 업데이트 할 수 없습니다.',
    'Message.Confirm.Terminal.Update.DB': '단말기 DB를 업데이트 하시겠습니까?',
    'Message.Success.Terminal.Update.DB': '단말기 DB를 업데이트 하였습니다.',
    'Message.Failure.Terminal.Update.DB':
        '단말기 DB를 업데이트 할 수 없습니다.',
    'Message.Confirm.Terminal.Update.FirmWareAndDB':
        '단말기 펌웨어+DB를 업데이트 하시겠습니까?',
    'Message.Success.Terminal.Update.FirmWareAndDB':
        '단말기 펌웨어+DB를 업데이트 하였습니다.',
    'Message.Failure.Terminal.Update.FirmWareAndDB':
        '단말기 펌웨어+DB를 업데이트 할 수 없습니다.',
    'Message.Confirm.Terminal.HerbURLChange.Prd':
        '운영계 URL을 변경 하시겠습니까?',
    'Message.Success.Terminal.HerbURLChange.Prd':
        '운영계 URL을 변경 하였습니다.',
    'Message.Failure.Terminal.HerbURLChange.Prd':
        '운영계 URL을 변경 할 수 없습니다.',
    'Message.Confirm.Terminal.HerbURLChange.Stg':
        '검증계 URL을 변경 하시겠습니까?',
    'Message.Success.Terminal.HerbURLChange.Stg':
        '검증계 URL을 변경 하였습니다.',
    'Message.Failure.Terminal.HerbURLChange.Stg':
        '검증계 URL을 변경 할 수 없습니다.',
    'Message.Confirm.Terminal.Reset': '단말기 리셋을 하시겠습니까?',
    'Message.Success.Terminal.Reset': '단말기 리셋을 하였습니다.',
    'Message.Failure.Terminal.Reset': '단말기 리셋을 할 수 없습니다.',
    'Terminal.SMS.SendContents.Placeholder': '내용을 입력하세요.',
    'Message.Confirm.Terminal.SMS.Send': 'SMS를 전송 하시겠습니까?',
    'Message.Success.Terminal.SMS.Send': 'SMS를 전송 하였습니다.',
    'Message.Failure.Terminal.SMS.Send': 'SMS를 전송 할 수 없습니다.',
    'Message.Confirm.Terminal.Ble.Sync': 'SYNC 하시겠습니까?',
    'Message.Success.Terminal.Ble.Sync': 'SYNC 하였습니다.',
    'Message.Failure.Terminal.Ble.Sync': 'SYNC 할 수 없습니다.',
    'Message.Confirm.Terminal.Ble.Create': '공개키 등록 하시겠습니까?',
    'Message.Success.Terminal.Ble.Create': '공개키 등록 하였습니다.',
    'Message.Failure.Terminal.Ble.Create': '공개키 등록 할 수 없습니다.',
    'Message.Confirm.Terminal.Ble.Update': '공개키 수정 하시겠습니까?',
    'Message.Success.Terminal.Ble.Update': '공개키 수정 하였습니다.',
    'Message.Failure.Terminal.Ble.Update': '공개키 수정 할 수 없습니다.',
    'Message.Confirm.Terminal.Ble.Delete': '공개키 삭제 하시겠습니까?',
    'Message.Success.Terminal.Ble.Delete': '공개키 삭제 하였습니다.',
    'Message.Failure.Terminal.Ble.Delete': '공개키 삭제 할 수 없습니다.',
    'Terminal.Provider': '공급사',
    'Terminal.Provider.All': '공급사 전체',
    'Terminal.Provider.Placeholder': '공급사 선택',
    'Terminal.BleMacAddress': 'BLE MAC Address',
    'Terminal.WIFIName': 'WIFI Access Name',

    // 차량 관리 > 단말기 장착 검증
    'Terminal.Verification.Period': '기간',
    'Terminal.Verification.Date': '검증 일자',
    'Terminal.Verification.Status': '장착 상태',
    'Terminal.Verification.Status.AllSelect': '장착 상태 전체',
    'Terminal.Verification.Status.Success': '검증 완료',
    'Terminal.Verification.Status.Failure': '검증 실패',
    'Terminal.Verification.Status.InProgress' : '검증 진행 중',
    'Terminal.Verification.Verifier': '검증자',
    'Terminal.Verification.Sub.Info': '검증 정보',
    'Terminal.Verification.CreateDate': '검증 날짜',
    'Terminal.Verification.UpdateDate': '수정 날짜',
    'Terminal.Verification.Normal': '정상',
    'Terminal.Verification.Abnormal': '비정상',
    'Terminal.Verification.NotYet': '미진행',
    'Terminal.Verification.Sub.Device': '디바이스 펌웨어 체크',
    'Terminal.Verification.DB.Check': 'DB 체크',
    'Terminal.Verification.FW.Check': '펌웨어 체크',
    'Terminal.Verification.IGN.Check': 'IGN 체크',
    'Terminal.Verification.VIN.Check': 'VIN 체크',
    'Terminal.Verification.Remark': '특이사항',
    'Terminal.Verification.Sub.Vehicle': '차량 상태 체크',
    'Terminal.Verification.Response.Time': '응답 수신 소요 시간',
    'Terminal.Verification.View.Time': '단말 정보 조회 시각',
    'Terminal.Verification.Accumulate.Distanse': '누적 이동 거리',
    'Terminal.Verification.Sub.Location': '차량 위치 체크',
    'Terminal.Verification.Device.Locaiton': '단말 기준 차량 위치',
    'Terminal.Verification.Server.Location': '서버 기준 차량 위치',
    'Terminal.Verification.HDOP': 'HDOP',
    'Terminal.Verification.Sub.Door': '도어 제어 체크',
    'Terminal.Verification.Lock.Ble': '잠금 (BLE)',
    'Terminal.Verification.UnLock.Ble': '잠금 해제 (BLE)',
    'Terminal.Verification.Lock.Server': '잠금 (서버)',
    'Terminal.Verification.UnLock.Server': '잠금 해제 (서버)',
    'Terminal.Verification.Sub.SmartKey': '스마트키 (FOB ON, OFF)',
    'Terminal.Verification.Smart.On.Ble': '스마트키 ON 시동 걸림 (BLE)',
    'Terminal.Verification.Smart.Off.Ble': '스마트키 OFF 시동 안 걸림 (BLE)',
    'Terminal.Verification.Smart.On.Server': '스마트키 ON 시동 걸림 (서버)',
    'Terminal.Verification.Smart.Off.Server': '스마트키 OFF 시동 안 걸림 (서버)',
    'Terminal.Verification.Sub.Ign': '시동차단 체크',
    'Terminal.Verification.Ign.On': '시동차단 ON 시동 안 걸림',
    'Terminal.Verification.Ign.Off': '시동차단 OFF 시동 걸림',

    // 차량 관리 > 충전/주유 이력
    'Refuel.Stats': '총 {totalCost}円',
    'Refuel.LicensePlateNumber': '차량번호',
    'Refuel.LicensePlateNumber.Placeholder': '차량번호 입력',
    'Refuel.BusinessName': '회원사',
    'Refuel.BusinessName.Placeholder': '회원사 입력',
    'Refuel.DepartmentName': '부서',
    'Refuel.DepartmentName.Placeholder': '부서 입력',
    'Refuel.ReservationKey': '대여번호',
    'Refuel.ReservationKey.Placeholder': '대여번호 입력',
    'Refuel.CardNum': '카드번호',
    'Refuel.CardNum.Placeholder': '카드번호 입력',
    'Refuel.PaidAt': '결제기간',
    'Refuel.UserName': '성명',
    'Refuel.ReservationTime': '대여기간',
    'Refuel.FuelType': '연료유형',
    'Refuel.EventTime': '결제날짜',
    'Refuel.OilStation': '충전소/주유소명',
    'Refuel.RefuelCost': '충전/주유금액',
    'Refuel.RefuelLiter': '충전/주유량',
    'Refuel.FtlAmountChange': '충전/주유변화량',
    'Refuel.ReservationType': '유형',
    'Refuel.ReservationType.All': '프로필 유형 전체',

    // 차량 관리 > 하이패스 이력
    'Transit.Stats': '총 {totalCost}円',
    'Transit.LicensePlateNumber.Placeholder': '차량번호 입력',
    'Transit.Business.Placeholder': '회원사 입력',
    'Transit.Department.Placeholder': '부서 입력',
    'Transit.RegistrationNumber.Placeholder': '예약번호 입력',
    'Transit.CardNumber.Placeholder': '카드번호 입력',
    'Transit.BusinessName': '회원사',
    'Transit.UserName': '대여회원',
    'Transit.ReservationKey': '대여번호',
    'Transit.ReservationDate': '대여기간',
    'Transit.LicensePlateNumber': '차량번호',
    'Transit.CardNum': '카드번호',
    'Transit.PassedAt': '위치확인일시',
    'Transit.TotalCost': '결제금액',
    'Transit.Location': '위치',
    'Transit.TollgateExit': '출구',
    'Transit.TollgateEntrance': '입구',
    'Transit.TollgateEntrance.Unspecified': '미지정(입구)',
    'Transit.TollgateExit.Info1': '{tollgateExit}',
    'Transit.TollgateExit.Info2': '({passedAt})',
    'Transit.TollgateEntrance.Info1': '{tollgateEntrance}',
    'Transit.TollgateEntrance.Info2': '({entrancePassedAt})',
    'Transit.ReservationType': '유형',
    'Transit.ReservationType.All': '프로필 유형 전체',

    // 차량 관리 > 사고수리 이력
    'VehicleRepair.ManageType': '관리항목',
    'VehicleRepair.State': '진행상태',
    'VehicleRepair.RepairStation': '담당영업점',
    'VehicleRepair.Period': '작업기간',
    'VehicleRepair.AccidentTime': '사고일시',
    'VehicleRepair.AccidentTime.Placeholder': '사고일시 입력',
    'VehicleRepair.AccidentType': '사고분류',
    'VehicleRepair.AccidentType.AllSelect': '사고분류 전체',
    'VehicleRepair.AccidentType.Placeholder': '사고분류 선택',
    'VehicleRepair.AccidentInfo': '사고정보',
    'VehicleRepair.Repair.Info': '작업내역',
    'VehicleRepair.Repair.Detail': '상세내역',
    'VehicleRepair.Repair.Before': '작업 전',
    'VehicleRepair.Repair.After': '작업 후',
    'VehicleRepair.Repair.Before.Placeholder': '작업 전 사항 입력',
    'VehicleRepair.Repair.After.Placeholder': '작업 후 사항 입력',
    'VehicleRepair.Repair.Regist': '정보 등록',
    'VehicleRepair.Repair.Select': '상세 정보',
    'VehicleRepair.Repair.RegistTime': '등록일',
    'VehicleRepair.Repair.RegistNum': '등록번호',
    'VehicleRepair.Rental.Interwork': '등록일',
    'VehicleRepair.Vehicle.Num': '차량번호',
    'VehicleRepair.Vehicle.Num.Placeholder': '차량번호 입력',
    'VehicleRepair.Vehicle.Num.Placeholder2': '차량 번호 검색',
    'VehicleRepair.Repair.Cost': '청구 비용(円)',
    'VehicleRepair.Repair.State': '진행상태',
    'VehicleRepair.Repair.State.Placeholder': '진행 상태 선택',
    'VehicleRepair.Rental.Num': '대여 번호',
    'VehicleRepair.Rental.Num.Placeholder': '대여 번호 선택',
    'VehicleRepair.Accident.Time': '사고 일시',
    'VehicleRepair.Accident.Time.Placeholder': '사고 일시 입력',
    'VehicleRepair.Accident.Type': '사고 분류',
    'VehicleRepair.Accident.Location': '사고 위치',
    'VehicleRepair.Accident.Memo': '사고 경위',
    'VehicleRepair.Accident.Part': '사고 부위',
    'VehicleRepair.Accident.Part.Placeholder': '사고 부위 입력',
    'VehicleRepair.Accident.Photo': '사고 사진',
    'VehicleRepair.Accident.Latitude': '사고 위경도',
    'VehicleRepair.Accident.Longitude': '경도',
    'VehicleRepair.Accident.Latitude.Placeholder': '위도 입력',
    'VehicleRepair.Accident.Longitude.Placeholder': '경도 입력',
    'VehicleRepair.Accident.File.Comment': '※ 등록 사진 용량 10MB 이하',
    'VehicleRepair.Accident.UserFaultRate': '고객 과실비율(%)',
    'VehicleRepair.Accident.InsureReceptNum': '운행차량 보험접수<br/>번호',
    'VehicleRepair.Accident.OpntVehicleNumber': '상대차량 번호',
    'VehicleRepair.Accident.UserFaultRate.Placeholder': '고객 과실비율(%) 입력',
    'VehicleRepair.Accident.InsureReceptNum.Placeholder':
        '운행차량 보험접수번호 입력',
    'VehicleRepair.Accident.OpntVehicleNumber.Placeholder':
        '상대차량 번호 입력',
    'VehicleRepair.Accident.OpntInsureCompanyName': '상대차량 보험사',
    'VehicleRepair.Accident.OpntReceiptNum': '상대차량 보험접수<br/>번호',
    'VehicleRepair.Accident.OpntReceiptNum.Placeholder':
        '상대차량 보험접수 번호 입력',
    'VehicleRepair.Accident.OpntInsureCompanyName.Placeholder':
        '상대차량 보험사 입력',
    'VehicleRepair.Accident.Type.1': '단독',
    'VehicleRepair.Accident.Type.2': '차대차(쌍방)',
    'VehicleRepair.Accident.Type.3': '차대차(피해)',
    'VehicleRepair.Accident.Status.1': '접수완료',
    'VehicleRepair.Accident.Status.2': '처리중',
    'VehicleRepair.Accident.Status.4': '청구',
    'VehicleRepair.Accident.Status.8': '처리완료',
    'VehicleRepair.Accident.Status.Claim': '미납',
    'VehicleRepair.VehicleCoverageYn': '대물 발생여부',
    'VehicleRepair.PersonCoverageYn': '대인 발생여부',
    'VehicleRepair.SelfPhysicalYn': '자손 발생여부',
    'VehicleRepair.DrivingYn': '차량 정상운행 가능 여부',
    'VehicleRepair.AgentName': '담당 영업점',
    'VehicleRepair.AgentName.Placeholder': '담당 영업점 입력',
    'VehicleRepair.Repair.Status': '진행 상태',
    'VehicleRepair.Repair.Status.AllSelect': '진행 상태 전체',
    'VehicleRepair.Repair.Status.Placeholder': '진행 상태 선택',
    'VehicleRepair.Repair.Time': '작업기간',
    'VehicleRepair.Repair.Description': '상세내역',
    'VehicleRepair.Repair.Description.Placeholder': '상세 내역 입력',
    'VehicleRepair.Repair.Fee': '작업 비용',
    'VehicleRepair.Repair.Fee.Placeholder': '작업 비용 입력',
    'VehicleRepair.Repair.SelfVehicleCd': '자차 처리',
    'VehicleRepair.Repair.Estimate': '수리견적서',
    'VehicleRepair.Repair.Self': '자기부담금 청구서',
    'VehicleRepair.Repair.Insurance': '점검정비 명세서',
    'VehicleRepair.Repair.Customer': '고객 청구서',
    'VehicleRepair.Repair.Content': '메모',
    'VehicleRepair.Repair.Content.Placeholder': '메모 입력',
    'VehicleRepair.Cost': '비용',
    'VehicleRepair.Cost.Placeholder': '비용 입력',
    'VehicleRepair.Rest.Cost': '휴차료',
    'VehicleRepair.Rest.Cost.Placeholder': '휴차료 입력',
    'VehicleRepair.Etc.Cost': '기타 비용',
    'VehicleRepair.Etc.Cost.Placeholder': '기타 비용 입력',
    'VehicleRepair.Already.Repair.Message':
        '해당 대여번호에 이미 접수된 사고신고가 있습니다. 이동하겠습니까?',
    'VehicleRepair.NoBeforeImg.Message': '등록된 이미지가 없습니다.',
    'VehicleRepair.RangeValidate.Message': '대여시간을 벗어났습니다.',
    'VehicleRepair.NoIncidentTime.Message':
        '사고 일시는 필수항목입니다. 대여시작시간으로 설정합니다.',
    'VehicleRepair.SelfFee.Max': '자기부담금(최대)',
    'VehicleRepair.SelfFee.Min': '자기부담금(최소)',
    'VehicleRepair.SelfFee.Min.Placeholder': '자기부담금(최소값) 입력',
    'VehicleRepair.SelfFee.Percent': '자기부담금비율',
    'VehicleRepair.SelfFee.Percent.Placeholder': '자기부담금비율 입력',
    'VehicleRepair.CompensationFee': 'NOC보상 가입 여부',
    'VehicleRepair.Exemption': '면책상품',
    'VehicleRepair.incidentDriver.RegNum': '사고운전자사번',

    // 차량 관리 > 유지관리/정비 이력
    'Maintenance.BusinessName': '위탁업체',
    'Maintenance.BusinessName.Select': '위탁업체 선택',
    'Maintenance.BusinessName.AllSelect': '위탁업체 전체',
    'Maintenance.LicensePlateNumber.Placeholder': '차량번호 입력',
    'Maintenance.Status.Select': '관리상태 선택',
    'Maintenance.Status.AllSelect': '관리상태 전체',
    'Maintenance.CreatedAt': '등록일시',
    'Maintenance.Wiblezone.Select': '스테이션 선택',
    'Maintenance.Wiblezone.Placeholder': '스테이션 입력',
    'Maintenance.Wiblezone': '스테이션',
    'Maintenance.Provider': '공급사',
    'Maintenance.LicensePlateNumber': '차량번호',
    'Maintenance.Status': '관리상태',
    'Maintenance.Period': '작업기간',
    'Maintenance.Period2': '작업기간 조회',
    'Maintenance.ManageType': '관리항목',
    'Maintenance.Reservation': '차량예약',
    'Maintenance.RentalInfo': '차량 대여 정보',
    'Maintenance.Vehicle.Select': '차량 선택',
    'Maintenance.Search': '차량검색',
    'Maintenance.RepairTime': '작업일시\n(대여기간)',
    'Maintenance.RepairTime1': '작업일시',
    'Maintenance.RepairTime2': '\n(대여기간)',
    'Maintenance.Description': '상세 내역',
    'Maintenance.RepairInfo': '작업 내역',
    'Maintenance.StatusCheck': '상태 점검',
    'Maintenance.Vehicle': '차량',
    'Maintenance.ReservationPosibleTime': '예약 가능 시간대',
    'Maintenance.StartTime': '시작시간',
    'Maintenance.EndTime': '종료시간',
    'Maintenance.VehicleControl': '차량 제어',
    'Maintenance.CreateNo': '등록번호',
    'Maintenance.CreateManagerName': '등록 담당자/ID',
    'Maintenance.Description.Placeholder': '상세 내역 입력',
    'Maintenance.WorkingDate.Duration': '총 작업시간 : ',
    'Maintenance.WorkingDate.ErrorMessage':
        '종료시간은 시작시간보다 커야 합니다.',
    'Maintenance.Command.OpenDoor': '문열기',
    'Maintenance.Command.CloseDoor': '문닫기',
    'Maintenance.Command.Horn': '경적/비상등',
    'Maintenance.Command.Immobilizer': 'FOB ON/OFF',
    'Maintenance.Command.Preventreign': '시동차단 ON/OFF',
    'Maintenance.Msg.Success': '제어 명령을 실행하였습니다.',
    'Maintenance.Msg.Fail': '제어 명령 실행에 실패하였습니다.',
    'Maintenance.Msg.Vehicle.Select': '스테이션을 선택 후 차량을 검색해주세요.',
    'Maintenance.Msg.Wible.Select1': '스테이션 선택 > 작업일자 선택 후',
    'Maintenance.Msg.Wible.Select2':
        '해당 일자의 작업 가능 차량을 검색해 보세요',
    'Maintenance.Remaing.Gas': '충전/주유량 {value}%',
    'Maintenance.Status.Complete': '작업완료',
    'Maintenance.Status.EndTime': '작업 완료 시간',

    // 차량 관리 > 매각
    'Trade.LicensePlateNumber': '차량번호',
    'Trade.LicensePlateNumber.Placeholder': '차량번호 입력',
    'Trade.item': '처리항목',
    'Trade.TradeType': '처리항목',
    'Trade.TradeType.Select': '처리항목 선택',
    'Trade.TradeType.AllSelect': '처리항목 전체',
    'Trade.Type.Sale': '매각',
    'Trade.Type.Scrap': '폐차',
    'Trade.Type': '매각관리항목',
    'Trade.VehicleModel': '차종',
    'Trade.VehicleModel.Placeholder': '차량 번호 선택 시 자동 입력',
    'Trade.TradeDate': '처리일',
    'Trade.TradeDate.Sale': '매각일자',
    'Trade.TradeDate.Scrap': '폐차일자',
    'Trade.TradeAmount.Sale': '매각금액',
    'Trade.TradeAmount.Scrap': '폐차금액',
    'Trade.SaleBuyer': '매입자',
    'Trade.ScrapBuyer': '폐차장',
    'Trade.File': '양도계약서',
    'Trade.File.Placeholder': 'png, jpg, pdf 파일 등록',
    'Trade.Note': '비고',
    'Trade.SaleBuyer.Placeholder': '매입자 입력',
    'Trade.ScrapBuyer.Placeholder': '폐차장 입력',
    'Trade.TradeDate.Sale.Placeholder': '매각일자 입력',
    'Trade.TradeDate.Scrap.Placeholder': '폐차일자 입력',

    // 차량 관리 > 차량제어 이력
    'ControlHistory.UserName': '이름',
    'ControlHistory.UserName.Input': '이름 입력',
    'ControlHistory.LicensePlateNumber': '차량번호',
    'ControlHistory.LicensePlateNumber.Input': '차량번호 입력',
    'ControlHistory.CommandDate': '제어일시',
    'ControlHistory.RequestedTime': '요청시간',
    'ControlHistory.FinishedTime': '응답시간',
    'ControlHistory.Action': '액션',
    'ControlHistory.ControlCommand': '제어 명령',
    'ControlHistory.ResponseMessage': 'Req 결과',
    'ControlHistory.Result': '결과',
    'ControlHistory.CallbackCode': '콜백코드',
    'ControlHistory.ResponseCode': '응답코드',
    'ControlHistory.RequestData.Label': '요청내역',
    'ControlHistory.ServiceHUBTID': 'TID',
    'ControlHistory.ClientID': 'Client ID',
    'ControlHistory.RequestData': '요청 데이터',
    'ControlHistory.ResponseData': '응답내역',
    'ControlHistory.CallbackData': '응답 데이터',

    // 스테이션 관리
    'Location.Name': '스테이션 명칭',
    'Location.Name.Placeholder': '스테이션 명칭 입력',
    'Location.Address': '주소',
    'Location.Address.Placeholder': '주소 입력',
    'Location.Address.Placeholder2': '스테이션 주소 입력',
    'Location.BusinessName': '회원사',
    'Location.BusinessName.Placeholder': '회원사 입력',
    'Location.BusinessName.Button': '회원사 등록',
    'Location.BusinessName.Exclusive': '전용 회원사',
    'Location.PersonalCd': 'B2C 이용형태',
    'Location.PersonalCd.AllSelect': 'B2C 이용형태 전체',
    'Location.UseYn': '운영상태',
    'Location.UseYn.AllSelect': '운영상태 전체',
    'Location.CountVehicle': '스테이션 내 차량',
    'Location.Info': '기본 정보',
    'Location.WibleZoneLocation': '스테이션 위치',
    'Location.UndergroundYn': '지상/지하',
    'Location.Floor': '층수',
    'Location.Floor.Placeholder': '층수 입력',
    'Location.IndoorYn': '실내/실외',
    'Location.LatitudeLongitude': '스테이션 위경도',
    'Location.Latitude.Placeholder': '위도 입력',
    'Location.Longitude.Placeholder': '경도 입력',
    'Location.OperationTime': '운영시간',
    'Location.OperationTime.Placeholder': '운영시간 입력',
    'Location.OperationTimeJP': '운영시간',
    'Location.RegularHoliday': '정기휴무',
    'Location.JPlatform': '(J-Platform)',
    'Location.RegularHoliday.Placeholder': '정기휴무 입력',
    'Location.Radius': '반납지점(반경)',
    'Location.Radius.Placeholder': '반경 선택',
    'Location.ChargeTypeCd': '개인용 판매 상품',
    'Location.ChargeTypeCd.TIMEBASE': 'Time-based',
    'Location.ChargeTypeCd.SPECIAL': '1회권',
    'Location.ChargeTypeCd.PASS': '구독권',
    'Location.ChargeTypeCd.ONEDAY': '1 Day+',
    'Location.Contract.Period': '계약기간',
    'Location.Contract.Period.StartDate': '계약시작일시',
    'Location.Contract.Period.EndDate': '계약종료일시',
    'Location.Desc': '스테이션 설명',
    'Location.Desc.Placeholder': '설명 입력',
    'Location.ParkingCount': '주차면 수',
    'Location.ParkingCount.Placeholder': '주차면 수 입력',
    'Location.VehicleCount': '배차 대수',
    'Location.VehicleCount.Placeholder': '배차 대수 입력',
    'Location.RentalFee': '주차량 임대료',
    'Location.RentalFee.Placeholder': '가격 입력',
    'Location.ManagerName': '현장 담당자',
    'Location.ManagerName.Placeholder': '이름 입력',
    'Location.EntryExitMethod': '입출차 방식',
    'Location.chargingCardYn': '충전카드 유무',
    'Location.EntryExitMethod.Placeholder': '입출차 방식 입력',
    'Location.Memo': '유의사항',
    'Location.Memo.Placeholder': '유의사항 입력',
    'Location.HolidayInfo': '휴무일 정보',
    'Location.Holiday': '휴무일',
    'Location.Holiday.Placeholder': '휴무일 선택',
    'Location.Business': '회원사',
    'Location.BusinessInfo': '회원사 정보',
    'Location.BusinessRegNum': '사업자등록번호',
    'Location.BusinessManagerName': '담당자명',
    'Location.Vehicle': '차량',
    'Location.VehicleInfo': '차량 정보',
    'Location.VehicleInfo.Alert': `<span className="titleInfo">*차량 별 대여시간은 <span className="textColorBlack">차량관리 > 차량조회</span> 에서 설정할 수 있습니다.</span>`,
    'Location.Vehicle.Search': '차량 검색',
    'Location.LicensePlateNumber': '차량번호',
    'Location.VehicleModelName': '모델명',
    'Location.DepartmentName': '전용 부서',
    'Location.DepartmentName2': '부서',
    'Location.DepartmentName.Button': '부서 등록',
    'Location.DepartmentName.Select': '부서 선택',
    'Location.X': 'X',
    'Location.Guide': '스테이션 이용안내',
    'Location.Guide.Placeholder': '스테이션 이용안내 선택',
    'Location.UsageGuide': '이용상세 안내',
    'Location.UsageGuide.Tab': '이용 안내 상세',
    'Location.UsageGuide.Placeholder': '이용상세 안내 선택',
    'Location.UsageGuide.Language': '이용상세 안내({value})',
    'Location.ReservationType': '유형',
    'Location.ReservationType.BUSINESS': '업무용',
    'Location.ReservationType.PERSONAL': '개인용',
    'Location.RentalTime': '대여시간',
    'Location.Product': '상품 (요금제/보험 명)',
    'Location.Provider': '공급사',
    'Location.AddressDetail': '상세주소',
    'Location.AddressDetail.Placeholder': '상세 주소 입력',

    // 대여/결제 관리 > 대여 현황
    'ReservationSchedule.Daily': '일 별',
    'ReservationSchedule.DailyDateFormat': 'YYYY년 MM월 DD일',
    'ReservationSchedule.Monthly': '월 별',
    'ReservationSchedule.MonthlyDateFormat': 'YYYY년 MM월',
    'ReservationSchedule.RenterName.Placeholder': '대여 회원명 입력',
    'ReservationSchedule.ReservationKey.Placeholder': '대여번호 입력',
    'ReservationSchedule.SubscribeID.Placeholder': '구독번호 입력',
    'ReservationSchedule.VehiclePickupStationName.Placeholder':
        '스테이션 명칭 입력',
    'ReservationSchedule.LicensePlateNumber.Placeholder': '차량번호 입력',
    'ReservationSchedule.ModelName.Placeholder': '차종 입력',
    'ReservationSchedule.ModelGrade.All': '차급 전체',
    'ReservationSchedule.ProviderID.All': '공급사 전체',
    'ReservationSchedule.ReservationType.All': '프로필 유형 전체',
    'ReservationSchedule.VehiclePickupStationName': '스테이션',
    'ReservationSchedule.ModelName': '차종',
    'ReservationSchedule.LicensePlateNumber': '차량번호',
    'ReservationSchedule.ReservationCount': '예약건수',
    'ReservationSchedule.ConfirmCount': '예약완료 {value} 건',
    'ReservationSchedule.UseCount': '이용중 {value} 건',
    'ReservationSchedule.ReturnCount': '반납완료 {value} 건',
    'ReservationSchedule.ReturnDelayCount': '반납지연 {value} 건',
    'ReservationSchedule.BusinessCount': '비즈니스 {value} 건',
    'ReservationSchedule.BasicCount': 'Time-based {value} 건',
    'ReservationSchedule.PassCount': '구독권 {value} 건',
    'ReservationSchedule.SpecialCount': '1회권 {value} 건',
    'ReservationSchedule.MaintenanceCount': '차량관리 {value} 건',
    'ReservationSchedule.PersonalCount': '개인 {value} 건',
    'ReservationSchedule.MaintenanceTypeCdName': '차량관리 - {value}',

    // 대여/결제 관리 > 대여 내역
    'Reservation.Stats':
        '어제 {prevReservation}건 | 오늘 {todayReservation}건 | 총 {totalRecord}건',
    'Reservation.Rental.Id': '대여번호',
    'Reservation.Rental.Id.Search': '대여번호 검색',
    'Reservation.Rental.Customer': '고객명', //사고수리관리에서 고객명으로 사용
    'Reservation.Customer.Name': '회원명',
    'Reservation.Customer.Name.Label': '대여회원',
    'Reservation.Customer.Email': '이메일',
    'Reservation.Customer.CompanyEmail': '회사 이메일',
    'Reservation.Customer.Cellphone': '연락처',
    'Reservation.Customer.Cellphone.Masked': '{firstNumber}-****-{lastNumber}',
    'Reservation.Customer.Id': '회원 ID',
    'Reservation.BusinessName': '회원사',
    'Reservation.BusinessName.Search': '회원사 검색',
    'Reservation.BusinessName.Placeholder': '회원사 입력',
    'Reservation.DepartmentName': '대여회원 부서명',
    'Reservation.DepartmentName.Label': '부서명',
    'Reservation.DepartmentName.Placeholder': '부서명 입력',
    'Reservation.RentalName.Placeholder': '회원명 입력',
    'Reservation.RentalName': '대여회원',
    'Reservation.RentalName.Search': '대여회원 검색',
    'Reservation.ReservationKey': '대여번호',
    'Reservation.ReservationKey.Placeholder': '대여번호 입력',
    'Reservation.SubscribeID': '구독번호',
    'Reservation.SubscribeID.Placeholder': '구독번호 입력',
    'Reservation.SubscribeID.Search': '구독번호 검색',
    'Reservation.Subscribe.DiscountPay': '구독 할인요금',
    'Reservation.Subscribe.SubscribeRound': 'NO',
    'Reservation.Membership.DiscountPay.Short': '멤버십 할인',
    'Reservation.Membership.DiscountPay': '멤버십 할인요금',
    'Reservation.Membership.DiscountRate': '멤버십 할인율',
    'Reservation.Extend.DiscountPay': '대여요금 할인',
    'Reservation.LicensePlateNumber': '차량번호',
    'Reservation.LicensePlateNumber.Placeholder': '차량번호 입력',
    'Reservation.ModelName.Placeholder': '차종 입력',
    'Reservation.ModelName': '차종',
    'Reservation.VehiclePickupStationName.Placeholder': '스테이션 명칭 입력',
    'Reservation.ReservationType.Placeholder': '프로필 유형 전체',
    'Reservation.ReservationType.Label': '대여유형',
    'Reservation.ReservationType': '유형',
    'Reservation.ReservationType.Private': '개인',
    'Reservation.ReservationType.Business': '비즈니스',
    'Reservation.ReservationStatus': '상태',
    'Reservation.VehicleAvailableTime': '대여가능 시간',
    'Reservation.PickUpToReturnTime':
        '픽업 {pickupHour}:{pickupMin} , 반납 {returnHour}:{returnMin}',
    'Reservation.ReservationStatus.Label': '대여상태',
    'Reservation.ReservationStatus.Placeholder': '상태 전체',
    'Reservation.ReservationStatus.Request': '예약요청',
    'Reservation.ReservationStatus.Confirmed': '예약완료',
    'Reservation.ReservationStatus.Inuse': '이용중',
    'Reservation.ReservationStatus.Canceled': '예약취소',
    'Reservation.ReservationStatus.Returned': '반납완료',
    'Reservation.ReservationStatus.NotReturned': '반납지연',
    'Reservation.ChargeName': '요금제',
    'Reservation.ChargeGroup': '요금 그룹',
    'Reservation.ChargeGroup.Placeholder': '요금 그룹 전체',
    'Reservation.ChargeGroup.TimeBase': 'Time-based',
    'Reservation.ChargeGroup.Single': '특가(1회권)',
    'Reservation.ChargeGroup.Subscribe': '특가(구독권)',
    'Reservation.ChargeGroup.OneDayPlus': '특가(1 Day+)',
    'Reservation.ChargeType': '개인 상품',
    'Reservation.ChargeType.Placeholder': '개인 상품 전체',
    'Reservation.LicenseType': '비즈니스 상품',
    'Reservation.LicenseType.Placeholder': '비즈니스 상품 전체',
    'Reservation.ProviderName.Placeholder': '공급사 입력',
    'Reservation.ProviderName': '공급사',
    'Reservation.ReservationDate': '예약일',
    'Reservation.Search.ReservationDate': '대여일',
    'Reservation.ReservationTime': '예약일시',
    'Reservation.RentalDate': '대여기간',
    'Reservation.RegistDate': '대여등록일',
    'Reservation.StartDate': '대여시작일',
    'Reservation.EndDate': '대여종료일',
    'Reservation.EndDate.WibleContract.Alert':
        '대여종료일이 스테이션 계약기간보다 이후입니다. 대여기간을 변경해주세요.',
    'Reservation.Date.StartToEnd': '{startDate} ~ {endDate}',
    'Reservation.Date.Duration': '({days}일 {hours}시간 {minutes}분)',
    'Reservation.RentalDate.Duration':
        '총 대여 기간 : {days}일 {hours}시간 {minutes}분',
    'Reservation.Duration': '({days}일 {hours}시간 {minutes}분)',
    'Reservation.ReservationTime.Label': '대여 시간',
    'Reservation.RemainEngagementTime.Label': '이번달 잔여 약정 시간',
    'Reservation.RemainEngagementTime':
        '{hours}시간 {minutes}분 / {totalHours} 시간',
    'Reservation.MaxExtend': '{hours}시간 {minutes}분',
    'Reservation.SubscribeDate': '구독 기간 선택',
    'Reservation.SubscribeDate.Months.Placeholder': '월 선택',
    'Reservation.SubscribeDate.Months.Label': '{value}회차',
    'Reservation.SubscribeDate.StartDate': '구독시작일시',
    'Reservation.SubscribeDate.EndDate': '구독종료일시',
    'Reservation.SubscribeDate.~': ' ~ ',
    'Reservation.VehiclePickupStationName': '스테이션',
    'Reservation.Passenger': '탑승자',
    'Reservation.Passenger.Alert':
        '기존 선택한 회원과 같은 회원을 선택할 수 없습니다.',
    'Reservation.Passenger.Count': '{passenger}명',
    'Reservation.LicenseType.Label': '상품명',
    'Reservation.LicenseType.Name': '상품',
    'Reservation.LicenseType.PlaceHolder': '상품 선택',
    'Reservation.ReservationCancelTime': '예약 취소일시',
    'Reservation.UsageTime': '실 사용기간',
    'Reservation.Penalty': '페널티 발생',
    'Reservation.Destination': '목적지',
    'Reservation.ReservationBasicInfo': '대여정보',
    'Reservation.WorkDetail': '업무내용',
    'Reservation.VehicleCheck.Pictures': '주차 사진',
    'Reservation.BeforeReservation.Pictures': '대여 전 사진',
    'Reservation.ReturnLocation.Pictures': '반납 위치 사진',
    'Reservation.Vehicle.External': '차량 외부 점검',
    'Reservation.Vehicle.External.CheckList': '차량 외부 체크리스트',
    'Reservation.Vehicle.Internal': '차량 내부 점검',
    'Reservation.Vehicle.NotAbnormal': '이상 없음 체크',
    'Reservation.Vehicle.CheckList.Scratch': '스크래치',
    'Reservation.Vehicle.CheckList.Broken': '깨짐',
    'Reservation.Vehicle.CheckList.Crush': '찌그러짐',
    'Reservation.Vehicle.CheckList.Separation': '이격',
    'Reservation.Vehicle.CheckList.Smoke': '담배냄새',
    'Reservation.Vehicle.CheckList.Trash': '쓰레기',
    'Reservation.Vehicle.CheckList.Dust': '흙, 모래',
    'Reservation.Vehicle.CheckList.Animal': '동물털',
    'Reservation.Vehicle.CheckList.Food': '음식물',
    'Reservation.Vehicle.CheckList.Stink': '악취',
    'Reservation.Vehicle.CheckList.Pollution': '끈적임, 오염',
    'Reservation.Vehicle.CheckList.Goods': '타인물품',
    'Reservation.Vehicle.CheckList.Window': '창문 얼룩',
    'Reservation.Vehicle.CheckList': '체크리스트',
    'Reservation.Vehicle.Pictures': '점검 사진',
    'Reservation.Vehicle.Pictures.NotExist': '등록된 사진이 없습니다.',
    'Reservation.Vehicle.VehicleStatus.Text': '차량 상태 기재',
    'Reservation.CustomerInfo': '대여회원 정보',
    'Reservation.Passenger.Driving.Yes': '동승운전자',
    'Reservation.Passenger.Driving.No': '동승자',
    'Reservation.Passenger.Info': '탑승자 정보',
    'Reservation.CombinationPassenger.Info': '동승운전자 (바로 퇴근하기)',
    'Reservation.Passenger.Create': '+ 탑승자 추가',
    'Reservation.CombinationPassenger.Create': '+ 동승운전자 추가',
    'Reservation.Passenger.Edit': '탑승자 변경',
    'Reservation.CombinationPassenger.Edit': '동승운전자 변경',
    'Reservation.Passenger.EditComplete': '탑승자 변경 완료',
    'Reservation.CombinationPassenger.EditComplete': '동승운전자 변경 완료',
    'Reservation.Passenger.Driving': '운전 여부',
    'Reservation.Memo': '비고',
    'Reservation.Memo.Input': '비고 입력',
    'Reservation.VehicleInfo': '차량 정보',
    'Reservation.VehicleInfo.Return': '차량 점검 및 반납 정보',
    'Reservation.VehicleInfo.Create': '대여 차량 정보',
    'Reservation.VehicleType': '차급',
    'Reservation.FuelType': '유종',
    'Reservation.Vehicle.Insurance': '보험정보',
    'Reservation.Vehicle.Select': '대여 차량 선택',
    'Reservation.Insurance': '보험 정보',
    'Reservation.Insurance.Label': '보험',
    'Reservation.Insurance.Placeholder': '보험 선택',
    'Reservation.Insurance.NotExist': '선택할 수 있는 보험이 없습니다.',
    'Reservation.ChargeInfo': '이용 정보',
    'Reservation.ChargeInfo.Main': '대여 요금',
    'Reservation.ChargeInfo.Additional': '연장 정보',
    'Reservation.ChargeInfo.Extend': '연장 요금',
    'Reservation.ChargeInfo.RentalFee': '대여요금',
    'Reservation.ChargeInfo.CouponDiscountPay': '쿠폰 할인',
    'Reservation.ChargeInfo.CouponName': '사용 쿠폰명',
    'Reservation.ChargeInfo.InsuranceFee': 'NOC보상 가입 요금',
    'Reservation.ChargeInfo.InsuranceFee.Value': '{insuranceFee}円',
    'Reservation.ChargeInfo.OdoKilometer': '주행거리',
    'Reservation.ChargeInfo.OdoKilometer.Km': '{distance}km',
    'Reservation.ChargeInfo.OdoKilometer.Combination.Km':
        '{totalDistance}km (개인용 : {personalDistance}km / 업무용 : {businessDistance}km)',
    'Reservation.ChargeInfo.DrivingFee.SubTitle': '주행 요금',
    'Reservation.ChargeInfo.DrivingFee.Button': '주행요금 조회',
    'Reservation.ChargeInfo.DrivingFee': '주행요금',
    'Reservation.ChargeInfo.DrivingFee.Km': '주행요금(/km)',
    'Reservation.ChargeInfo.DrivingFee.Combination':
        '{totalDrivingFee}円 (개인용 : {personalDrivingFee}円 / 업무용 : {businessDrivingFee}円)',
    'Reservation.ChargeInfo.TollFee.Subtitle': '통행 요금',
    'Reservation.ChargeInfo.TollFee': '하이패스 결제 요금',
    'Reservation.ChargeInfo.TollFee.Date': '발생 일시',
    'Reservation.ChargeInfo.TollFee.Combination':
        '{totalTollFee}円 (개인용 : {personalTollFee}円 / 업무용 : {businessTollFee}円)',
    'Reservation.ChargeInfo.RentalDelayFee': '반납지연요금',
    'Reservation.ChargeInfo.TotalCharge': '총 이용 금액',
    'Reservation.ChargeInfo.Notice':
        '예약을 연장하여 이용한 경우, 주행요금은 프로필 별 최종 이용 상품의 대여내역에 합산되어 표기됩니다. <br /> 동일한 대여번호 및 프로필로 발생한 대여내역을 확인해 주시기 바랍니다.',
    'Reservation.ChargeCombination.Info': '결합상품 정보',
    'Reservation.ChargeCombination.ChargeMasterYn': '구분',
    'Reservation.ChargeCombination.ChargeMaster.Master': '주 상품',
    'Reservation.ChargeCombination.ChargeMaster.Combined': '추가 상품',
    'Reservation.ChargeCombination.ChargeType': '유형',
    'Reservation.ChargeCombination.ChargeInfo': '요금 그룹',
    'Reservation.ChargeCombination.RentalDate': '대여기간',
    'Reservation.ChargeCombination.RentalCharge': '대여료',
    'Reservation.ChargeCombination.InsuranceCharge': 'NOC보상',
    'Reservation.ChargeCombination.CouponDiscount': '쿠폰 할인',
    'Reservation.ChargeCombination.DiscountPay': '할인금액',
    'Reservation.ChargeCombination.Addgoods': '부가상품',
    'Reservation.ChargeCombination.Total': '합계',
    'Reservation.ChargeCombination.ReturnStatus': '반납상태',
    'Reservation.PaymentInfo': '결제 정보',
    'Reservation.PaymentInfo.Coupon': '할인쿠폰 적용',
    'Reservation.PaymentInfo.Coupon.PlaceHolder.NotExist': '할인쿠폰 선택',
    'Reservation.PaymentInfo.Coupon.PlaceHolder': '할인쿠폰 선택',
    'Reservation.PaymentInfo.DrivingFee': '주행요금 및 통행료 지불 방법',
    'Reservation.Payment.Button': '결제 요금 조회',
    'Reservation.FinalCharge': '최종 결제 요금',
    'Reservation.ReservClaimMethod.Month':
        '계약 시 협의한 회사 결제 정보로 합산 청구됩니다.',
    'Reservation.ReservMonthClaimMethod.ExactCalculation':
        '계약 시 협의된 방식에 따라 회사에 별도 청구됩니다.',
    'Reservation.ChargeCard.Select': '결제 카드',
    'Reservation.ChargeCard.Placeholder': '결제 카드 선택',
    'Reservation.ChargeCard.NotExist': '결제 카드 선택',
    'Reservation.Extend.Title': '대여기간 연장',
    'Reservation.Extend.Success.Title': '대여기간 연장 완료 안내',
    'Reservation.Extend.Confirm': '대여기간을 연장하시겠습니까?',
    'Reservation.Extend.Success': '대여기간 연장이 완료되었습니다.',
    'Reservation.Extend.Success.ChangedDate':
        '변경된 대여기간 : {startDate} ~ {endDate}',
    'Reservation.Extend.ReservationInfo': '대여 정보',
    'Reservation.Extend.LicenseType': '연장 방식',
    'Reservation.Extend.LicenseType.PersonalRelation':
        '(회원의 기존 예약이 있어 해당 예약을 연결합니다)',
    'Reservation.Extend.PersonalRelation': '기존 예약',
    'Reservation.Extend.LicenseType.ReturnTime': '반납 연장',
    'Reservation.Extend.LicenseType.Relation': '바로 퇴근하기',
    'Reservation.Extend.Time': '연장 시간',
    'Reservation.Extend.Coupon': '쿠폰 정보',
    'Reservation.Extend.Time.Alert':
        '연장 시간은 연장 가능 시간 이상으로 설정할 수 없습니다.',
    'Reservation.Extend.Type': '연장 유형',
    'Reservation.Extend.PossibleTime': '연장 가능 시간',
    'Reservation.Extend.PossibleTime.NotExist': '연장 가능한 시간이 없습니다.',
    'Reservation.Extend.NotPossible': '연장 불가능합니다.',
    'Reservation.Extend.NotPossibleDate':
        '연장 불가능한 날짜입니다. 다른 날짜를 선택해주세요.',
    'Reservation.Extend.NotPossible.TimeOver' : '(사유: 연장 가능 시간 초과)',
    'Reservation.Extend.NotPossible.OperationTimeOver' : '(사유: 스테이션 미 운영시간)',
    'Reservation.Extend.NotPossible.Holiday' : '(사유: 스테이션 휴무일)',
    'Reservation.Extend.PossibleDate': '연장 가능 일시',
    'Reservation.Extend.ReturnTime': '반납 시간',
    'Reservation.Extend.PickupTime.Constraint':
        '픽업 시간은 반납 시간보다 커야 합니다.',
    'Reservation.Extend.AdditionalAmount': '추가 결제 금액',
    'Reservation.Extend.Personal.AdditionalAmount': '개인 추가 결제 금액',
    'Reservation.Extend.PersonalRelation.AdditionalTime':
        '비즈니스 추가 대여 시간',
    'Reservation.Extend.PersonalRelation.AdditionalAmount':
        '비즈니스 추가 결제 금액',
    'Reservation.Extend.Card': '결제카드',
    'Reservation.Extend.ReservationType': '프로필 유형',
    'Reservation.PayAndCreate': '결제 및 대여 등록',
    'Reservation.Extend.Button': '대여기간 연장',
    'Reservation.PaymentHistory.Button': '결제 내역',
    'Reservation.Extend.RelationPassenger': '바로 퇴근 동승운전자 설정',
    'Reservation.Extend.RelationPassenger.Info':
        '바로퇴근 동승운전자가 있다면, 설정하세요. {br} 대여 관리 상세화면에서 나중에 추가 가능합니다.',
    'Reservation.Inuse.Button': '이용',
    'Reservation.Inuse.Alert': '이용중 상태로 전환하시겠습니까?',
    'Reservation.Inuse.Success': '이용중 상태로 전환에 성공하였습니다.',
    'Reservation.Inuse.Failure': '이용중 상태로 전환에 실패하였습니다.',
    'Reservation.ForceReturn.Button': '강제반납',
    'Reservation.ForceReturn.Alert':
        '강제 반납 처리하시겠습니까?{br}강제 반납이 완료되면, 추가 결제 요금 유무에 따라 추후 사용자에게 미수금이 발생합니다.',
    'Reservation.ForceReturn.Confirm': '강제반납 처리하시겠습니까?',
    'Reservation.ForceReturn.Complete': '강제 반납 완료',
    'Reservation.ForceReturn.Failure': '강제 반납에 실패하였습니다.',
    'Reservation.ForceReturn.Success':
        '강제 반납이 완료되었습니다.{br}차량의 단말기를 통해 사용자가 결제해야 하는 요금이 확인되면{br}미수금으로 등록됩니다.',
    'Reservation.Cancel.Button': '취소처리',
    'Reservation.Cancel': '대여 취소',
    'Reservation.Cancel.Subtitle': '예약 취소 정보',
    'Reservation.Cancel.Amount': '결제 취소 금액',
    'Reservation.Cancel.Amount.Business': '결제 취소 금액 (비즈)',
    'Reservation.Cancel.Amount.Personal': '결제 취소 금액 (개인)',
    'Reservation.Cancel.Commission': '취소 수수료',
    'Reservation.Cancel.Commission.Business': '취소 수수료 (비즈)',
    'Reservation.Cancel.Commission.Personal': '취소 수수료 (개인)',
    'Reservation.Cancel.Amount.Commission.Business': '결제 취소 수수료 (비즈)',
    'Reservation.Cancel.Amount.Commission.Personal': '결제 취소 수수료 (개인)',
    'Reservation.Cancel.Complete': '대여 취소 완료 안내',
    'Reservation.Cancel.Failure.Title': '대여 취소 불가 안내',
    'Reservation.Cancel.Alert': '대여 취소 하시겠습니까?',
    'Reservation.Cancel.Success':
        '결제 취소가 정상적으로 성공하였습니다.{br}대여 취소가 완료되었습니다.',
    'Reservation.Cancel.Msg':
        '결제 취소 금액  {cancelFee}円, 취소 수수료 금액 {commissionFee}円',
    'Reservation.Cancel.Failure':
        '대여 취소가 불가능합니다.{br}이미 대여가 시작되었을 경우, 취소가 불가능합니다.{br}대여 관리의 상태를 확인해 주세요.',
    'Reservation.Return.Button': '반납처리',
    'Reservation.Return.Alert': '반납 처리하시겠습니까?',
    'Reservation.Return.Success.Title': '대여 반납 완료 안내',
    'Reservation.Return.Success': '대여 반납이 완료되었습니다.',
    'Reservation.Success.Payment':
        '결제가 정상적으로 성공하였습니다.{br}대여 반납이 완료되었습니다.',
    'Reservation.Success.Payment.Amount': '결제 금액 : {amount}円',
    'Reservation.Success.Payment.Amount.Business': '결제 금액 (비즈)',
    'Reservation.Success.Payment.Amount.Personal': '결제 금액 (개인)',
    'Reservation.Success.Payment.Amount.Commission.Business':
        '결제 취소 금액 (비즈)',
    'Reservation.Success.Payment.Amount.Commission.Personal':
        '결제 취소 금액 (개인)',
    'Reservation.Return.Impossible.Title': '대여 반납 불가 안내',
    'Reservation.Return.Impossible':
        '현재 차량이 충전기에 연결되어 있지 않습니다.{br}차량반납을 위해 충전기에 차량을 연결해주세요.',
    'Reservation.Return.Failure.Title': '반납 실패',
    'Reservation.Return.Failure': '반납 처리에 실패했습니다.',
    'Reservation.Return.Failure.Location.Title': '차량위치 오류',
    'Reservation.Return.Failure.Location':
        '스테이션 반경 내 차량이 주차되어 있지 않습니다. 주차 위치를 확인해 주세요.',
    'Reservation.Return.Failure.Vehicle': '차량 위치 조회 불가',
    'Reservation.Return.Failure.Location.Wible':
        '스테이션 위치 : {location}{br}차량 위치 : {vehicle}',
    'Reservation.Return.Failure.VehicleStatus.Title': '차량 상태 오류',
    'Reservation.Return.Failure.VehicleStatus.ErrorCode':
        '에러코드 : {errorCode}',
    'Reservation.Return.Failure.VehicleStatus.501':
        '차량 시동이 켜져 있습니다.{br}시동을 꺼 주세요.',
    'Reservation.Return.Failure.VehicleStatus.503':
        '차량 도어가 닫히지 않았습니다.{br}차량 도어를 확인해 주세요.',
    'Reservation.Return.Failure.VehicleStatus.504':
        '트렁크가 열려 있습니다.{br}트렁크 도어를 확인해 주세요.',
    'Reservation.Return.Failure.VehicleStatus.505':
        '후드가 열려 있습니다.{br}후드를 확인해 주세요.',
    'Reservation.Return.Failure.VehicleStatus.506':
        '반납할 예약 정보가 없습니다.{br}시스템 관리자에게 문의해 주세요.',
    'Reservation.Return.Failure.VehicleStatus.507':
        '반납할 예약 정보가 없습니다.{br}시스템 관리자에게 문의해 주세요.',
    'Reservation.Failure.NotPayed.Title': '미수금 안내',
    'Reservation.Failure.NotPayed':
        '대여회원이 결제하지 않은 미수금이 있습니다.',
    'Reservation.NotPayed.Push': '결제 요청 Push 발송',
    'Reservation.Return.Failure.Payment.Title': '결제 실패',
    'Reservation.Failure.Payment':
        '카드 결제에 실패하였습니다.{br}오류코드를 확인해 주세요.',
    'Reservation.Success.Title': '대여 등록 완료 안내',
    'Reservation.Success':
        '결제가 정상적으로 성공하였습니다.{br}대여 등록이 완료되었습니다.',
    'Reservation.Failure.Title': '대여 불가 안내',
    'Reservation.Failure.Extend.Title': '대여 불가 안내',
    'Reservation.Failure.Duplication':
        '선택하신 차량과 대여 기간에 이미 대여를 신청한 사용자가 있습니다.{br}차량 또는 대여 기간을 변경하여 다시 시도해 주세요.',
    'Reservation.Failure.ContractDate':
        '회원사 계약상품의 잔여 약정시간이 부족해 대여가 불가능합니다.{br}잔여시간: {hours}시간 {minutes}분{br}{br} 대여 시간을 변경하여 다시 시도하세요.{br}*잔여시간이 30분 미만인 경우 신규 대여가 불가능합니다.',
    'Reservation.Failure,ContractDate.Lite':
        '잔여 약정시간이 부족합니다. {br}(잔여시간 {hours}시간 {minutes}분)',
    'Reservation.Failure.ContractDate.Extend':
        '회원사 계약상품의 잔여 약정시간이 부족해 반납 연장이 불가능합니다.{br}잔여시간: {hours}시간 {minutes}분{br}{br} 반납 연장 시간을 변경하여 다시 시도하세요.{br}*잔여시간이 10분 미만인 경우 반납 연장이 불가능합니다.',
    'Reservation.Passenger.Failure.Title': '동승운전자 안내',
    'Reservation.Passenger.Failure.License':
        '입력하신 동승운전자 ({passenger}) 운전면허 만료되었습니다.',
    'Reservation.Passenger.Failure.Penalty':
        '입력하신 동승운전자 ({passenger}) 페널티 점수가 20점을 초과하였습니다.',
    'Reservation.Contract.View': '계약서 조회',
    'Reservation.Claim': '미수금',
    'Reservation.Claim.Msg': '결제하지 않은 미수금이 있습니다.',
    'Reservation.Send.PaymentTalk': '결제 요청 e-mail 발송',
    'Reservation.Send.PaymentTalk.Confirm':
        '결제 요청 e-mail을 발송하시겠습니까?',
    'Reservation.Send.PaymentTalk.Success':
        '결제 요청 e-mail을 발송하였습니다.',
    'Reservation.ClaimMethod.CaseBy': '건별 청구 (차량 반납 시마다 청구)',
    'Reservation.ClaimMethod.Monthly': '월별 청구 (4주 단위로 합산 요금 청구)',
    'Reservation.ServiceDelay': '서비스 지연',
    'Reservation.ServiceDelayFee.PerMinute': '분당 서비스 지연 요금',
    'Reservation.ServiceDelay.DelayMinute': '지연 시간',
    'Reservation.Coupon.PriceZero.Alert':
        '대여금액이 0円인 경우 쿠폰을 사용할 수 없습니다.',

    'Reservation.Info': '예약 정보',
    'Reservation.Change.Vehicle': '차량 변경',
    'Reservation.Change.Vehicle.Button': '가용 차량 검색',
    'Reservation.Change.Vehicle.Title': '예약 차량 변경',
    'Reservation.Change.Vehicle.Confirm': '차량을 변경하시겠습니까?',
    'Reservation.Change.Vehicle.Success': '차량을 변경하였습니다.',
    'Reservation.Change.Vehicle.Failure': '차량을 변경할 수 없습니다.',
    'Reservation.Change.Vehicle.Reason.Placeholder': 'MOCEAN 담당자의 경우, 변경 사유를 입력해 주세요.',
    'Reservation.Change.Requester': '요청자를 선택해주세요.',
    'Reservation.Change.Requester.Renter': '고객',
    'Reservation.Change.Requester.MOCEAN': 'MOCEAN 담당자',
    'Reservation.Change.Reason': '사유',
    'Reservation.Change.Cancel.Renter.Placeholder': '취소 사유를 입력해 주세요',
    'Reservation.Change.Cancel.MOCEAN.Placeholder': '고객에게 알릴 취소 사유를 입력해 주세요',

    // 대여/결제 관리 > 구독 내역
    'Subscribe.Stats':
        '이번 달 {thisMonth}건 | 다음 달 {nextMonth}건 | 총 {total}건',
    'Subscribe.SubscribeId': '구독번호',
    'Subscribe.ChargeGroup': '상품',
    'Subscribe.SubscribeMonth': '구독 개월 수',
    'Subscribe.ReservationTime': '예약일시',
    'Subscribe.ReservationStartTime': '구독 시작일시',
    'Subscribe.ReservationEndTime': '구독 종료일시',
    'Subscribe.StationName': '스테이션',
    //'Vehicle.LicensePlateNumber': '차량번호',
    'Subscribe.ModelName': '차종',
    'Subscribe.ProviderName': '공급사',
    'Subscribe.RenterName': '대여회원',
    'Subscribe.Status': '상태',
    'Subscribe.RenterId': '회원ID',
    'Subscribe.Card': '결제카드',
    'Subscribe.Payment': '청구일',
    'Subscribe.PaymentType': '이용요금 청구주기',
    'Subscribe.VehicleType': '차급',
    'Subscribe.SubscribeId.Placeholder': '구독번호 입력',
    //'Common.User.Name': '회원명',
    'Subscribe.User.Name.Placeholder': '회원명 입력',
    'Subscribe.StationName.Placeholder': '스테이션 명칭 입력',
    'Subscribe.Vehicle.Placeholder': '차량번호 입력',
    'Subscribe.ProviderName.Placeholder': '공급사 전체',
    'Subscribe.ChargeGroup.Placeholder': '상품 전체',
    'Subscribe.Period': '구독기간',
    'Subscribe.SubTitle.Detail': '구독 내역 상세',
    'Subscribe.SubTitle.RentalInfo': '대여 정보',
    'Subscribe.SubTitle.Info': '구독 정보',
    'Subscribe.SubTitle.PayInfo': '구독 결제 정보',
    'Subscribe.SubTitle.VehicleInfo': '구독 차량 정보',
    'Subscribe.PayDaySelect': '청구일 조회',
    'Subscribe.PayCount': '청구일 조회',
    'Subscribe.PaymentTypeDesc': '{count}건 {cycle}별 결제',
    'Subscribe.PayDay.Info': '청구일 안내',
    'Subscribe.PayDay.comment': '회차 별 청구일은 다음과 같습니다.',
    'Subscribe.Rental.Detail': '대여내역 상세',
    'Subscribe.Rental.Detail.Info': '상세조회',
    'Subscribe.Subscribe': '구독 내역',
    'Subscribe.Status.Wait': '대기',
    'Subscribe.Status.Complete': '완료',
    'Subscribe.Status.Fail': '실패',

    'Subscribe.ChargeGroupCd': '구독요금그룹',
    'Subscribe.ChargeGroupCd.WEEKDAY': '퇴출근 구독',
    'Subscribe.ChargeGroupCd.WEEKEND': '주말 구독',
    'Subscribe.ChargeGroupCd.SUPER': '프리미엄 구독',

    'Subscribe.RentalFee': '구독요금',
    'Subscribe.CouponDiscountPay': '쿠폰할인',
    'Subscribe.CouponName': '사용 쿠폰명',
    'Subscribe.Fee': '총 결제금액',

    'Subscribe.ClaimMethod.CaseBy': '건 별 결제',
    'Subscribe.ClaimMethod.Monthly': '월 별 결제',
    'Subscribe.Cancel.Title': '구독 취소 안내',
    'Subscribe.Cancel.Check':
        '취소기간 : {cancelStartTime} ~ {cancelEndTime}{br}취소 수수료 : {cancelCommission}円{br}할인 반환금 : {discountRefundPay}円',

    'Subscribe.Cancel.Check2': '취소기간 : {cancelStartTime} ~ {cancelEndTime}',
    'Subscribe.Cancel.CancelCommission': '취소 수수료 : ',
    'Subscribe.Cancel.DiscountRefundPay': '할인 반환금 : ',

    'Subscribe.Insurance.Name': '보험명',
    'Subscribe.Insurance.SelfCost': '자기부담금',
    'Subscribe.SubscribeMonth.Head': '구독 결제 회차',
    'Subscribe.SubscribeMonth.Count':
        '{round}/{totalMonth} 회차 ({subscribeStartTime} ~ {subscribeEndTime})',
    'Subscribe.ChargeDiscountPay': '정기 구독 할인 금액',
    'Subscribe.InsuranceCost': '보험료',
    'Subscribe.TotalCost': '총 결제 금액',
    'Subscribe.CancelMsg': '(취소)',

    'Subscribe.Cancel.Date': '결제 취소',
    'Subscribe.Cancel.DateTime': '결제 취소 일시',
    'Subscribe.Cancel.Charge': '취소 수수료',
    'Subscribe.Discount.Return.Charge': '할인 반환금',
    'Subscribe.Cancel.Fee': '구독 취소 요금',

    // 대여/결제 관리 > 결제 내역
    'ReservationPayment.Stats': '총: {total}円',
    'ReservationPayment.BusinessName': '회원사',
    'ReservationPayment.BusinessName.Placeholder': '회원사 입력',
    'ReservationPayment.DepartmentName': '부서명',
    'ReservationPayment.DepartmentName.Placeholder': '부서명 입력',
    'ReservationPayment.UserName': '대여회원',
    'ReservationPayment.UserName.Placeholder': '회원명 입력',
    'ReservationPayment.ReservationKey': '대여번호',
    'ReservationPayment.ReservationKey.Placeholder': '대여번호 입력',
    'ReservationPayment.SubscribeID': '구독번호',
    'ReservationPayment.SubscribeID.Placeholder': '구독번호 입력',
    'ReservationPayment.PaymentID': '결제번호',
    'ReservationPayment.PaymentID.Placeholder': '결제번호 입력',
    'ReservationPayment.LicensePlateNumber': '차량번호',
    'ReservationPayment.LicensePlateNumber.Placeholder': '차량번호 입력',
    'ReservationPayment.LocationName': '스테이션',
    'ReservationPayment.LocationName.Placeholder': '스테이션 입력',
    'ReservationPayment.ReservationType': '유형',
    'ReservationPayment.ReservationType.All': '프로필 유형 전체',
    'ReservationPayment.ReservationType.BUSINESS': '업무용',
    'ReservationPayment.ReservationType.PERSONAL': '개인용',
    'ReservationPayment.SalesClassCd': '매출 구분',
    'ReservationPayment.SalesClassCd.All': '매출 구분 전체',
    'ReservationPayment.PaymentClassCd': '결제 구분',
    'ReservationPayment.PaymentClassCd.All': '결제 구분 전체',
    'ReservationPayment.ChargeGroup': '요금 그룹',
    'ReservationPayment.ChargeGroup.All': '요금 그룹 전체',
    'ReservationPayment.ChargeType': '개인 상품',
    'ReservationPayment.ChargeType.All': '개인 상품 전체',
    'ReservationPayment.LicenseTypeCd': '비즈니스 상품',
    'ReservationPayment.LicenseTypeCd.All': '비즈니스 상품 전체',
    'ReservationPayment.AuthDate': '결제일시',
    'ReservationPayment.AuthDate.Label': '결제일',
    'ReservationPayment.ModelName': '차종',
    'ReservationPayment.UserDepartmentName': '대여회원 부서명',
    'ReservationPayment.RealPay': '결제금액',
    'ReservationPayment.PaymentInfo': '결제 정보',
    'ReservationPayment.PaymentInfo.Text1':
        '※ 정확한 결제 내역은 각 카드사 홈페이지/App 및 영수증을 참고하시기 바랍니다.',
    'ReservationPayment.PaymentInfo.Text2':
        '※ 하이패스 결제 요금은 사용 후 약 3영업일 후 확인 가능합니다.',
    'ReservationPayment.PaymentMethod': '결제수단',
    'ReservationPayment.ApproveNumber': '승인번호',
    'ReservationPayment.ApproveStatus': '승인상태',
    'ReservationPayment.ApproveStatus.All': '승인상태 전체',
    'ReservationPayment.Output': '결제 내역 출력',
    'ReservationPayment.PaymentPartCancel': '취소 후 재결제',
    'ReservationPayment.PaymentPartCancel.Title': '취소 후 재결제 안내',
    'ReservationPayment.PaymentPartCancel.Text': `<span className="textColorRed">취소 후 재결제</span>를 진행하시겠습니까?`,
    'ReservationPayment.PaymentPartCancel.Text2':
        '부분취소를 하고자 하는 경우, 결제 전체취소 후 재결제를 진행합니다.',
    'ReservationPayment.PaymentPartCancel.Confirm':
        '취소 후 재결제 하시겠습니까?',
    'ReservationPayment.PaymentPartCancel.Success':
        '취소 후 재결제 하였습니다.',
    'ReservationPayment.PaymentPartCancel.Failure':
        '취소 후 재결제 할 수 없습니다.',
    'ReservationPayment.RePaymentAmount': '재결제 금액',
    'ReservationPayment.RePaymentAmount.Alert': '재결제 금액을 확인해 주세요.',
    'ReservationPayment.PaymentCancel': '결제취소',
    'ReservationPayment.PaymentCancel.Title': '결제취소 안내',
    'ReservationPayment.PaymentCancel.Text': `<span className="textColorRed">결제취소</span>를 진행하시겠습니까?`,
    'ReservationPayment.PaymentCancel.Confirm': '결제취소 하시겠습니까?',
    'ReservationPayment.PaymentCancel.Success': '결제취소 하였습니다.',
    'ReservationPayment.PaymentCancel.Failure': '결제취소 할 수 없습니다.',
    'ReservationPayment.PaymentHistory': '결제 이력',
    'ReservationPayment.UsageInformation': '이용 정보',
    'ReservationPayment.UserMobilePhone': '연락처',
    'ReservationPayment.ReservationTime.Period': '실 사용기간',
    'ReservationPayment.ResultCode.0': '-',
    'ReservationPayment.ResultCode.0000': '성공',
    'ReservationPayment.ResultCode.2001': '취소',
    'ReservationPayment.ResultCode.9999': '실패',
    'ReservationPayment.ResultCode.930302': '취소실패',
    'ReservationPayment.ResultCode2.0': '-',
    'ReservationPayment.ResultCode2.0000': '결제완료',
    'ReservationPayment.ResultCode2.2001': '취소완료',
    'ReservationPayment.ResultCode2.9999': '결제실패',
    'ReservationPayment.ResultCode2.930302': '취소실패',

    // 대여/결제 관리 > 결제 카드
    'Card.Business.Placeholder': '회원사 입력',
    'Card.Department.Placeholder': '부서명 입력',
    'Card.Business.Owner.Placeholder': '명의자/법인명 입력',
    'Card.CardRegister.Placeholder': '카드 등록자 입력',
    'Card.CardCompany.AllSelect': '카드사 전체',
    'Card.CardCorporation.AllSelect': '카드구분 전체',
    'Card.CardStatus.AllSelect': '상태 전체',
    'Card.CardNumber': '카드번호',
    'Card.CardNumber.Masked': '****-****-****-{cardNumber}',
    'Card.CardNumber.Placeholder': '카드번호(뒷 4자리) 입력',
    'Card.Change.CardStatus': '변경할 상태를 선택해 주세요.',
    'Card.Change.CardStatus.NotUse': '사용안함 처리',
    'Card.Change.CardStatus.Use': '사용중 처리',
    'Card.BusinessCard.Personal': '법인(개인명의)',
    'Card.BusinessCard.Business': '법인(법인명의)',
    'Card.Information.Input': '카드 정보 입력',
    'Card.Category': '카드구분',
    'Card.Register': '등록자',
    'Card.Business': '회원사',
    'Card.Business.Select': '회원사 선택',
    'Card.Department': '부서명',
    'Card.Department.Select': '부서 선택',
    'Card.OwnerOrBusiness': '명의자/법인명',
    'Card.Owner': '카드 명의자',
    'Card.Owner.Placeholder': '카드 명의자 입력',
    'Card.SocialNumber': '주민등록번호',
    'Card.SocialNumber.Placeholder': '앞 6자리 입력',
    'Card.CardCompany': '카드사',
    'Card.CardCompany.Placeholder': '카드사 선택',
    'Card.Password': '카드 비밀번호',
    'Card.Password.Placeholder': '앞 2자리 입력',
    'Card.Validity': '유효기간',
    'Card.Validity.Mid': '/',
    'Card.Validity.Month.Placeholder': '월 입력',
    'Card.Validity.Year.Placeholder': '년 입력',
    'Card.NickName': '카드별명',
    'Card.NickName.Placeholder': '카드별명 입력',
    'Card.Belonging': '카드 소속',
    'Card.Belonging.Placeholder': '카드 소속 선택',
    'Card.RegistrationDate': '카드 등록일',
    'Card.Representation.Label': '대표카드 여부',
    'Card.Representation': '대표카드 설정',
    'Card.Representation.Yn': '대표카드 여부',
    'Card.Representation.Yes': '대표카드',
    'Card.Representation.Set': '대표카드로 설정합니다.',
    'Card.Representation.Warning1':
        '※ 이미 설정된 대표카드가 있는 경우, 본 카드로 대표카드가 교체됩니다.',
    'Card.Representation.Warning2':
        '※ 회사 대표카드와 부서 대표카드 정보를 모두 보유하고 있는 회원의 경우, 부서 대표카드가 우선 결제카드로 설정됩니다.',
    'Card.Terms.Agree': '[결제 서비스 이용약관]에 동의합니다.',
    'Card.Terms.Detail': '약관 상세',
    'Card.Status.Label': '상태',
    'Card.Status': '카드상태',
    'Card.Status.Change': '카드 상태 변경',
    'Card.LastUpdateDate': '마지막 수정일',
    'Card.NoData': '-',
    'Card.Use.Yes': '사용중',
    'Card.Use.No': '사용안함',
    'Card.Business.Card.Type': '회원사 카드 소속',
    'Card.Buseinss.Card.Type.Buseinss.Label': '회사 공통 카드',
    'Card.Buseinss.Card.Type.Department.Label': '부서 전용 카드',
    'Card.Buseinss.Card.Type.Buseinss':
        '회사 공통 카드(등록 시, 회사 임직원 모두 이용 가능)',
    'Card.Buseinss.Card.Type.Department':
        '부서 전용 카드(등록 시, 부서 소속 임직원만 이용 가능)',
    'Card.Company': '카드사',
    'Card.Company.BC': '비씨',
    'Card.Company.KB': 'KB국민',
    'Card.Company.Hana': '하나(외환)',
    'Card.Company.Samsung': '삼성',
    'Card.Company.ShinHan': '신한',
    'Card.Company.HD': '현대',
    'Card.Company.LT': '롯데',
    'Card.Company.CT': '씨티',
    'Card.Company.NH': 'NH농협',
    'Card.Company.SuHyup': '수협',
    'Card.Company.ShinHyup': '신협',
    'Card.Company.WooRi': '우리',
    'Card.Company.GwangJu': '광주',
    'Card.Company.Jeonbuk': '전북',
    'Card.Company.Jeju': '제주',
    'Card.Company.SanEun': '산은캐피탈',
    'Card.Company.Visa': '해외비자',
    'Card.Company.Master': '해외마스터',
    'Card.Company.Dynasty': '해외다이너스티',
    'Card.Company.AMX': '해외AMX',
    'Card.Company.JCB': '해외JCB',
    'Card.Company.PostOffice': '우체국',
    'Card.Company.FSB': '저축은행',
    'Card.Company.UnionPay': '은련',
    'Card.Company.kfcc': '새마을금고',
    'Card.Company.KDB': 'KDB산업',
    'Card.BusinessRegNum.Message':
        '회원사/계약 메뉴에서 사업자등록번호를 등록해주세요.',

    // 대여/결제 관리 > ETC Card
    'Hipass.Management.HiPassCard': 'ETC Card',
    'Hipass.CardRegistrationDate': '카드 등록일',
    'Hipass.CardCompany.Placeholder': '카드사 선택',
    'Hipass.CardCorporation.AllSelect': '카드구분 전체',
    'Hipass.ModelGrade.AllSelect': '차급 전체',
    'Hipass.CardStatus.AllSelect': '상태 전체',
    'Hipass.CardNumber': '카드번호',
    'Hipass.LicensePlateNumber.Placeholder': '차량번호 입력',
    'Hipass.ModelName.Placeholder': '차종 입력',
    'Hipass.LicensePlateNumber': '차량번호',
    'Hipass.ModelName': '차종',
    'Hipass.VehicleType': '차급',
    'Hipass.CardCompany': '카드사',
    'Hipass.Validity': '유효기간',
    'Hipass.Status': '상태',
    'Hipass.Create.HiPassCard': 'ETC Card 등록',
    'Hipass.Update.HiPassCard': 'ETC Card 수정',
    'Hipass.Validity.Month.Placeholder': '월 입력',
    'Hipass.Validity.Year.Placeholder': '년 입력',
    'Hipass.Information.Input': 'ETC Card 정보 입력',
    'Hipass.ModelGrade.Large': '대형',
    'Hipass.ModelGrade.Small': '소형',
    'Hipass.ModelGrade.Msg.PossibleRegist': '등록 가능합니다.',
    'Hipass.ModelGrade.Msg.AlreadyRegist': '이미 등록된 카드번호입니다.',
    'Hipass.ModelGrade.Msg.DupCheck': '카드번호 중복체크를 해주세요',
    'Hipass.ModelGrade.Msg.InputCardNum': '카드번호를 입력해 주세요',

    // 대여/결제 관리 > 충전/제휴 카드
    'AssociateCard.CardRegistrationDate': '카드 등록일',
    'AssociateCard.CardCompany.Placeholder': '카드사 선택',
    'AssociateCard.CardCorporation.AllSelect': '카드구분 전체',
    'AssociateCard.CardStatus.AllSelect': '상태 전체',
    'AssociateCard.CardType': '카드종류',
    'AssociateCard.CardNumber': '카드번호',
    'AssociateCard.LicensePlateNumber.Placeholder': '차량번호 입력',
    'AssociateCard.ModelName.Placeholder': '차종 입력',
    'AssociateCard.LicensePlateNumber': '차량번호',
    'AssociateCard.ModelName': '차종',
    'AssociateCard.VehicleType': '차급',
    'AssociateCard.VehicleType.AllSelect': '차급 전체',
    'AssociateCard.CardCompany': '카드사',
    'AssociateCard.Validity': '유효기간',
    'AssociateCard.Status': '상태',
    'AssociateCard.Create.ChargeCard': '충전/제휴 카드 등록',
    'AssociateCard.Update.ChargeCard': '충전/제휴 카드 수정',
    'AssociateCard.Validity.Month.Placeholder': '월 입력',
    'AssociateCard.Validity.Year.Placeholder': '년 입력',
    'AssociateCard.Information.Input': '충전/제휴 카드 정보 입력',
    'AssociateCard.Hipass': '하이패스',
    'AssociateCard.Oil': '충전',
    'AssociateCard.Memo': '메모',

    // 회원사 관리 > 회원사/계약
    'Business.Select': '회원사 선택',
    'Business.Placeholder': '회원사 입력',
    'Business.Name.Placeholder': '회원사명 입력',
    'Business.RegNum.Placeholder': '사업자등록번호 입력',
    'Business.LicenseType.Placeholder': '가입상품 전체',
    'Business.LicenseType.Insert.Placeholder': '가입상품 입력',
    'Business.Contract.Yn.Placeholder': '계약 등록 여부 전체',
    'Business.ManagerMobileNumber.Placeholder': '전화번호 입력',
    'Business.Condition.Placeholder': '업태/업종 입력',
    'Business.Address.Placeholder': '주소 선택',
    'Business.Address.Detail.Placeholder': '상세주소 입력',
    'Business.BankName.Placeholder': '은행 선택',
    'Business.AccountNumber.Placeholder': '계좌번호 입력',
    'Business.AccountHolder.Placeholder': '예금주 입력',
    'Business.MainProfile.Placeholder':
        '대표 프로필명 입력(한글 2글자/영어 3글자)',
    'Business.Remark1.Placeholder':
        '※ 대표 이미지가 없을 시, 대표 프로필명으로 사진 표시가 대체됩니다.',
    'Business.Remark2.Placeholder':
        '※ 대표 프로필명 입력 시, 한글/영문 혼용을 금지합니다.',
    'Business.Remark3.Placeholder':
        '※ 슈퍼관리자는 최대 5인까지 등록 가능합니다.',
    // 'Business.Remark3.Placeholder': '※ 슈퍼관리자는 최대 5인까지 등록 가능합니다. MOCEAN Car Sharing의 최종 승인이 있어야 슈퍼관리자로 등록이 완료됩니다.',
    'Business.Name': '회원사명',
    'Business.RegNum': '사업자등록번호',
    'Business.licenseType': '가입상품',
    'Business.Contract.Period': '계약기간',
    'Business.Contract.StartDate': '계약시작일',
    'Business.Contract.EndDate': '계약만료일',
    'Business.Contract.Yn': '계약 등록 여부',
    'Business.WibleZoneCnt': '스테이션',
    'Business.Registration': '사업자 등록증',
    'Business.ManagerMobileNumber': '전화번호',
    'Business.Condition': '업태/업종',
    'Business.Address': '주소',
    'Business.Address.Detail': '상세주소',
    'Business.BankName': '은행',
    'Business.AccountNumber': '계좌번호',
    'Business.AccountHolder': '예금주',
    'Business.BankBookCopy': '통장사본',
    'Business.MainImage': '대표 이미지',
    'Business.MainProfile': '대표 프로필명',
    'Business.Email': '이메일 주소',
    'Business.Email1': '이메일 주소1',
    'Business.Email2': '이메일 주소2',
    'Business.Email3': '이메일 주소3',
    'Business.Email4': '이메일 주소4',
    'Business.Email5': '이메일 주소5',
    'Business.Contract.WibleZone.Placeholder': '계약 스테이션 개수 표시',
    'Business.Contract.MainWibleZone.Placeholder': '대표 스테이션 선택',
    'Business.MemberShip.Charge.Placeholder': '요금 입력',
    'Business.MemberShip.DiscountRate.Placeholder': '할인율 입력',
    'Business.MemberShip.Time.Placeholder': '시간 입력',
    'Business.AutoClaimDate.Placeholder': '날짜 입력',
    'Business.BasicCard.Placeholder': '카드사명/카드번호',
    'Business.Product.Guide.Placeholder': '상품 안내문구 입력',
    'Business.Contract.Product': '계약상품',
    'Business.Contract.WibleZone': '계약 스테이션',
    'Business.Contract.MainWibleZone': '대표 스테이션',
    'Business.Contract.PaperReg': '계약서 등록',
    'Business.MemberShip.Charge': '맴버십 요금',
    'Business.MemberShip.DiscountRate': '맴버십 할인율',
    'Business.MemberShip.Time': '맴버십 약정시간',
    'Business.Reserv.Charge': '대여요금',
    'Business.Reserv.DiscountRate': '% 할인',
    'Business.Reserv.MonthCycle': '월별 청구 :',
    'Business.Charge.Month': '월별 정산 :',
    'Business.Charge.Card': '카드 자동청구',
    'Business.Charge.Case': '건별 정산(카드)',
    'Business.Charge.Separate': '별도 정산',
    'Business.Charge.Monthly': '매월',
    'Business.Charge.Day': '일',
    'Business.Charge.BasicCard': '기본 카드',
    'Business.AutoClaimDate': '자동 청구일',
    'Business.Charge.SelectCard': '카드 선택',
    'Business.Product.Guide': '상품 안내문구',
    'Business.SelectedWibleZone': '선택된 스테이션',
    'Business.SearchWibleZone': '스테이션 검색',
    'Business.WibleZone.Name': '스테이션명',
    'Business.WibleZone.Addr': '스테이션 주소',
    'Business.WibleZone.WibleZoneCnt': '차량대수',
    'Business.Email.Placeholder': '이메일 주소 입력',
    'Business.WibleZone.Contract.WibleZoneCnt': '계약차량 대수',
    'Business.Contract.WibleZone.Cnt': '스테이션 개수',
    'Business.ContractYn': '계약등록 여부',
    'Business.ContractYn.Y': '등록',
    'Business.ContractYn.N': '미등록',
    'Business.Contract.PaymentInfo': '결제정보',
    'Business.EveryCheck': '전체 선택',
    'Business.Penalty': '페널티',

    // 회원사 관리 > 기업 상담이력
    'CustomerService.Business.Placeholder': '기업명 입력',
    'CustomerService.Resister.Placeholder': '담당자명 입력',
    'CustomerService.User.Placeholder': '상담자명 입력',
    'CustomerService.Status.AllSelect': '처리결과 전체',
    'CustomerService.LisenceType.AllSelect': '상품유형 전체',
    'CustomerService.SubTitle': '신청내역',
    'CustomerService.Business': '기업명',
    'CustomerService.Resister': '담당자명',
    'CustomerService.LisenceType': '상품유형',
    'CustomerService.Question': '궁금한 점',
    'CustomerService.Response': '상담내역',
    'CustomerService.Telnum': '부서명',
    'CustomerService.Email': '부서명',
    'CustomerService.RequestDate': '신청일시',
    'CustomerService.Response.Placeholder': `    ∙ 업종
    ∙ 사업장 주소
    ∙ 현재 업무용 차량 운영 대수
    ∙ 희망 계약차량 대수
    ∙ 기타 상담내역`,
    'CustomerUser.Coupon.DiscountAmount': '할인액',

    // 회원/부서 관리 > 회원
    'CustomerUser.Stats':
        '어제: (정) {yesterdayRegularCnt}명 (준) {yesterdayAssociateCnt}명 | 이번달: (정) {monthRegularCnt}명 (준) {monthAssociateCnt}명 | 총: (정) {totalRegularCnt}명 (준) {totalAssociateCnt}명',
    'CustomerUser.Title': '회원 조회',
    'CustomerUser.UserID': '회원 ID',
    'CustomerUser.UserID.Placeholder': '회원 ID 입력',
    'CustomerUser.Name': '회원명',
    'CustomerUser.Name.Placeholder': '회원명 입력',
    'CustomerUser.ReferralCode': '회원고유코드',
    'CustomerUser.ReferralCode.Placeholder': '회원고유코드 입력',
    'CustomerUser.ActivationStatusCd': '회원상태',
    'CustomerUser.MobileNumber': '연락처',
    'CustomerUser.JoinDate': '가입일',
    'CustomerUser.Marketing': '마케팅 수신 동의여부',
    'CustomerUser.ReservationAlim': 'PUSH 정보 수신',
    'CustomerUser.Address': '주소',
    'CustomerUser.DriverLicense': '면허정보',
    'CustomerUser.MembershipCd': '등급',
    'CustomerUser.MembershipCd.Lv': '회원등급',
    'CustomerUser.ExpectMembershipCd': '다음달 예상 등급',
    'CustomerUser.BusinessProfile.YN': '비즈니스 프로필 생성여부',
    'CustomerUser.Business_Profile': '비즈니스 프로필 여부',
    'CustomerUser.AuthCode': '비즈니스 프로필 인증코드',
    'CustomerUser.BusinessName': '회원사 정보',
    'CustomerUser.MyBusinessName': '소속회원사',
    'CustomerUser.DepartmentName': '부서정보',
    'CustomerUser.Auth': '권한',
    'CustomerUser.CompanyEmail': '회사 이메일',
    'CustomerUser.PersonalCardInfo': '개인 결제카드 정보',
    'CustomerUser.CardCompanyName': '카드 회사 명',
    'CustomerUser.CardNumber': '카드 번호',
    'CustomerUser.CouponCnt': '보유쿠폰',
    'CustomerUser.RecommendCnt': '추천실적',
    'CustomerUser.ConsultationCnt': '상담내역',
    'CustomerUser.PenaltyPoint': '상담내역',
    'CustomerUser.PenaltyFee': '누적 과태료',
    'CustomerUser.Penalty': '페널티',
    'CustomerUser.ReceivablesPay': '누적 미수금',
    'CustomerUser.Personal': '개인',
    'CustomerUser.Business': '비즈니스',
    'CustomerUser.PurchaseDtl': '구매/대여내역',
    'CustomerUser.TotalUseCnt': '총 이용 횟수',
    'CustomerUser.Subject': '제목',
    'CustomerUser.BirthDate': '생년월일',
    'CustomerUser.SendContents': '발송내용',
    'CustomerUser.CouponList': '쿠폰 리스트',
    'CustomerUser.Recommend.UserID': '회원ID',
    'CustomerUser.Recommend.Name': '회원명',
    'CustomerUser.Recommend.UserStatus': '회원상태',
    'CustomerUser.Recommend.JoinDate': '가입일자',
    'CustomerUser.Subject.Placeholder': '제목을 입력하세요',
    'CustomerUser.SendContents.Placeholder': '내용을 입력하세요',
    'CustomerUser.Serach': '회원 검색',
    'CustomerUser.ActivationStatusCd.Placeholder': '회원상태 전체',
    'CustomerUser.MembershipCd.Placeholder': '회원등급 전체',
    'CustomerUser.ServiceInformationAlim': 'PUSH 정보 수신',
    'CustomerUser.Recommend': '추천 실적',
    'CustomerUser.DriverReissueYn.Message':
        '<span>(*재발급 1년 미만의 운전면허정보 등록)</span>',
    'CustomerUser.Nationality': '내/외국인',
    'CustomerUser.PassportStatusCd': '여권 승인상태',
    'CustomerUser.Lang': '사용언어',
    'CustomerUser.Nationality.All': '내/외국인 전체',
    'CustomerUser.PassportStatusCd.All': '여권 승인상태 전체',
    'CustomerUser.Lang.All': '사용언어 전체',

    // 회원/부서 관리 > 임직원 조회
    'BusinessMember.Stats': '총: (정) {regularCount}명 (준) {associateCount}명',
    'BusinessMember.CompanyEmail.Placeholder': '회사 이메일 입력',
    'BusinessMember.SubTitle.View': '임직원 상세',
    'BusinessMember.SubTitle.Detail': '임직원 수정',
    'BusinessMember.SubTitle.BusinessMemberInfo': '임직원 정보',
    'BusinessMember.SubTitle.UserInfo': '회원 정보',
    'BusinessMember.SubTitle.IndividualBusinessMemberInfo': '임직원 개인 정보',
    'BusinessMember.SubTitle.BDInfo': '회사 및 부서정보',
    'BusinessMember.LicenseCertificate': '면허인증여부',
    'BusinessMember.LicenseStatusCd': '운전면허 승인상태',
    'BusinessMember.Delete.Message1': `{departmentName} {name}님의 비즈니스 프로필을 삭제하시겠습니까?`,
    'BusinessMember.Delete.Message2': `삭제 처리 후에는 해당 임직원이 업무용으로 MOCEAN Car Sharing 차량을 이용할 수 없게 됩니다.`,
    'BusinessMember.Delete.Message3': `*추후 해당 임직원의 업무용 재이용을 원할 경우, 임직원 등록 및 인증 절차를 다시 진행해야 합니다.`,
    'BusinessMember.Customer.Cellphone.Masked':
        '{firstNumber}-****-{lastNumber}',
    'BusinessMember.LicenseYN': '면허인증',
    'BusinessMember.Role.Delete': '이용권한 삭제',

    // 회원/부서 관리 > 임직원 등록
    'BusinessInvite.Title.Group': '단체회원 등록',
    'BusinessInvite.Title.Single': '개별회원 등록',
    // 'BusinessInvite.Create.Tip.1': `- MOCEAN Car Sharing 계정에 우리 회사 소속의 <span>비즈니스 프로필 등록 가능한 권한을 부여</span>할 수 있습니다.`,
    // 'BusinessInvite.Create.Tip.2': `- 임직원의 회사 이메일을 등록 시, <span>해당 이메일로 비즈니스 프로필 인증 및 생성</span>이 가능합니다.`,
    // 'BusinessInvite.Create.Tip.3': `- 임직원에게 MOCEAN Car Sharing 비즈니스 프로필 생성을 안내/독려하고자 하는 경우, <span>등록 후 초대 메일</span>을 발송해주세요.  비즈니스 프로필 생성을 위한 인증코드와 이용 가이드가 포함된 초대 메일이 발송됩니다.`,
    // 'BusinessInvite.Create.Tip.4': `- 2인 이상의 임직원을 한꺼번에 등록하고자 하실 경우, <span>양식을 다운로드 받아 작성 후  파일 업로드 > [확인] 버튼</span>을 눌러주세요.`,
    'BusinessInvite.Create.Tip.1': `- MOCEAN Car Sharing에 가입한 회원에게 <span>우리 회사의 비즈니스 프로필을 생성 가능한 권한을 부여</span>할 수 있습니다.`,
    'BusinessInvite.Create.Tip.2': `- 본 화면에서 <span>등록된 임직원은 MOCEAN Car Sharing App에서 회사 이메일로 비즈니스 프로필 인증과 생성</span>이 가능합니다.`,
    'BusinessInvite.Create.Tip.3': `- 임직원에게 비즈니스 프로필 생성을 안내/독려하고자 하는 경우, <span>등록 후 '등록 현황' 탭에서 초대 메일을 발송</span>해주세요.`,
    'BusinessInvite.Create.Tip.4': `- 2인 이상의 임직원을 한꺼번에 등록하고자 하실 경우, <span>단체회원 등록</span>의 양식을 다운로드 받아 작성 후,  파일을 업로드해 주세요.`,
    'BusinessInvite.List.Tip.1': `- 임직원에게 MOCEAN Car Sharing 비즈니스 프로필 생성을 안내/독려하고자 하는 경우, <span>등록 후 초대 메일을 발송</span>해주세요.`,
    'BusinessInvite.List.Tip.2': ` 비즈니스 프로필 생성을 위한 <span>이용 가이드가 포함된 초대 메일</span>이 발송됩니다.`,
    'BusinessInvite.Email.Confirm': '이메일을 발송하시겠습니까?',
    'BusinessInvite.Email.Success': '이메일을 발송하였습니다.',
    'BusinessInvite.Department.Select': '부서명 선택',
    'BusinessInvite.CreatorName': '등록자',
    'BusinessInvite.Status': '상태',
    'BusinessInvite.UploadCnt': '요청건수',
    'BusinessInvite.SuccessCnt': '성공건수',
    'BusinessInvite.FailCnt': '실패건수',
    'BusinessInvite.Link': '파일링크',
    'BusinessInvite.BusinessID': '회원사 ID',
    'BusinessInvite.CreatedAt': '등록일',
    'BusinessInvite.List.CreatedAt': '등록일시',
    'BusinessInvite.UpdatedAt': '수정일',
    'BusinessInvite.CreatedBy': '생성자',
    'BusinessInvite.BusinessName': '회원사명',
    'BusinessInvite.List.BusinessName': '회사명',
    'BusinessInvite.DepartmentName': '부서명',
    'BusinessInvite.DepartmentID': '부서 ID',
    'BusinessInvite.DepartmentManager': '부서담당자',
    'BusinessInvite.CompanyEmail': '회사 이메일',
    'BusinessInvite.List.CompanyEmail': '회사 이메일',
    'BusinessInvite.BusinessProfileStatus': '프로필 진행상태',
    'BusinessInvite.FailMsg':
        '일부 데이터를 저장할 수 없습니다. 다시 확인해주세요.',
    'BusinessInvite.AllFailMsg': '저장할 데이터를 다시 확인해주세요.',
    'BusinessInvite.NoDataMsg': '저장할 데이터가 없습니다.',

    // 회원/부서 관리 > 부서
    'Department.DepartmentName': '부서명',
    'Department.DepartmentManager': '부서 담당자',
    'Department.RepresentativeDepartmentManager': '대표 부서 담당자',
    'Department.RepresentativeDepartmentManager.Placeholder':
        '대표 부서 담당자 선택',
    'Department.Code': '부서코드',
    'Department.DepartmentCnt': '가입 부서원',
    'Department.isTeamVehicle': '부서전용차량여부',
    'Department.paymentMethod': '결제수단',
    'Department.License': '가입상품',
    'Department.BusinessName': '회원사명',
    'Department.BusinessName.Placeholder': '회원사명 입력',
    'Department.BusinessName.Search': '회원사명 검색',
    'Department.DepartmentName.Placeholder': '부서명 입력',
    'Department.Select': '부서 선택',
    'Department.All': '부서 전체',
    'Department.Placeholder': '부서명 입력',
    'Department.DepartmentManager.Placeholder': '대표 부서 담당자 입력',
    'Department.DepartmentManager.Search': '부서 담당자 검색',
    'Department.CreateMethod': '부서 등록 방식',
    'Department.CreateMethod.Single': '개별 추가',
    'Department.CreateMethod.Single.Subtitle': '부서 개별 추가',
    'Department.CreateMethod.Multiple': '일괄 추가',
    'Department.CreateMethod.Multiple.Subtitle': '부서 일괄 추가',
    'Department.Edit': '부서정보 수정',
    'Department.DepartmentManager.Create': '부서 담당자 등록',
    'Department.DepartmentManager.Create.Message':
        '※ 검색 후 저장 시, 바로 부서 담당자는 권한이 부여됩니다.',
    'Department.CreateResult':
        '단체회원 등록 결과 <span className="titleInfo02">(최근 7일에 대한 내역만 조회)</span>',
    'Department.Btn.Create': '부서 등록',

    // 회원/부서 관리 > 회원사 권한
    'BusinessOperator.List': '회원사 권한 정보',

    // 회원/부서 관리 > 운전면허 검증이력
    'License.Check.RentalTime': '대여시간',
    'License.Check.CreatedAt': '요청시간',
    'License.Check.PhoneNumber': '전화번호',
    'License.Check.ResidentName': '운전자 이름',
    'License.Check.ResidentDate': '운전자 생년월일',
    'License.Check.ResponseCode': '응답결과',
    'License.Check.ResponseMessage': '응답메시지',
    'License.Check.LicenseKind': '운전면허종류',
    'License.Check.Number': '면허번호',
    'License.Check.Type': '면허종류',
    'LicenseCheck.RentalTime': '대여시간',
    'LicenseCheck.CreatedAt': '등록일자',
    'LicenseCheck.PhoneNumber': '전화번호',
    'LicenseCheck.PhoneNumber.Placeholder': '전화번호 입력',
    'LicenseCheck.ResidentName': '운전자명',
    'LicenseCheck.ResidentName.Placeholder': '운전자명 입력',
    'LicenseCheck.ResidentDate': '생년월일',
    'LicenseCheck.ResidentDate.Placeholder':
        '운전자 생년월일 (YYMMDD, 6자리) 입력',
    'LicenseCheck.ResidentEmail': '회원 이메일',
    'LicenseCheck.ResidentEmail.Placeholder': '회원 이메일 입력',
    'LicenseCheck.ResponseCreateAt': '검증일시',
    'LicenseCheck.ResponseCode': '응답결과',
    'LicenseCheck.ResponseMessage': '검증값' /*{ 음답메시지 }*/,
    'LicenseCheck.LicenseKind': '운전면허종류',
    'LicenseCheck.SubTitle': '운전면허 검증 이력',

    // 회원/부서 관리 > 페널티
    'Penalty.List': '페널티 내역',
    'Penalty.Policy': '페널티 정책',
    'Penalty.UserID': '회원 ID',
    'Penalty.UserID.Placeholder': '회원 ID 입력',
    'Penalty.UserName': '회원명',
    'Penalty.UserName.Placeholder': '회원명 입력',
    'Penalty.ReservationKey': '대여번호',
    'Penalty.ReservationKey.Placeholder': '대여번호 입력',
    'Penalty.ReservationType': '대여유형',
    'Penalty.ReservationType.Placeholder': '대여유형 입력',
    'Penalty.BusinessName': '회원사명',
    'Penalty.DepartmentName': '부서명',
    'Penalty.PenaltyCategory': '페널티 항목',
    'Penalty.PenaltyCategory.Select': '페널티 항목 선택',
    'Penalty.PenaltyCategory.All': '페널티 항목 전체',
    'Penalty.ProfileTypeCd.All': '프로필 유형 전체',
    'Penalty.RegistrationDate': '페널티 등록일',
    'Penalty.ViolationDesc': '위반사항',
    'Penalty.ViolationDesc.Select': '위반사항 선택',
    'Penalty.ViolationDesc.All': '위반사항 전체',
    'Penalty.ViolationDesc.Placeholder': '위반사항 입력',
    'Penalty.Point': '점수',
    'Penalty.PenaltyFee': '페널티 요금',
    'Penalty.PenaltyPoint': '페널티 점수',
    'Penalty.AccumulatedPenaltyPoint': '누적 페널티 점수',
    // 'Penalty.PenaltyPoint.Minus': '페널티 점수 차감',
    'Penalty.PenaltyUseStopYn': '이용정지 여부',
    'Penalty.CreateDt': '등록일',
    'Penalty.Register': '등록자',
    'Penalty.Description': '추가내용',
    'Penalty.Complete.Alert.Message':
        '페널티 요금이 면제되어 결제 없이 처리완료 합니다. 이대로 진행 하겠습니까?',
    // 'Penalty.Description.Minus':
    //     '페널티 점수 면제 완료(기존 페널티 점수 : {penaltyPoint}점)',

    // 매출/정산 관리 > 매출
    'Sales.MID.Select.Placeholder': 'MID 전체',
    'Sales.ProfileTypeCd.Select.Placeholder': '프로필 유형 전체',
    'Sales.SalesTypeCd.Select.Placeholder': '매출 구분 전체',
    'Sales.Account': '계좌번호',
    'Sales.MID': 'MID',
    'Sales.ProfileType': '프로필 유형',
    'Sales.ChargeGroup': '요금그룹',
    'Sales.Personal': '개인 상품',
    'Sales.Business': '비즈니스 상품',
    'Sales.SalesTypeCd': '매출구분',
    'Sales.Sales.Amount': '매출금액',
    'Sales.SalesTime': '일시',
    'Sales.Stat': '어제 {yesterday}円 | 이번 달 {month}円 | 총 {total}円',
    'Sales.BusinessName': '회원사',
    'Sales.BusinessName.Placeholder': '회원사 입력',
    'Sales.DepartmentName': '부서명',
    'Sales.DepartmentName.Placeholder': '부서명 입력',
    'Sales.UserName': '대여회원',
    'Sales.UserName.Placeholder': '회원명 입력',
    'Sales.ReservationType': '대여유형',
    'Sales.ReservationKey': '대여번호',
    'Sales.ReservationKey.Placeholder': '대여번호 입력',
    'Sales.ReservationVehicle': '대여차량',
    'Sales.Accounting.Download': '회계용 엑셀 다운로드',
    'Sales.SubscribeID': '구독번호',
    'Sales.SubscribeID.Placeholder': '구독번호 입력',
    'Sales.PaymentID': '결제번호',
    'Sales.PaymentID.Placeholder': '결제번호 입력',
    'Sales.LicensePlateNumber': '차량번호',
    'Sales.LicensePlateNumber.Placeholder': '차량번호 입력',
    'Sales.LocationName': '스테이션',
    'Sales.LocationName.Placeholder': '스테이션 입력',
    'Sales.Reservation.Type': '프로필 유형',
    'Sales.ReservationType.All': '프로필 유형 전체',
    'Sales.ReservationType.BUSINESS': '업무용',
    'Sales.ReservationType.PERSONAL': '개인용',
    'Sales.ReservationPeriod': '대여기간',
    'Sales.RentalPeriod': '실 사용기간',
    'Sales.SalesClassCd': '매출 구분',
    'Sales.SalesClassCd.All': '매출 구분 전체',
    'Sales.PaymentClassCd': '결제 구분',
    'Sales.PaymentClassCd.All': '결제 구분 전체',
    'Sales.ChargeGroup.All': '요금 그룹 전체',
    'Sales.ChargeType': '개인 상품',
    'Sales.ChargeType.All': '개인 상품 전체',
    'Sales.LicenseTypeCd': '비즈니스 상품',
    'Sales.LicenseTypeCd.All': '비즈니스 상품 전체',
    'Sales.AuthDate': '결제일시',
    'Sales.AuthDate.Label': '결제일',
    'Sales.ModelName': '차종',
    'Sales.UserDepartmentName': '대여회원 부서명',
    'Sales.RealPay': '결제금액',
    'Sales.PaymentType': '결제 방식',
    'Sales.PaymentInfo': '결제 정보',
    'Sales.PaymentMethod': '결제수단',
    'Sales.ChargeEvidence': '결제 증빙자료',
    'Sales.ChargeCompleteTime': '정산 완료 일시',
    'Sales.Charge.Type': '청구 수단',
    'Sales.ChargeTime': '청구 일시',
    'Sales.ApproveNumber': '승인번호',
    'Sales.ApproveStatus': '승인상태',
    'Sales.LicenseType': '상품명',
    'Sales.LicenseType.PlaceHolder': '비즈니스 상품 선택',
    'Sales.Provider': '공급사',
    'Sales.Provider.Placeholder': '공급사 입력',
    'Sales.TollTime': '통행료 발생 일시',
    'Sales.TollFee': '통행료',
    'Sales.TotalTollFee': '통행요금',
    'Sales.RentalDelayFee': '서비스 지연요금(円/분)',
    'Sales.RentalDelayTime': '지연시간(분)',
    'Sales.PenaltyFee': '페널티 요금',
    'Sales.PenaltyCategory': '페널티 항목',
    'Sales.PenaltyPoint': '페널티 점수',
    'Sales.ViolationDesc': '위반사항',
    'Sales.VehicleRepairFee': '사고처리 요금',
    'Sales.CancelFee': '취소 수수료',
    'Sales.InsuranceFee': 'NOC보상',
    'Sales.InsuranceName': 'NOC보상명',
    'Sales.Insurance.SelfCost': '자기부담금',
    'Sales.RentalFee': '대여요금',
    'Sales.ExtendFee': '연장 대여요금',
    'Sales.UserID': '회원ID',
    'Sales.User.Name': '회원명',
    'Sales.UserTelnum': '연락처',
    'Sales.PaymentInfo.DrivingFee': '주행요금 및 통행료 지불 방법',
    'Sales.SubscribeGroupName': '구독 상품명',
    'Sales.SubscribePeriod': '구독 계약 기간',
    'Sales.DrivingFee': '주행요금',
    'Sales.DrivingDistance': '주행거리(km)',
    'Sales.DrivingDistanceFee': '주행요금(円/km)',

    // 매출/정산 관리 > 쿠폰 매출
    // 'CouponSales.Stats': '· 할인 금액 어제 {prevDayDiscountSum}円 | 총 {totalDiscountSum}円   · 연계 발생 매출 어제 {prevDaySum}円 | 총 {totalSum}円',
    'CouponSales.Stats.1': '· 할인 금액',
    'CouponSales.Stats.2': '어제 {prevDayDiscountSum}円',
    'CouponSales.Stats.3': '총 {totalDiscountSum}円',
    'CouponSales.Stats.4': '· 연계 발생 매출',
    'CouponSales.Stats.5': '어제 {prevDaySum}円',
    'CouponSales.Stats.6': '총 {totalSum}円',
    'CouponSales.SalesClassCd': '매출구분',
    'CouponSales.SalesClassCd.All': '매출구분 전체',
    'CouponSales.CouponTypeCd': '쿠폰타입',
    'CouponSales.CouponTypeCd.All': '쿠폰타입 전체',
    'CouponSales.CouponTypeCd.Select': '쿠폰타입 선택',
    'CouponSales.DiscountTypeCd': '할인타입',
    'CouponSales.DiscountTypeCd.All': '할인타입 전체',
    'CouponSales.DiscountTypeCd.Select': '할인타입 선택',
    'CouponSales.CouponKey': '쿠폰관리번호',
    'CouponSales.CouponKey.Placeholder': '쿠폰관리번호 입력',
    'CouponSales.CouponName': '쿠폰명',
    'CouponSales.CouponName.Placeholder': '쿠폰명 입력',
    'CouponSales.UsePay': '이용금액',
    'CouponSales.CouponDiscountPay': '할인금액',
    'CouponSales.RealPay': '결제금액',
    'CouponSales.ReservationType': '프로필 유형',
    'CouponSales.ReservationType.All': '프로필 유형 전체',
    'CouponSales.ReservationType.Label': '대여 유형',
    'CouponSales.UserName': '회원명',
    'CouponSales.UserName.Placeholder': '회원명 입력',
    'CouponSales.LocationName': '스테이션',
    'CouponSales.LocationName.Placeholder': '스테이션 입력',
    'CouponSales.ModelName': '차종',
    'CouponSales.ModelName.Placeholder': '차종 입력',
    'CouponSales.Provider': '공급사',
    'CouponSales.Provider.All': '공급사 전체',
    'CouponSales.ChargeTypeCd': '요금그룹',
    'CouponSales.ChargeTypeCd.All': '요금그룹 전체',
    'CouponSales.ChargeName': '개인상품',
    'CouponSales.ChargeID.All': '개인상품 전체',
    'CouponSales.LicenseTypeCd': '비즈니스상품',
    'CouponSales.LicenseTypeCd.All': '비즈니스상품 전체',
    'CouponSales.PaymentTypeCd': '정산 구분',
    'CouponSales.PaymentTypeCd.All': '정산 구분 전체',
    'CouponSales.PaymentTypeCd.CLAIM': '청구 완료',
    'CouponSales.PaymentTypeCd.PAYMENT': '정산 완료',
    'CouponSales.UseDate': '쿠폰 사용일시',
    'CouponSales.SearchDate': '쿠폰사용기간',
    'CouponSales.ClaimDate': '청구 일시',
    'CouponSales.SalesInfo': '쿠폰 매출 정보',
    'CouponSales.CouponInfo': '쿠폰 정보',
    'CouponSales.UnitDiscountRate': '할인단위',
    'CouponSales.DiscountPay': '할인금액',
    'CouponSales.UserInfo': '회원 정보',
    'CouponSales.UserEmail': '회원 ID',
    'CouponSales.UserMobileNumber': '연락처',
    'CouponSales.BusinessName': '회원사',
    'CouponSales.DepartmentName': '부서명',
    'CouponSales.BusinessEmail': '회사 이메일',
    'CouponSales.SubscribeInfo': '구독 정보',
    'CouponSales.SubscribeID': '구독번호',
    'CouponSales.SubscribeChargeName': '구독 상품명',
    'CouponSales.SubscribeReservationTime': '구독 계약 기간',
    'CouponSales.ReservationInfo': '대여 정보',
    'CouponSales.ReservationKey': '대여번호',
    'CouponSales.LicensePlateNumber': '차량번호',
    'CouponSales.LicensePlateNumber.Label': '대여차량',
    'CouponSales.ChargeName.Label': '상품명',
    'CouponSales.ReservationTime': '대여기간',
    'CouponSales.ReservationTime.ADDITION': '개별 계약기간',
    'CouponSales.RentalEndTime': '실 사용기간',
    'CouponSales.CalculateInfo': '정산 정보',
    'CouponSales.PaymentMethod': '청구 수단',
    'CouponSales.PaymentMethodInfo': '결제정보',
    'CouponSales.CompleteDate': '정산 완료 일시',
    'CouponSales.isCouponRevenueProtected' : '쿠폰 매출 보전 여부',
    'CouponSales.isCouponRevenueProtected.ALL' : '쿠폰 매출 보전 여부 전체',
    // 매출/정산 관리 > 정산
    'Calculation.Stats':
        '정산 {calculationSum}円 | 청구완료 {claimSum}円 | 미정산 {unCalculationSum}円',
    'Calculation.Stats.1': '정산 {calculationSum}円',
    'Calculation.Stats.2': '청구완료 {claimSum}円',
    'Calculation.Stats.3': '미정산 {unCalculationSum}円',
    'Calculation.CalculateType': '정산대상',
    'Calculation.CalculateType.All': '정산대상 전체',
    'Calculation.CalculateType.BUSINESS': '회원사',
    'Calculation.CalculateType.PERSONAL': '개인',
    'Calculation.BusinessName': '회원사명',
    'Calculation.BusinessName.Label': '회원사',
    'Calculation.UserName': '회원명',
    'Calculation.UserName.Label': '회원',
    'Calculation.UserName.Placeholder': '회원명 입력',
    'Calculation.CalculateMethod': '정산유형',
    'Calculation.CalculateMethod.All': '정산유형 전체',
    'Calculation.CalculateMethod.EXACT_CALCULATION': '별도정산',
    'Calculation.CalculateMethod.MANUAL_CALCULATION': '수기정산',
    'Calculation.SalesClassCd': '매출구분',
    'Calculation.SalesClassCd.All': '매출구분 전체',
    'Calculation.SalesClassCd.Select': '매출구분 선택',
    'Calculation.LicenseTypeCd': '비즈니스 상품',
    'Calculation.LicenseTypeCd.All': '비즈니스 상품 전체',
    'Calculation.CalculateStartEndDate.Label': '정산 기간(발생일자)',
    'Calculation.CalculateStartEndDate': '정산기간',
    'Calculation.CalculateStartEndDate.StartDate': '정산시작일시',
    'Calculation.CalculateStartEndDate.EndDate': '정산종료일시',
    'Calculation.CalculateDate': '발생일자',
    'Calculation.CalculateDate.Placeholder': '발생일자 선택',
    'Calculation.PaymentMethod': '결제 수단',
    'Calculation.PaymentMethod.All': '결제 수단 전체',
    'Calculation.PaymentMethod.CARD': '카드결제',
    'Calculation.PaymentMethod.CASH': '계좌이체',
    'Calculation.CreatedAt': '정산 등록 일시',
    'Calculation.CalculateEndTime': '정산 완료 일시',
    'Calculation.SearchDate': '정산완료일',
    'Calculation.CalculatePay': '정산 금액',
    'Calculation.CalculatePay.Placeholder': '정산 금액 입력',
    'Calculation.CalculatePay.Search': '정산 금액 조회',
    'Calculation.CalculatePay.Search.Helper': '정산 금액 조회 해주세요.',
    'Calculation.CalculatePay.None': '정산 금액이 0円 입니다.',
    'Calculation.CalculatePay.None1':
        '설정한 정산 기간에 정산할 대상이 없습니다.',
    'Calculation.CalculatePay.None2': '정산 대상이 없어 등록이 불가합니다.',
    'Calculation.CalculateStatus': '정산상태',
    'Calculation.CalculateStatus.All': '정산상태 전체',
    'Calculation.CalculateStatus.Label': '상태',
    'Calculation.CalculateStatus.UN_CALCULATE': '미정산',
    'Calculation.CalculateStatus.CLAIM_COMPLETE': '청구완료',
    'Calculation.CalculateStatus.COMPLETE': '정산완료',
    'Calculation.Memo': '메모',
    'Calculation.Memo.Placeholder': '메모 입력',
    'Calculation.BaseInfo': '기본 정보',
    'Calculation.ClaimInfo': '청구 정보',
    'Calculation.ClaimTime': '청구 일시',
    'Calculation.PaymentMethodInfo': '결제정보',
    'Calculation.ClaimFileName.Label': '결제 증빙자료 첨부',
    'Calculation.PaymentCard': '결제 카드',
    'Calculation.Evidence': '증빙 자료 ',
    'Calculation.CalculateClaim.List': '정산 대상 목록',
    'Calculation.ReservationTime': '발생일자',
    'Calculation.ReservationKey': '대여번호',
    'Calculation.LocationName': '스테이션',
    'Calculation.DepartmentName': '부서',
    'Calculation.OdoKilometer': '주행거리(km)',
    'Calculation.ActionClaim': '청구하기',
    'Calculation.ActionClaim.Confirm': '청구 하시겠습니까?',
    'Calculation.ActionClaim.Success': '청구 하였습니다.',
    'Calculation.ActionClaim.Failure': '청구 할 수 없습니다.',
    'Calculation.ActionCalculation': '정산완료',
    'Calculation.ActionCalculation.Confirm': '정산완료 하시겠습니까?',
    'Calculation.ActionCalculation.Success': '정산완료 하였습니다.',
    'Calculation.ActionCalculation.Failure': '정산완료 할 수 없습니다.',
    'Calculation.ActionCalculation.Title': '정산 완료하기',
    'Calculation.ActionCalculationCard.Text':
        '아래 정산 금액을 카드로 결제합니다.',
    'Calculation.ActionCalculationCard.Text2':
        '결제카드를 설정하신 후 정산완료를 선택하세요.',
    'Calculation.ActionCalculationCash.Text':
        '아래 정산 금액을 계좌이체로 확인합니다.',
    'Calculation.ActionCalculationCash.Text2':
        '결제 증빙자료를 첨부하신 후 정산완료를 선택하세요.',
    'Calculation.MID': 'MID',
    'Calculation.MID.Select': 'MID 선택',

    // 매출/정산 관리 > 쿠폰
    'Coupon.Code.Issued': '발급',
    'Coupon.Code.NotIssued': '미발급',
    'Coupon.CouponKey': '관리번호',
    'Coupon.CouponKey.Placeholder': '관리번호 입력',
    'Coupon.CouponTypeCd': '쿠폰타입',
    'Coupon.CouponTypeCd.All': '쿠폰타입 전체',
    'Coupon.CouponTypeCd.Select': '쿠폰타입 선택',
    'Coupon.DiscountTypeCd': '할인타입',
    'Coupon.DiscountTypeCd.All': '할인타입 전체',
    'Coupon.DiscountTypeCd.Select': '할인타입 선택',
    'Coupon.ProfileTypeCd': '유형',
    'Coupon.ProfileTypeCd.All': '유형 전체',
    'Coupon.CouponName': '쿠폰명',
    'Coupon.CouponName.Placeholder': '쿠폰명 입력',
    'Coupon.Status': '상태',
    'Coupon.Status.All': '상태 전체',
    'Coupon.Date.Issued': '다운로드 기한',
    'Coupon.Date.Used': '사용기간',
    'Coupon.CouponCode': '쿠폰코드',
    'Coupon.CouponCode.Placeholder': '쿠폰코드 입력',
    'Coupon.Reward.Desc': '※ 5km 이상 주행, 이용 전보다 충전량이 많은 상태로 반납시 리워드 쿠폰이 발행됩니다.',
    'Coupon.Weekdays.Holidays': '평일/휴일',
    'Coupon.Weekdays.Holidays.All': '요일 전체',
    'Coupon.Day': '요일',
    'Coupon.IssuedNumber': '발행 매수',
    'Coupon.IssuedNumber.Placeholder': '발행매수 입력',
    'Coupon.IssuedNumber.Validator.Unlimit': '무제한을 선택해주세요.',
    'Coupon.IssuedNumber.Posible': '발행 가능 매수',
    'Coupon.Detail.CouponBasicInformation': '쿠폰 기본 정보',
    'Coupon.Detail.PublicationInformation': '발행 정보',
    'Coupon.Detail.CouponNo': '쿠폰번호',
    // 'Coupon.Detail.CouponNo.Placeholder': '쿠폰번호 입력',
    'Coupon.Detail.CouponStatus': '쿠폰상태',
    'Coupon.Deatil.CouponName.Placeholder': '쿠폰명 입력',
    'Coupon.Detail.Coupon.Publisher' : '쿠폰 발행 주체',
    'Coupon.Detail.Coupon.Publisher.Placeholder' : '쿠폰 발행 공급사(프렌차이즈) 선택',

    'Coupon.Detail.Description.App': '쿠폰설명(App)',
    'Coupon.Detail.Description.App.Placeholder': '쿠폰설명 입력',
    'Coupon.Detail.Description.Admin': '쿠폰설명(Admin)',
    'Coupon.Detail.Description.Admin.Placeholder': '쿠폰설명 입력',
    'Coupon.Detail.AddWithAply': '다운로드일 기준',
    'Coupon.Detail.AddWithAply.Value': '다운로드일 기준 {value} 일',
    'Coupon.Detail.AddWithAply.Placeholder': '숫자',
    'Coupon.RegisterableNumber': '등록 가능 수량',
    'Coupon.RegisterableNumber.Placeholder': '등록 가능 수량 입력',
    'Coupon.CouponClassCd.Placeholder': '자동발급구분 선택',
    'Coupon.Detail.DiscountInformation': '할인 정보',
    'Coupon.Detail.UnitFieldInfo': '쿠폰 단위',
    'Coupon.Detail.UnitField.TIME': '시간(분)',
    'Coupon.Detail.UnitField.TIME.Placeholder': '분 입력',
    'Coupon.Detail.UnitField.TIME.Tail': '분',
    'Coupon.Detail.UnitField.TIME.View': '시간 | ',
    'Coupon.Detail.UnitField.AMOUNT': '금액(円)',
    'Coupon.Detail.UnitField.AMOUNT.Placeholder': '금액 입력',
    'Coupon.Detail.UnitField.AMOUNT.Tail': '円',
    'Coupon.Detail.UnitField.AMOUNT.View': '금액 | ',
    'Coupon.Detail.UnitField.DISCOUNTRATE': '할인율(%)',
    'Coupon.Detail.UnitField.DISCOUNTRATE.Placeholder': '할인율 입력',
    'Coupon.Detail.UnitField.DISCOUNTRATE.Tail': '율',
    'Coupon.Detail.UnitField.DISCOUNTRATE.View': '할인율 | ',
    'Coupon.Detail.LeastFieldInfo': '적용 조건',
    'Coupon.Detail.DiscountInfoLange': '할인 적용 구간',
    'Coupon.Detail.UnitField.TIME.Choice': '시간 선택',
    'Coupon.DiscountStartHour': '시작시간',
    'Coupon.DiscountEndHour': '종료시간',
    'Coupon.Detail.DiscountTimeAs.1': '시작시간으로부터',
    'Coupon.Detail.DiscountTimeAs.2': '분 후 까지',
    'Coupon.Detail.DescountMaxAmt': '최대 할인액',
    'Coupon.Detail.DescountMaxAmt.Placeholder': '최대 할인액 입력',
    'Coupon.Detail.ApplicableInformation': '적용 대상 정보',
    'Coupon.Detail.Applicable.Title': '제휴 정보',
    'Coupon.Detail.Applicable.Business': '적용 회원사',
    'Coupon.Detail.Applicable.WibleZone': '적용 스테이션',
    'Coupon.Detail.Applicable.Product': '적용 상품',
    'Coupon.Detail.Applicable.Pop.AffiliateName': '제휴명',
    'Coupon.Detail.Applicable.Pop.AffiliateName.Placeholder': '제휴명 입력',
    'Coupon.Detail.Applicable.Pop.AffiliateBusinessName': '제휴사',
    'Coupon.Detail.Applicable.Pop.AffiliateBusinessName.Placeholder':
        '제휴사 입력',
    'Coupon.Detail.Applicable.Pop.AffiliateDate': '제휴 기간',
    'Coupon.Detail.Applicable.Pop.CouponCode': '제휴코드',
    'Coupon.Detail.Applicable.Pop.CouponCode.Placeholder': '제휴코드 입력',
    'Coupon.Detail.ActualIssuedNumber': '실 발행 매수',
    'Coupon.Detail.ActualUsedNumber': '실 사용 매수',
    'Coupon.Detail.Customer.List': '회원 조회',
    'Coupon.Detail.Customer.List.Name': '회원명',
    'Coupon.Detail.Customer.List.MobileNumber': '전화번호',
    'Coupon.Detail.Customer.List.Email': '회원 ID',
    'Coupon.Detail.Customer.List.ValidStartDate': '발급시작일자',
    'Coupon.Detail.Customer.List.ValidEndDate': '발급종료일자',
    'Coupon.Detail.Customer.List.useDate': '사용일시',
    'Coupon.Detail.Btn.Business': '회원사 검색',
    'Coupon.Detail.Btn.Business.Choice': '회원사 선택',
    'Coupon.Detail.Btn.WibleZone': '스테이션 검색',
    'Coupon.Detail.Btn.WibleZone.Choice': '스테이션 선택',
    'Coupon.Detail.Btn.Product': '상품 검색',
    'Coupon.Detail.Btn.Product.Choice': '상품 선택',
    'Coupon.Detail.LedgerInfo.Title': '쿠폰 발행 대장',
    'Coupon.Detail.LedgerInfo': '쿠폰 발행대장 내역',
    'Coupon.Detail.LedgerInfo.Random.Description':
        '발행매수만큼 난수를 생성합니다.',
    'Coupon.Detail.LedgerInfo.User.Description':
        '등록된 대상에게 난수로 발행된 쿠폰을 발행합니다.',
    'Coupon.LedgerInfo.IssuedDate': '발행 일시',
    'Coupon.LedgerInfo.IssuedTypeCd': '쿠폰 발행 방식',
    'Coupon.LedgerInfo.IssuedCount': '발행 매수',
    'Coupon.LedgerInfo.IssuedTarget': '발행 대상',
    'Coupon.LedgerInfo.IssuedTarget.Guide': '발행 대상 안내',
    'Coupon.LedgerInfo.RsultDown': '결과 다운로드',
    'Coupon.LedgerInfo.CouponInfo': '쿠폰 정보',
    'Coupon.LedgerInfo.LedgerType': '발행 방식',
    'Coupon.LedgerInfo.LedgerTarget': '발행 대상',
    'Coupon.Detail.DiscountInfoLange.Time':
        '시작시간 : {startTime} | 종료시간 : 시작시간으로부터 {endTime}분 후 까지',
    'Coupon.Detail.User.Leave': '탈퇴회원',
    'Coupon.NationalityCd': '내/외국인',
    'Coupon.NationalityCd.All': '내/외국인 전체',

    // 매출/정산 관리 > 미수금
    'Receivable.ProfileType': '프로필 유형',
    'Receivable.ProfileType.All': '프로필 유형 전체',
    'Receivable.ReceivableType': '미수금 유형',
    'Receivable.ReceivableType.All': '미수금 유형 전체',
    'Receivable.ReceivableInfo': '미수금 정보',
    'Receivable.Business': '회원사',
    'Receivable.Name': '회원명',
    'Receivable.Name.Input': '회원명 입력',
    'Receivable.UserID': '회원 ID',
    'Receivable.UserID.Input': '회원 ID 입력',
    'Receivable.SubscriptionNo': '구독번호',
    'Receivable.RentalInfo': '대여 정보',
    'Receivable.RentalNo': '대여번호',
    'Receivable.CarNo': '차량번호',
    'Receivable.CarNo.Input': '차량번호 입력',
    'Receivable.RentalCarNo': '대여차량',
    'Receivable.ReceivableAmtOne': '미수금(円)',
    'Receivable.ReceivableAmt': '미수금',
    'Receivable.ReceivableDate': '미수금 발행일시',
    'Receivable.ReceivableCreateDate': '미수금 발생일시',
    'Receivable.CustomerInfo': '고객정보',
    'Receivable.Send.Push': '푸시 발송',
    'Receivable.Send.Select.Push': '푸시 선택 발송',
    'Receivable.Send.All.Push': '푸시 전체 발송',
    'Receivable.Charge.Card': '결제 카드',

    // 매출/정산 관리 > 과태료 (하단 주석은 삭제예정)
    'Violation.Stats': '총 {totalCost}円',
    'Violation.RentType.Placeholder': '업무유형',
    'Violation.CompanyName.Placeholder': '회원사 입력',
    'Violation.RenterID.Placeholder': '회원ID 입력',
    //'Vehicle.LicensePlateNumber.Placeholder': '차량번호 입력',
    'Violation.UnpaidType.Placeholder': '미수금 유형',
    'Violation.RentVehicle': '대여차량',
    'Violation.ViolationType': '위반유형',
    'Violation.ViolationType.Placeholder': '위반유형 전체',
    'Violation.ViolationType.Select.Placeholder': '위반유형 선택',
    'Violation.ReservationType.Placeholder': '프로필 유형 전체',
    'Violation.ViolationCharge': '과태료(円)',
    'Violation.ViolationTime': '위반일시',
    'Violation.ViolationTime.Placeholder': '위반일시 입력',
    //'Code.Menu.SystemType.Business': '회원사',
    //'Common.User.Name': '회원명',
    'Violation.Rental.Customer.Tel': '전화번호',
    'Violation.Vehicle.SubTitle': '차량 정보',
    'Violation.Info.SubTitle': '위반 내역',
    //'Vehicle.LicensePlateNumber': '차량번호',
    'Violation.Vehicle.Placeholder': '차량 번호 검색',
    //'License.Check.Number':'면허번호',
    //'License.Check.Type':'면허종류',
    'Violation.Business.CompanyName': '회원사',
    'Violation.Business.CompanyNum': '사업자번호',
    'Violation.Business.Address': '회원 주소',
    'Violation.ViolationLoc': '위반장소',
    'Violation.Detail': '세부내용',
    'Violation.Order.Organization': '발부처',
    'Violation.Handling.Time': '처리일시',
    'Violation.Objection': '이의처리',
    'Violation.Objection.W': '발송 전',
    'Violation.Objection.C': '발송 완료',
    'Violation.ViolationLoc.Placeholder': '위반장소 입력',
    'Violation.Detail.Placeholder': '세부내용 입력',
    'Violation.Order.Organization.Placeholder': '발부처 입력',
    'Violation.Handling.Time.Placeholder': '일시 입력',
    'Violation.Objection.Placeholder': '이의처리 선택',
    'Violation.Objection.Comment.Placeholder': '내용 입력',
    'Violation.User.Name.Placeholder': '회원명 입력',
    'Violation.title': '위반 정보 입력',
    'Violation.getReservation.btn': '대여정보 조회',
    'Violation.Vehicle.Search': '차량 검색',
    'Violation.PenaltyFee': '범칙금',
    'Violation.Msg.NoReservation':
        '입력한 정보에 해당하는 대여정보가 없습니다.',
    'Violation.PenaltyPaper': '과태료 청구서',
    'Violation.TransferTarget': '이관 신청 대상',

    // 상품 관리 > 요금제
    'Charge.Plan': '요금제명',
    'Charge.PlanType': '요금 그룹',
    'Charge.SpecialPlanType': '1회권 특가 요금 타입',
    'Charge.SpecialPlanType.AllSelect': '1회권 특가 요금 타입 전체',
    'Charge.SpecialPlanType.Select': '1회권 특가 요금 타입 선택',
    'Charge.PassPlanType': '구독권 특가 요금 타입',
    'Charge.PassPlanType.AllSelect': '구독권 특가 요금 타입 전체',
    // 'Code.Charge.PassPlanType.WEEKDAY': '평일',
    'Charge.PlanGroup': '요금 그룹',
    'Charge.FreeBaseDistance': '주행요금 무과금 기준 거리',
    'Charge.FreeBaseDistance.Placeholder': '무과금 기준 거리 입력',
    'Charge.BaseDistance': '기준 거리',
    'Charge.BaseDistance.Placeholder': '기준 거리 입력',
    'Charge.ChargeForDistance': '거리당 주행요금',
    'Charge.ChargeForDistance.Placeholder': '거리당 주행요금 입력',
    'Charge.Fee': '요금',
    'Charge.Fee.Placeholder': '요금 입력',
    'Charge.Memo': '메모',
    'Charge.Memo.Placeholder': '메모 입력',
    'Charge.Plan.Placeholder': '요금제 입력',
    'Charge.PlanGroup.AllSelect': '요금 그룹 전체',
    'Charge.PlanName': '요금제 명',
    'Charge.PlanName.Placeholder': '요금제 명 입력',
    'Charge.ProfileType': '프로필 유형',
    'Charge.PlanGuide': '요금제 안내 문구',
    'Charge.PlanGuide.Placeholder': '요금제 안내 문구 입력',
    'Charge.BaseInfo': '기본 정보',
    'Charge.DiscountInfo': '할인 정보',
    'Charge.FeeInfo': '요금 정보',
    'Charge.StartMin': '시작시간',
    'Charge.EndMin': '종료시간',
    'Charge.IncreaseYn': '램프업적용여부',
    'Charge.Min.Placeholder': '분 단위로 입력',
    'Charge.BaseTime': '기준 시간',
    'Charge.BaseFee': '기준 요금',
    'Charge.Month.3': '3개월',
    'Charge.Month.6': '6개월',
    'Charge.Month.12': '12개월',
    'Charge.PlusHour': '플러스 시간',
    'Charge.PlusHour.Placeholder': '플러스 시간 입력',
    'Charge.RentalSpot': '대여 장소',
    'Charge.ReturnSpot': '반납 장소',
    'Charge.Spot.Placeholder': '주소 입력',
    'Charge.GuideInfo.Placeholder': '캠핑요금제 안내 선택',

    // 상품 관리 > NOC보상
    'Insurance.InsuranceFee': 'NOC보상',
    'Insurance.InsuranceFee.Placeholder': '가입비 입력',
    'Insurance.InsuranceName': 'NOC보상명',
    'Insurance.InsuranceName.Placeholder': 'NOC보상명 입력',
    'Insurance.InsuranceGroup': 'NOC보상 그룹',
    'Insurance.InsuranceGroup.Placeholder': 'NOC보상 그룹 입력',
    'Insurance.InsuranceGroupName': 'NOC보상 그룹명',
    'Insurance.InsuranceGroupName.Placeholder': 'NOC보상 그룹명 입력',
    'Insurance.ChargePlanType': '요금 그룹',
    'Insurance.ChargePlanType.AllSelect': '요금 그룹 전체',
    'Insurance.Product': '요금 상품',
    'Insurance.Product.Placeholder': '요금 상품 입력',
    'Insurance.Product.Select': '요금 상품 선택',
    'Insurance.Insurance': '가입비(24시간 기준)',
    'Insurance.Insurance.short': '가입비',
    'Insurance.Insurance1': '보험료1',
    'Insurance.Insurance2': '보험료2',
    'Insurance.Insurance3': '보험료3',
    'Insurance.BaseInfo': '기본 정보',
    'Insurance.SelfCost': '자기부담금',
    'Insurance.SelfCost.Placeholder': '자기부담금 입력',
    'Insurance.ExemptionFee': '면책요금',
    'Insurance.ExemptionFee.Placeholder': '면책요금 입력',
    'Insurance.UseYn': '사용여부',
    'Insurance.StartMin': '시작시간',
    'Insurance.EndMin': '종료시간',
    'Insurance.BaseTime': '기준 시간',
    'Insurance.BaseFee': '기준 요금',
    'Insurance.Won': '円',
    'Insurance.SurchargeRate': '추가금 비율',
    'Insurance.Rate.Placeholder': '비율(%) 입력',

    // 상품 관리 > 부가상품
    'Extra.Name': '부가상품명',
    'Extra.Name.Placeholder': '부가상품명 입력',
    'Extra.ChargeType': '과금 타입',
    'Extra.Memo': '메모',
    'Extra.Memo.PlaceHolder': '메모 입력',
    'Extra.ChargeType.Placeholder': '과금 타입 전체',
    'Extra.Html.File.Placeholder': 'html 파일 등록',
    'Extra.ProductInfo.Language': '이용 안내({value})',
    'Extra.ProductInfo.Placeholder': '부가상품 안내 선택',
    'Extra.BaseCharge': '기본구간 요금',
    'Extra.DelayCharge': '지연구간 요금',
    'Extra.Charge.Placeholder': '요금 입력',
    'Extra.Day.Placeholder': '1일(24시간)',
    'Extra.Asta': '*',

    // 콘텐츠 관리 > 공지/이벤트
    'Notice.Subject': '공지/이벤트 명',
    'Notice.Subject.Placeholder': '공지/이벤트 명 입력',
    'Notice.Contents': '공지 내용',
    'Notice.Target': '게시대상',
    'Notice.StartDate': '노출기간',
    'Notice.EndDate': '노출기간',
    'Notice.NoticePeriod': '게시기간',
    'Notice.CreatedAt': '생성일자',
    'Notice.CreatedBy': '작성자',
    'Notice.NoticeMainYn': '노출여부',
    'Notice.NoticeTypeCd': '카테고리',
    'Notice.NoticeTopYn': '목록 상단 고정',
    'Notice.CompanyName': '회원사 명',
    'Notice.BusinessRegNum': '사업자 번호',
    'Notice.License': '가입상품',
    'Notice.Title.Company': '회원사 정보',
    'Notice.Category.Placeholder': '카테고리 전체',
    'Notice.Target.Placeholder': '게시대상 전체',
    'Notice.NoticeMainYn.Placeholder': '노출여부 전체',
    'Notice.TargetBusiness': '노출 대상 회원사',
    'Notice.FileInfo': '공지/이벤트 파일정보',
    'Notice.NoBusinessMgs': '회원사 미 지정 시 회원사 전체로 등록됩니다.',
    'Notice.Nationality': '가입자',
    'Notice.Nationality.All': '내/외국인 전체',

    // 콘텐츠 관리 > 스테이션 이용안내
    'GuideLocation.Subject': '제목',
    'GuideLocation.Subject.Placeholder': '제목 입력',
    'GuideLocation.UpdatedAt': '최종 수정일',
    'GuideLocation.OpenYn': '노출여부',
    'GuideLocation.CreatedBy': '작성자',
    'GuideLocation.CreateInfo': '앱 콘텐츠 관리 등록정보',
    'GuideLocation.FileInfo': '앱 콘텐츠 관리 파일정보',
    'GuideLocation.Contents': '내용',
    'GuideLocation.Contents.Placeholder': '내용 입력',
    'GuideLocation.Contents.creatorName': '작성자',
    'GuideLocation.Contents.Type': '콘텐츠 유형',
    'GuideLocation.Contents.Type.PlaceHolder': '콘텐츠 유형 선택',

    // 콘텐츠 관리 > 차종별 이용안내
    'ModelInfo.Grade': '차급',
    'ModelInfo.ModelName': '차종',
    'ModelInfo.ModelID': '모델 ID',
    'ModelInfo.OpenYn': '노출 여부',
    'ModelInfo.Contents': '내용',
    'ModelInfo.UpdatedAt': '최종 수정일',
    'ModelInfo.CreatorName': '작성자',
    'ModelInfo.Create.SubTitle.Info': '차종별 이용안내 등록정보',
    'ModelInfo.Create.SubTitle.File': '차종별 이용안내 파일정보',

    // 콘텐츠 관리 > 서비스 소개/배너/팝업
    'BannerMgmt.Scene': '배너영역(씬)',
    'BannerMgmt.Scene.Placeholder': '배너영역(씬) 선택',
    'BannerMgmt.Scene.All': '배너영역(씬) 전체',
    'BannerMgmt.Type': '카테고리',
    'BannerMgmt.Type.All': '카테고리 전체',
    'BannerMgmt.OrderNum': '표시 순서',
    'BannerMgmt.OrderNum.Placeholder': '표시 순서 입력',
    'BannerMgmt.UseYn': '사용여부',
    'BannerMgmt.Active.Period': '노출기간',
    'BannerMgmt.Title': '서비스 소개/배너/팝업 명',
    'BannerMgmt.Title.Placeholder': '서비스 소개/배너/팝업 명 입력',
    'BannerMgmt.Url': '이동 메뉴',
    'BannerMgmt.Url.Placeholder': '외부 링크 립력 (http://, https:// 포함)',
    'BannerMgmt.Image': '서비스 소개/배너/팝업 이미지',
    'BannerMgmt.Image.Placeholder': '선택된 파일 없음',
    'BannerMgmt.LinkYn': '링크 이동',
    'BannerMgmt.LinkY': '외부 링크',
    'BannerMgmt.LinkN': '사용 안함',
    'BannerMgmt.InAppLink': '내부 링크',
    // 'BannerMgmt.Banner.Drawer.Desc':
    //     '배너(드로워) 이미지 파일 조건 설명 (840 px * 222 px / 1M 이하 / png, jpg, jpeg, gif / 최대1장)',
    // 'BannerMgmt.Banner.Wiblezone.Desc':
    //     '배너(스테이션) 이미지 파일 조건 설명 (1005 px * 258 px / 1M 이하 / png, jpg, jpeg, gif / 최대1장)',
    // 'BannerMgmt.Banner.CouponBook.Desc':
    //     '배너(쿠폰북) 이미지 파일 조건 설명 (1005 px * 258 px / 1M 이하 / png, jpg, jpeg, gif / 최대1장)',
    'BannerMgmt.Banner.Drawer.Desc':
        '배너(드로워) 이미지 파일 조건 설명 (714 px * 222 px / 1M 이하 / png, jpg, jpeg, gif / 최대1장)',
    'BannerMgmt.Banner.Wiblezone.Desc':
        '배너(스테이션) 이미지 파일 조건 설명 (840 px * 222 px / 1M 이하 / png, jpg, jpeg, gif / 최대1장)',
    'BannerMgmt.Banner.CouponBook.Desc':
        '배너(쿠폰북) 이미지 파일 조건 설명 (840 px * 222 px / 1M 이하 / png, jpg, jpeg, gif / 최대1장)',
    'BannerMgmt.Splash.Desc':
        '서비스 소개 이미지 파일 조건 설명 (1236 px * 2334 px / 1M 이하 / png, jpg, jpeg, gif / 최대1장)',
    'BannerMgmt.PopAdmin.Desc':
        '팝업(어드민) 이미지 파일 조건 설명 (540 px * 540 px / 1M 이하 / png, jpg, jpeg, gif / 최대1장)',
    'BannerMgmt.PopApp.Desc':
        '팝업(앱) 이미지 파일 조건 설명 (960 px * 1260 px / 1M 이하 / png, jpg, jpeg, gif / 최대1장)',
    'BannerMgmt.Desc':
        '이미지 파일 조건 설명 (1M 이하 / png, jpg, jpeg, gif / 최대1장)',

    // 콘텐츠 관리 > 알림 템플릿
    'MsgTemplate.MessageName': '메시지 명',
    'MsgTemplate.MessageName.Placeholder': '메시지 명을 입력',
    'MsgTemplate.SendProgram': '전송 프로그램',
    'MsgTemplate.MessagePurpose': '메시지 목적',
    'MsgTemplate.MessagePurpose.Placeholder': '메시지 목적을 입력',
    'MsgTemplate.MessageTemplate': '메시지 템플릿',
    'MsgTemplate.TargetReceiver': '수신대상',
    'MsgTemplate.TargetReceiver.Placeholder': '수신대상을 입력',
    'MsgTemplate.UpdateHistory': '수정이력',
    'MsgTemplate.MessageTypeCd': '메시지 유형',
    'MsgTemplate.MessageTypeCd.All': '메시지 유형 전체',
    'MsgTemplate.MessageTypeCd.Select': '메시지 유형 선택',
    'MsgTemplate.MessageClassCd': '메시지 구분',
    'MsgTemplate.MessageClassCd.All': '메시지 구분 전체',
    'MsgTemplate.AdmsgYn': '광고 알림',
    'MsgTemplate.KakaoCode': 'LINE 코드',
    'MsgTemplate.KakaoCode.Placeholder': 'LINE 코드를 입력',
    'MsgTemplate.LinkButtonName': 'LINE 버튼명',
    'MsgTemplate.LinkButtonName.Placeholder': 'LINE 버튼명을 입력',
    'MsgTemplate.LinkUrl': 'LINE 링크 URL',
    'MsgTemplate.LinkUrl.Placeholder': '링크 URL을 입력',
    'MsgTemplate.SendPeriod': '전달시점',
    'MsgTemplate.SendPeriod.Placeholder': '전달시점을 입력',
    'MsgTemplate.MessageContents': '메시지 내용(예시)',
    'MsgTemplate.TemplateCode': '템플릿 코드',
    'MsgTemplate.TemplateCode.Placeholder': '템플릿 코드를 입력',
    'MsgTemplate.MessageLang': '사용언어',
    'MsgTemplate.MessageLang.All': '사용언어 전체',

    // 콘텐츠 관리 > 알림발송이력
    'HistoryMsgTemplate.Type.AllSelect': '메시지 구분 전체',
    'HistoryMsgTemplate.MessageType.AllSelect': '메시지 유형 전체',
    'HistoryMsgTemplate.MessageName.Placeholder': '메시지 명 입력',
    'HistoryMsgTemplate.MessageName': '메시지 명',
    'HistoryMsgTemplate.Type': '메시지 구분',
    'HistoryMsgTemplate.MessageType': '메시지 유형',
    'HistoryMsgTemplate.Status': '전송상태',
    'HistoryMsgTemplate.Purpose': '메시지 목적',
    'HistoryMsgTemplate.Content': '메시지 내용',
    'HistoryMsgTemplate.Content.Placeholder': '메시지 내용 입력',
    'HistoryMsgTemplate.RetryCount': '재전송 횟수',
    'HistoryMsgTemplate.User': '대여회원',
    'HistoryMsgTemplate.User.Placeholder': '대여회원 입력',

    // 콘텐츠 관리 > 수동메시지 관리
    'PassiveMessage.CreatorName': '담당자',
    'PassiveMessage.MsgTitle': '메시지 명',
    'PassiveMessage.MsgTitle.Placeholder': '메시지 명을 입력',
    'PassiveMessage.MsgTypeCd': '메시지 구분',
    'PassiveMessage.MsgTypeCd.All': '메시지 구분 전체',
    // 'PassiveMessage.SendDate': '발송일시',
    'PassiveMessage.SendDay': '발송일',
    'PassiveMessage.NoticeTypeCd': '알림유형',
    'PassiveMessage.NoticeTypeCd.All': '알림유형 전체',
    'PassiveMessage.MsgStatus': '발송상태',
    'PassiveMessage.MsgStatus.1': '예약',
    'PassiveMessage.MsgStatus.2': '취소',
    'PassiveMessage.MsgStatus.3': '완료',
    'PassiveMessage.MsgStatus.Cancel': '발송취소',
    'PassiveMessage.MsgStatus.All': '발송상태 전체',
    'PassiveMessage.ExcelUpload': '엑셀 업로드',
    // 'PassiveMessage.Search.KeywordType': '구분',
    // 'PassiveMessage.Search.TimeGbn': '시간 검색 항목',
    // 'PassiveMessage.Search.TypeGbn': '검색항목',
    'PassiveMessage.SampleDownBtn': '샘플파일다운로드',
    'PassiveMessage.MsgContents': '메시지 내용',
    'PassiveMessage.MsgContents.TailText1':
        '메시지 내의 가변인자는 엑셀에 정의된 #{col1} ~ #{col5} 까지 지정가능합니다.',
    'PassiveMessage.MsgContents.TailText2':
        '예) 안녕하세요 #{col1} 님 #{col2} 에서 안내문자 드립니다. 문의사항은 #{col3} 으로 연락해주시기 바랍니다.',
    'PassiveMessage.TargetReceiver': '수신대상',
    'PassiveMessage.INAPP.LINK': 'In App Link',
    'PassiveMessage.INAPP.LINK.Placeholder': 'In App Link 선택',
    'PassiveMessage.INAPP.LINK.PARAM': 'In App Link 파라미터',

    // 콘텐츠 관리 > 약관관리
    'Title.Terms.Agree': '약관동의',
    'Terms.UseYn': '사용여부',
    'Terms.TermsGroupID': '약관그룹명',
    'Terms.View.TermsGroupID': '그룹',
    'Terms.View.TermsGroupID.All': '그룹 전체',
    'Terms.View.TermsGroupID.Select': '그룹 선택',
    'Terms.TermsVersion': '약관 버전',
    'Terms.TermsName': '약관 명',
    'Terms.TermsName.Placeholder': '약관명 입력',
    'Terms.TermsAgreeYn': '동의필수여부',
    'Terms.View.TermsAgreeYn': '동의필수여부',
    'Terms.Search.TermsAgreeYn': '필수여부',
    'Terms.Search.TermsAgreeYn.All': '필수여부 전체',
    'Terms.TermsMainYn': '메인약관여부',
    'Terms.TermsUrl': '약관 URL',
    'Terms.Type': '유형',
    'Terms.View.Type': '약관 유형',
    'Terms.View.Type.All': '유형 전체',
    'Terms.View.Type.Select': '약관 유형 선택',
    'Terms.ProviderId': '차량공급사ID',
    'Terms.LanguageCd': '언어 코드',
    'Terms.CreateDate': '약관 생성일',
    'Terms.UseDate': '약관 적용기간',
    'Terms.View.UseDate': '적용 시작 시점',
    'Terms.View.TermsClassID': '약관 분류',
    'Terms.View.TermsClassID.Name': '약관 분류명',
    'Terms.View.TermsClassID.Placeholder': '약관 분류 입력',
    'Terms.View.TermsClassID.All': '약관 분류 전체',
    'Terms.View.TermsClassID.Select': '약관분류 선택',
    'Terms.TermsTemplate': '약관 내용',
    'Terms.TermsHistory': '변경 이력',
    'Terms.View.UpdaterName': '최종변경자',
    'Terms.View.UpdatedAt': '최종변경일시',
    'Terms.View.FileInfoTitle': '약관 파일정보',
    'Terms.View.TabTitle.File': '약관 파일 보기',
    'Terms.View.TabTitle.History': '약관 변경 이력',
    'Terms.Nationality': '가입자',
    'Terms.Nationality.All': '내/외국인 전체',

    // 운영 관리 > 버전
    'AppVersion.AppTypeCd': '앱분류',
    'AppVersion.AppTypeCd.Placeholder': '앱분류 선택',
    'AppVersion.AppPlatFormCd': 'OS',
    'AppVersion.AppPlatFormCd.Placeholder': 'OS 선택',
    'AppVersion.UseYn': '사용여부',
    'AppVersion.UseYn.Placeholder': '사용여부 선택',
    'AppVersion.AppVersion': '앱버젼',
    'AppVersion.BuildVersion': '빌드버젼',
    'AppVersion.Description': '앱버젼 설명',
    'AppVersion.AppLink': '업데이트 링크',
    'AppVersion.UpdateYn': '업데이트 여부',
    'AppVersion.UpdateYn.Placeholder': '업데이트 여부 선택',
    'AppVersion.CreatedBy': '최종수정자',
    'AppVersion.CreateAt': '최종수정날짜',
    'AppVersion.UpdateMessage': '업데이트 메시지',

    // 운영 관리 > 어드민
    'Operator.UserName.Placeholder': '이름 입력',
    'Operator.Email.Placeholder': '사용자ID 입력',
    'Operator.MobileNumber.Placeholder': '전화번호 입력',
    'Operator.RoleName.AllSelect': '역할 전체',
    'Operator.ActivationStatusCd.AllSelect': '상태 전체',
    'Operator.UserName': '이름',
    'Operator.MobileNumber': '전화번호',
    'Operator.Email': '사용자ID',
    'Operator.SystemType': '소속',
    'Operator.SystemType.All': '소속 전체',
    'Operator.SystemType.Wible': 'MOCEAN',
    'Operator.SystemType.Partner': '위탁사',
    'Operator.SystemType.Partner.Select': '위탁사 선택',
    // 'Operator.SystemType.PartnerName': '위탁사명',
    'Operator.Role': '역할',
    'Operator.RoleDetail': '역할시스템',
    'Operator.DenyReason': '반려사유',
    'Operator.DenyReason.Placeholder': '반려 사유를 입력하세요.',
    'Operator.RoleSelect.Placeholder': '역할 선택',
    'Operator.StatusSelect.Placeholder': '상태 선택',

    // 운영 관리 > 위탁사
    'BusinessPartner.ID': '위탁사ID',
    'BusinessPartner.Name': '위탁사 명',
    'BusinessPartner.BusinessRegNum': '사업자 번호',
    'BusinessPartner.Address': '주소',
    'BusinessPartner.DetailAddress': '상세주소',
    'BusinessPartner.DetailAddress.Placeholder': '상세주소 입력',
    'BusinessPartner.ManagerName': '대표자명',
    'BusinessPartner.ConsignmentScope': '위탁 범위',
    'BusinessPartner.Provider': '공급사',
    'BusinessPartner.ManagerMobileNumber': '전화번호',
    'BusinessPartner.ManagerEmail': '이메일',
    'BusinessPartner.BankName': '은행명',
    'BusinessPartner.AccountNumber': '계좌번호',
    'BusinessPartner.AccountHolder': '예금주',
    'BusinessPartner.AccountNumberFile': '통장사본',
    'BusinessPartner.BusinessRegNumFile': '사업자등록증',
    'BusinessPartner.BusinessRegNumFileName': '사업자등록증파일명',
    'BusinessPartner.createdAt': '생성일',
    'BusinessPartner.updatedAt': '수정일',
    'BusinessPartner.CreatedBy': '생성자',
    'BusinessPartner.TypeCd': '분류',
    'BusinessPartner.UseYn': '사용 여부',
    'BusinessPartner.UseYn.All': '사용여부 전체',
    'BusinessPartner.Description': '메모',
    'BusinessPartner.Name.Placeholder': '위탁사 명 입력',
    'BusinessPartner.ManagerName.Placeholder': '대표자명 입력',
    'BusinessPartner.ManagerMobileNumber.Placeholder': '전화번호 입력',
    'BusinessPartner.BusinessRegNum.Placeholder': '사업자 번호 입력',
    'BusinessPartner.BankName.Placeholder': '은행명 입력',
    'BusinessPartner.AccountHolder.Placeholder': '예금주 입력',
    'BusinessPartner.AccountNumber.Placeholder': '계좌번호 입력',
    'BusinessPartner.Description.Placeholder': '메모 입력',
    'BusinessPartner.Btn.Add.Provider': '공급사 추가',
    'BusinessPartner.Btn.Delete.Provider': '공급사 삭제',

    // 운영 관리 > Greener 정책
    'GreenerPolicy.GradeName': '등급 구분',
    'GreenerPolicy.MonthlyMileage': '월 평균 마일리지 (km 이상)',
    'GreenerPolicy.GoodsUseAt': '상품 이용 여부',
    'GreenerPolicy.DiscountPrice': 'km당 할인 금액(円)',

    // 고객센터 > 고객센터 공지
    'CSCenterNotice.NoticeMainYn': '노출여부',
    'CSCenterNotice.PopupNoticeMainYn': '팝업노출여부',
    'CSCenterNotice.PopupNoticeMainYn.All': '팝업노출여부 전체',
    'CSCenterNotice.NoticeTypeCd': '공지유형',
    'CSCenterNotice.NoticeTypeCd.Placeholder': '공지유형 선택',
    'CSCenterNotice.NoticeTypeCd.All': '공지유형 전체',
    'CSCenterNotice.CreatedAt': '생성일자',
    'CSCenterNotice.ManagerName': '담당자',
    'CSCenterNotice.Name': '이름',
    'CSCenterNotice.ManagerName.Placeholder': '담당자 입력',
    'CSCenterNotice.ManagerEmail': '이메일',
    'CSCenterNotice.ManagerEmail.Placeholder': '이메일 입력',
    'CSCenterNotice.ManagerPhoneNumber': '전화번호',
    'CSCenterNotice.ManagerPhoneNumber.Placeholder': '전화번호 입력',
    'CSCenterNotice.NoticePeriod': '게시기간',
    'CSCenterNotice.PromotionFg': '구분',
    'CSCenterNotice.PromotionFgEtcDtl': '기타내역',
    'CSCenterNotice.PromotionFgEtcDtl.Placeholder': '기타 입력',
    'CSCenterNotice.MarketingTypeEtc.Placeholder': '구분 입력',
    'CSCenterNotice.MarketingPurposeEtc.Placeholder': '목적 입력',
    'CSCenterNotice.PurposeFg': '목적',
    'CSCenterNotice.PurposeFgEtcDtl': '기타내역',
    'CSCenterNotice.PurposeFgEtcDtl.Placeholder': '기타 입력',
    'CSCenterNotice.MarketingName': '마케팅명',
    'CSCenterNotice.MarketingName.Placeholder': '마케팅명 입력',
    'CSCenterNotice.Synopsis': '개요',
    'CSCenterNotice.Synopsis.Placeholder': '개요 입력',
    'CSCenterNotice.Schedule': '일정',
    'CSCenterNotice.Schedule.Placeholder': '일정 입력',
    'CSCenterNotice.AttendCondition': '참여조건',
    'CSCenterNotice.AttendCondition.Placeholder': '참여조건 입력',
    'CSCenterNotice.Benefit': '혜택/지급방식',
    'CSCenterNotice.Benefit.Placeholder': '혜택/지급방식 입력',
    'CSCenterNotice.HonorarySchedule': '홍보/채널일정',
    'CSCenterNotice.DetailDtl': '상세',
    'CSCenterNotice.DetailDtl.Placeholder': '상세 입력',
    'CSCenterNotice.Contents': '공지내용',
    'CSCenterNotice.Contents.Placeholder': '공지내용 입력',
    'CSCenterNotice.Subject': '공지사항 제목',
    'CSCenterNotice.Subject.Placeholder': '공지사항 제목 입력',
    'CSCenterNotice.Empty.Type.Etc': '구분 항목의 기타사항 입력 바랍니다.',
    'CSCenterNotice.Empty.Purpose.Etc': '목적 항목의 기타사항 입력 바랍니다.',

    // 고객센터 > 1:1상담
    'VocMgmt.Title.History': '상담이력',
    'VocMgmt.Category': '상담분류',
    'VocMgmt.Info': '상담정보',
    'VocMgmt.Response': '상담처리',
    'VocMgmt.History': '처리이력',
    'VocMgmt.Category.All': '상담분류 전체',
    'VocMgmt.Category.Placeholder': '상담분류 선택',
    'VocMgmt.Result': '처리결과',
    'VocMgmt.Result.All': '처리결과 전체',
    'VocMgmt.Assignee.Name': '상담자명',
    'VocMgmt.UserID': '회원 ID',
    'VocMgmt.UserID.Placeholder': '회원 ID 입력',
    'VocMgmt.Comment': '문의내용',
    'VocMgmt.CommentTitle': '처리내용',
    'VocMgmt.CreateDate': '상담일시',
    'VocMgmt.UpdateDate': '처리일시',
    'VocMgmt.Coupon.Select': '쿠폰발급',
    'VocMgmt.Coupon.Select.Placeholder': '쿠폰선택',
    'VocMgmt.User.Name': '대여번호',
    //'Vehicle.LicensePlateNumber': '차량번호',
    'VocMgmt.Message.CouponComment':
        '* 발급된 쿠폰은 회수가 불가능하오니 유의하시기 바랍니다.',
    'VocMgmt.Message.NoVocHistory': '상담 이력이 없습니다.',
    'VocMgmt.RenterPhoneNumber.Placeholder': '연락처 입력',
    'VocMgmt.Option.Title': '대여(구독)번호',
    'VocMgmt.Option.Reservation': '대여번호 검색',
    'VocMgmt.Reservation.Placeholder': '대여번호 검색',
    'VocMgmt.Option.Subscribe': '구독번호 검색',
    'VocMgmt.RenterName.Prefix': '( 대여자 이름 : ',
    'VocMgmt.RenterName.Suffix': ' )',
    'VocMgmt.VocID': '상담번호',
    'VocMgmt.IssuedCoupon': '발급된 쿠폰',

    // VOC 처리결과 코드
    'VocMgmt.Stats': '어제 {yesterday}건 | 총 {total}건',
    'VocMgmt.Code.Status.Wait': '대기중',
    'VocMgmt.Code.Status.Work': '처리중',
    'VocMgmt.Code.Status.Hold': '보류',
    'VocMgmt.Code.Status.Etc': '기타',
    'VocMgmt.Code.Status.Complete': '완료',

    // 고객센터 > FAQ
    'FaqMgmt.Category': '카테고리',
    'FaqMgmt.Category.Main': '대분류 카테고리',
    'FaqMgmt.Category.Main.All': '대분류 카테고리 전체',
    'FaqMgmt.Category.Main.Select': '대분류 카테고리 선택',
    'FaqMgmt.Category.Sub': '소분류 카테고리',
    'FaqMgmt.Category.Sub.All': '소분류 카테고리 전체',
    'FaqMgmt.Category.Sub.Select': '소분류 카테고리 선택',
    'FaqMgmt.FaqTitle': '제목',
    'FaqMgmt.FaqTitle.Placeholder': '제목 입력',
    'FaqMgmt.UseYn': '노출 여부',
    'FaqMgmt.TextYn': '작성타입',
    'FaqMgmt.Writer': '작성자',
    'FaqMgmt.question': '질문',
    'FaqMgmt.Answer': '본문',
    'FaqMgmt.ViewCount': '조회수',
    'FaqMgmt.Text': 'TEXT',
    'FaqMgmt.Html': 'HTML',
    'FaqMgmt.Title.Placeholder': '제목 입력',
    'FaqMgmt.Create.Title.Placeholder': '제목(질문) 입력',
    'FaqMgmt.FaqCode': '랜딩 키',
    'FaqMgmt.FaqCode.Placeholder': '랜딩 키 입력',

    // 고객센터 > 담당자 문의
    'ContactManager.ChargerName.Placeholder': '담당자명 입력',
    'ContactManager.ChargeRole.Placeholder': '담당역할 입력',
    'ContactManager.ChargerName': '담당자명',
    'ContactManager.ChargeRole': '담담역할',
    'ContactManager.Email': '이메일',

    // 시스템 관리 > 권한
    'Role.RuleInfo': '역할정보',
    'Role.SystemType': '시스템',
    'Role.SystemType.Placeholder': '시스템 입력',
    'Role.RoleKey': '역할 키',
    'Role.RoleKey.Placeholder': '역할 키 입력',
    'Role.Name': '역할 명',
    'Role.Name.Placeholder': '역할 명 입력',
    'Role.Note': '설명',
    'Role.Note.Placeholder': '설명 입력',
    'Role.Privacy.Officer': '개인정보 취급',
    'Role.SubMenu.Name': '메뉴명',
    'Role.SubMenu.InquiryYn': '읽기',
    'Role.SubMenu.SaveYn': '쓰기',
    'Role.SubMenu.DelYn': '삭제',
    'Role.SubMenu.DownYn': '다운로드',
    'Role.LandingUrl': 'Landing Url',
    'Role.LandingUrl.Placeholder': 'Landing Url 입력',

    // 시스템 관리 > 코드
    'CodeManage.CodeType': '유형',
    'CodeManage.UseYn': '표시여부',
    'CodeManage.Name': '코드명',
    'CodeManage.Name.Placeholder': '코드명 입력',
    'CodeManage.MsgKey': '메시지키',
    'CodeManage.MsgKey.Placeholder': '코드 메시지 키 입력',
    'CodeManage.CodeValue': '코드값',
    'CodeManage.CodeValue.Placeholder': '코드값 입력',
    'CodeManage.CodeValue.Int': '코드값(숫자형)',
    'CodeManage.CodeValue.Int.Placeholder': '코드값(숫자형) 입력',
    'CodeManage.GroupKey': '그룹키',
    'CodeManage.GroupKey.Placeholder': '그룹키 값 입력',
    'CodeManage.OrderNum': '우선순위',
    'CodeManage.OrderNum.Placeholder': '우선순위 값 입력',
    'CodeManage.Description': '코드설명',
    'CodeManage.Description.Placeholder': '코드설명 입력',

    // 시스템 관리 > 메뉴
    'Title.MenuManage.View': '메뉴정보',
    'MenuManage.MsgKey': '메뉴키',
    'MenuManage.MsgKey.Placeholder': '메뉴키 입력',
    'MenuManage.Name': '메뉴명',
    'MenuManage.Name.Placeholder': '메뉴명 입력',
    'MenuManage.UrlType': '메뉴유형',
    'MenuManage.UrlType.Placeholder': '메뉴유형 선택',
    'MenuManage.SystemType': '시스템',
    'MenuManage.SystemType.Placeholder': '시스템 선택',
    'MenuManage.UseYn': '표시여부',
    'MenuManage.MenuUrl': '메뉴경로',
    'MenuManage.MenuUrl.Placeholder':
        '메뉴경로 입력(메뉴경로는 전체 중복 불가)',
    'MenuManage.OrderNum': '우선순위',
    'MenuManage.OrderNum.Placeholder': '우선순위 입력',
    'MenuManage.AclYn': '권한여부',
    'MenuManage.ProgramSearch': '프로그램 추가/삭제',
    'MenuManage.MenuType': '화면유형',
    'MenuManage.BusinessMsgKey': '회원사 메뉴키',
    'MenuManage.BusinessMsgKey.Placeholder': '회원사 메뉴키 입력',
    'MenuManage.BusinessMenuName': '회원사 메뉴명',
    'MenuManage.BusinessMenuName.Placeholder': '회원사 메뉴명 입력',
    'MenuManage.SearchTitle': '조회 대상',
    'MenuManage.SearchKeyword.Placeholder': '프로그램명 or API URL 입력',

    // 시스템 관리 > 프로그램
    'Title.Program.Create': '프로그램 등록',
    'Program.Name': '프로그램 명',
    'Program.ApiUrl': 'API URL',
    'Program.Action': '기능',
    'Program.Name.Placeholder': '프로그램 명 입력',
    'Program.URL.Placeholder': 'API URL 입력',
    'Program.SearchKeyword': '프로그램명 or API URL 입력',
    'Program.SearchKeyword.Placeholder': '프로그램명 or API URL 입력',
    'Program.Alert.Create.Action.No.Selected':
        '등록한 프로그램 기능을 선택하세요.',

    // 시스템 관리 > 배치
    'Batch.Date.Last.Operation': '최종작업수행일시',
    'Batch.Cron.Expr': '스케쥴링(Cron Expr)',
    'Batch.Job.Back.Off.Count': '재시도횟수',
    'Batch.Job.Back.Off.Time': '재시도 Interval(초)',
    'Batch.Job.Name': '배치명',
    'Batch.Job.Name.Placeholder': '배치명 입력',
    'Batch.Job.Desc': '배치작업상세',
    'Batch.Manual.Processing': '수동처리',
    'Batch.Scheduling': '스케쥴링',
    'Batch.Run.Alert.No.Checked': '수동처리할 작업을 선택하십시오.',
    'Batch.Run.Confirm': '수동 실행하시겠습니까?',
    'Batch.Run.Success': '수동 실행하였습니다.',
    'Batch.Run.Failure':
        '수동 실행을 하지 못하였습니다. 확인 후 다시 시도하여 주십시오.',
    'Batch.Search.Info.Help':
        '* 원활한 검색을 위해 검색 기간은 최대 6개월로 권장합니다.',

    'Batch.Categories': '구분',
    'Batch.Date.Occurrence': '실행시작일시',

    // 공통 or 결제쪽(임시)
    'Team.paymentMethod': '결제수단',
    'Team.License': '가입상품',

    // 공급사 등록/조회
    'Provider.Name': '공급사',
    'Provider.VehicleCnt': '차량대수',
    'Provider.BusinessRegNum': '사업자등록번호',
    'Provider.Contract.Period': '계약기간',
    'Provider.createdAt': '등록일',
    'Provider.UseYn': '사용 여부',
    'Provider.Name.Placeholder': '공급사명 입력',
    'Provider.BusinessRegNum.Placeholder': '사업자등록번호 입력',
    'Provider.Provider.Name': '공급사명',
    'Provider.Provider.Name.Placeholder': '공급사명 입력',
    'Provider.Registration': '사업자 등록증',
    'Provider.ManagerMobileNumber': '전화번호',
    'Provider.Address': '주소',
    'Provider.Address.Detail': '상세주소',
    'Provider.BankName': '은행',
    'Provider.BankName.Placeholder': '은행 선택',
    'Provider.AccountNumber': '계좌번호',
    'Provider.AccountNumber.Placeholder': '계좌번호 입력',
    'Provider.AccountHolder': '예금주',
    'Provider.AccountHolder.Placeholder': '예금주 입력',
    'Provider.BankBookCopy': '통장사본',
    'Provider.CalculateType': '공급사 정산 방식',
    'Provider.CalculateType.Placeholder': '정산 방식 입력',
    'Provider.Contract.PaperReg': '계약서 등록',
    'Provider.ManagerName': '대표자명',
    'Provider.ManagerEmail': '이메일',
    'Provider.Location': '대표 스테이션',
    'Provider.Location.Placeholder': '스테이션 전체',
    'Provider.AccountNumberFile': '계좌번호파일',
    'Provider.BusinessRegNumFile': '사업자등록증파일',
    'Provider.BusinessRegNumFileName': '사업자등록증파일명',
    'Provider.CarNo': '차량번호',
    'Provider.VIN': '차대번호',
    'Provider.Year': '차량연식',
    'Provider.VehicleModel': '차종',
    'Provider.FuelType': '연료유형',
    'Provider.Grade': '차급',
    'Provider.Mid': 'MID',
    'Provider.Brand': '제조사',
    'Provider.VehicleInfo.List': '공급사 차량정보 목록',
    'Provider.AddressDetail.Placeholder': '상세 주소 입력',
    'Provider.updatedAt': '수정일',
    'Provider.CreatedBy': '생성자',

    // 내 정보
    'MyInfo.User': '개인 정보',
    'MyInfo.Business': '비즈니스 프로필 정보',
    'MyInfo.Business.YN': '비즈니스 프로필 여부',
    'MyInfo.Business.Code': '비즈니스프로필 인증코드',
    'MyInfo.Business.Info': '회원사 정보',
    'MyInfo.Business.Department': '부서정보',
    'MyInfo.Business.Email': '회사 이메일',
    'MyInfo.TransferRole': '권한 이관',
    'MyInfo.TransferRole.BusinessManager': '슈퍼 관리자 권한 이관',
    'MyInfo.TransferRole.BusinessOperator': '부서 담당자 권한 이관',
    'MyInfo.Business.Delete': '비즈니스 프로필 삭제',
    'MyInfo.BusinessManager.Now': '현재 슈퍼 관리자',
    'MyInfo.BusinessManager.Change': '변경 슈퍼 관리자',
    'MyInfo.BusinessOperator.Now': '현재 부서 담당자',
    'MyInfo.BusinessOperator.Change': '변경 부서 담당자',
    'MyInfo.Transfer': '권한 부여',
    'MyInfo.Delete.Possible': '프로필 삭제 안내',
    'MyInfo.Delete.Impossible': '프로필 삭제 불가 안내',
    'MyInfo.Biz.Delete': 'Biz Solution 탈퇴',
    'MyInfo.Biz.Delete.Message': `MOCEAN FMS를 탈퇴합니다. 이대로 진행 하시겠습니까?`,
    'MyInfo.Business.Delete.Message': `삭제 시 {businessName}의 업무용 차량 이용이 불가능합니다.
                    계속해서 삭제를 진행하시겠습니까?`,
    'MyInfo.BusinessManager.Transfer.Message01': `<span>{businessName}</span>의 슈퍼관리자 권한을 기업내의 다른 임직원에게 이관합니다.`,
    // TODO 해당 문구 삭제요망
    'MyInfo.BusinessManager.Transfer.Message02': `권한을 넘겨 받을 임직원이 MOCEAN Car Sharing에 접속하여 '승인' 버튼을 누를경우, 슈퍼관리자 권한이 이관 적용됩니다.`,
    'MyInfo.BusinessOperator.Transfer.Message01': `<span>{departmentName}</span>의 부서담당자 권한을 부서내의 다른 부서원에게 이관합니다.`,
    // TODO 해당 문구 삭제요망
    'MyInfo.BusinessOperator.Transfer.Message02': `권한을 넘겨 받을 임직원이 MOCEAN Car Sharing에 접속하여 '저장' 버튼을 누를경우, 부서담당자 권한이 이관 적용됩니다.`,

    // 계약정보 조회
    'BusinessContract.ContractDownload': '계약서 조회',
    'BusinessContract.ContractDownload.Popup': '계약서',
    'BusinessContract.Contract.Name': '회원사명',
    'BusinessContract.Contract.SuperAdmin': '슈퍼 관리자',
    'BusinessContract.Contract.Type': '계약상품',
    'BusinessContract.Contract.Vehicle': '계약차량',
    'BusinessContract.Contract.WibleZone': '계약 스테이션',
    'BusinessContract.Contract.Period': '계약 기간',
    'BusinessContract.Contract.EndTime': '만료예정일',
    'BusinessContract.Contract.Extension': '연장신청',
    'BusinessContract.Vehicle.Name': '계약 차량 리스트',
    'BusinessContract.State': '계약상태',
    'BusinessContract.State.R': '등록',
    'BusinessContract.State.Y': '예정',
    'BusinessContract.State.E': '만료',
    'BusinessContract.Contract.Date': `계약일 :`,
    'BusinessContract.Contract.End.Remain1': `(만료일이 `,
    'BusinessContract.Contract.End.Remain2': `일 남았습니다.)`,
    'BusinessContract.SuperAdmin':
        '{userName}|{employeeRegNum}|{departmentName}|{companyEmail}',
    'BusinessContract.Default.Question': '계약 연장 상담 요청드립니다.',

    'Role.MOBILITY_MANAGER': 'MOCEAN',
    'Role.MOBILITY_MANAGER.Partner': '위탁업체',
    'Role.BUSINESS_MANAGER': '슈퍼관리자',
    'Role.BUSINESS_OPERATOR': '부서담당자',

    'Dashboard.Contract.Desc.1': '계약 만료',
    'Dashboard.Contract.Desc.2': '일전',
    'Dashboard.Contract.Desc.3': '입니다.',
    'Dashboard.Contract.Help':
        '· 계약 관련 궁금한 사항은 고객센터(1234-1234)로 문의해 주세요.',
    'Dashboard.Contract.ShortCut': '바로가기',
    'Dashboard.Contract.Product': '계약 상품',
    'Dashboard.Contract.Product.Not': '계약 정보 없음',
    'Dashboard.UsageDepartmentTop5.Title': '이용 부서 TOP5',
    'Dashboard.UseSum.Title': '이용 금액',
    'Dashboard.AccidentOccurring.Title': '사고 발생',
    'Dashboard.UseMemberTop5.Title': '이용 회원 TOP5',
    'Dashboard.RentalNumber.Count': '대여 건수',
    'Dashboard.Compare.Before.Month': '전월대비',
    'Dashboard.EcoReport.Title': '친환경 리포트',
    'Dashboard.EcoReport.Desc.1': '우리 회사와 함께 줄인 탄소는',
    'Dashboard.EcoReport.Desc.2': '약',
    'Dashboard.EcoReport.Desc.Ton': '톤',
    'Dashboard.EcoReport.Desc.3': '입니다.',
    'Dashboard.WeekUsing.Title': '요일별 이용 현황 (건)',
    'Dashboard.TimeUsing.Title': '시간별 이용 현황 (평균)',
    'Dashboard.Noti.Alram.Title':
        '우리 회사의 MOCEAN Car Sharing 이용 현황을 한 눈에 확인하세요! :)',
    'Dashboard.CreatedAt': '최신 업데이트 {value}',
    'Dashboard.EmptyMsg': '회원사를 선택해 주시기 바랍니다.',
    'Dashboard.TabMenu.Monthly': '월별',
    'Dashboard.TabMenu.Period': '특정기간',
    'Dashboard.Common.Accumulate': '누적',
    'Dashboard.Common.RentalsNumber': '대여 건수',
    'Dashboard.Common.RentalsTime': '대여 시간 (hour)',
    'Dashboard.Common.RealUseTime': '실 사용 시간 (hour)',
    'Dashboard.Common.RentalsNumberRate': '대여 건수 기준 가동율',
    'Dashboard.Common.RentalsTimeRate': '대여 시간 기준 가동율',
    'Dashboard.Common.ProductRentalsNumber': '상품별 대여 건수',
    'Dashboard.SubTitle.Member': '회원 현황',
    'Dashboard.SubTitle.Member.Join': '가입',
    'Dashboard.SubTitle.Member.RegularJoin': '정회원 가입',
    'Dashboard.SubTitle.Member.BusinessProfileCreate': '비즈니스 프로필 생성',
    'Dashboard.SubTitle.Member.Secession': '탈퇴',
    'Dashboard.SubTitle.AppInstall': '앱 설치',
    'Dashboard.SubTitle.AppInstall.AndroidInstall': 'AOS 설치',
    'Dashboard.SubTitle.AppInstall.IOSInstall': 'iOS 설치',
    'Dashboard.SubTitle.AppActivate': '앱 활성 사용자',
    'Dashboard.SubTitle.AppActivate.Statistics':
        'DAU: {DAU} | MAU: {MAU} | Stickiness: {Stickiness}%',
    'Dashboard.SubTitle.AppActivate.AOS': 'AOS',
    'Dashboard.SubTitle.AppActivate.IOS': 'iOS',
    'Dashboard.SubTitle.AppActivate.Chart.Header':
        '전체: {total} | AOS: {AOS} | IOS: {IOS}',
    'Dashboard.SubTitle.BusinessProfileUsage': '비즈니스 프로필 이용 현황',
    'Dashboard.SubTitle.PersonalProfileUsage': '개인 프로필 이용 현황',
    'Dashboard.SubTitle.CommonUsage': '공통 이용 현황',
    'Dashboard.SubTitle.CommonUsage.Title.Business': '업무용',
    'Dashboard.SubTitle.CommonUsage.Title.Personal': '개인용',
    'Dashboard.SubTitle.CommonUsage.Week.Title': '요일별 이용 현황 (건)',
    'Dashboard.SubTitle.CommonUsage.Week.Chart.Header': '{value}건',
    'Dashboard.SubTitle.CommonUsage.TimeUsing.Title': '시간별 이용 현황',
    'Dashboard.SubTitle.CommonUsage.CompanyTop5.Title':
        '회원사별 이용 순위 Best5',
    'Dashboard.SubTitle.CommonUsage.CompanyTop5.Business': '비즈니스',
    'Dashboard.SubTitle.CommonUsage.CompanyTop5.Personal': '개인',
    'Dashboard.SubTitle.CommonUsage.CompanyTop5.Profile': '프로필',
    'Dashboard.SubTitle.SalesUsage': '매출 이용 현황',
    'Dashboard.SubTitle.SalesUsage.RentalsFee': '대여 요금 (万円)',
    'Dashboard.SubTitle.SalesUsage.DrivingFee': '주행 요금 (万円)',
    'Dashboard.SubTitle.SalesUsage.OtherSales': '기타 매출',
    'Dashboard.SubTitle.CustomerService': '고객 응대 현황',
    'Dashboard.SubTitle.CustomerService.VocReceiptNumber': '1:1 상담 접수 건수',
    'Dashboard.SubTitle.CustomerService.VehicleRepairNumber': '사고 접수 건수',

    // ----------------------- Phase 2 ---------------------------------------------

    'CustomerUser.NormalUser': '일반 회원',
    'CustomerUser.WithdrawUser': '탈퇴 회원',
    'CustomerUser.WithdrawUser.Detail': '탈퇴 회원 상세',
    'CustomerUser.UseYN': '이용정지 여부',
    'CustomerUser.UseYN.All': '이용정지 여부 전체',
    'CustomerUser.DriverCertificate': '운전경력증명서',
    'CustomerUser.DriverCertificate.FileName': '운전경력증명서-{name}',
    'CustomerUser.WithdrawDate': '탈퇴일',
    'CustomerUser.WithdrawReason': '탈퇴사유',
    'CustomerUser.Path': '가입 경로',
    'CustomerUser.Alim': '서비스 이용 정보 알림',
    'CustomerUser.RepairCnt': '사고이력',
    'CustomerUser.DeleteDriverLicense.Confirm.Message':
        '면허정보를 삭제하시겠습니까?',
    'CustomerUser.DeleteDriverLicense.Success.Message':
        '면허정보가 삭제되었습니다. 재등록까지 차량 대여 및 이용을 할 수 없습니다.',
    'CustomerUser.DeleteDriverLicense.Failure.Message':
        '면허정보를 삭제 할 수 없습니다.',
    'CustomerUser.DriverDeletedDate.Message.1': `면허정보 삭제 일시: {driverDeletedDate}`,
    'CustomerUser.DriverDeletedDate.Message.2': `<span>면허정보 재등록이 필요합니다.</span>`,

    'Btn.VehicleRepair.Accident.Status.Update.2': '처리 중',
    'Btn.VehicleRepair.Accident.Status.Update.4': '청구하기',
    'Btn.VehicleRepair.Accident.Status.Update.8': '처리 완료',

    // 모바일 화면용
    'MobilePage.Search.Title': '검색',

    //과태료 추가
    'Title.SalesMgmt.Violation.Detail': '과태료 상세',
    'Title.Violation.Transfer.Dialog': '이관신청서 전송',
    'Violation.Transfer.SubTitle.Trans': '전송 설정',
    'Violation.Transfer.SubTitle.Docu': '첨부 서류',
    'Violation.Transfer.Type': '전송 방식',
    'Violation.Transfer.TransTarget': '수신자 정보',
    'Violation.Transfer.Email': 'E-Mail',
    'Violation.Transfer.Fax': 'Fax',
    'Violation.Transfer.Confirm': '이관 신청서가 전송 되었습니다.',

    //매출
    'Sales.CalculateStatus': '정산구분',
    'Sales.CalculateStatus.Placeholder': '정산구분 전체',
    'Sales.ModelName.Placeholder': '차종 입력',
    'Sales.SubTitle.Sales': '매출 정보',
    'Sales.SubTitle.Calculate': '정산 정보',
    'Sales.SubTitle.User': '회원 정보',
    'Sales.SubTitle.Subscribe': '구독 정보',
    'Sales.SubTitle.Reservation': '대여 정보',
    'Sales.SubTitle.BusinessContract': '회원사계약 정보',
    'Sales.SubTitle.SalesDetail': '매출상세',
    'Sales.BusinessEmail': '회사 이메일',
    'Sales.BusinessName.Title': '회원사명',

    'Sales.TollFee.Title': '통행 요금',
    'Sales.delayFee.Title': '지연 요금',

    'Sales.EtcFee': '기타',
    'Sales.Memo': '메모',
    'Sales.MemberShip.Charge': '맴버십 요금',

    'Sales.CalculateStatus.Payment': '정산완료',
    'Sales.CalculateStatus.Cancel': '결제취소',
    'Sales.CalculateStatus.Claim': '청구완료',
    'Sales.CalculateStatus.ExactCalculation': '별도정산',
    'Sales.CalculateStatus.Refund': '정산완료(재결제)',

    'Business.Delay.IsTarget': '지연요금 부과',
    'Business.IsTarget': '부과여부',
    'Business.Target': '부과',
    'Business.NoTarget': '부과 안함',
    'Business.CliamMethod.ExactCalculation': '별도 정산',
    'Business.CliamMethod.ManualCalculation': '수기 정산',
    'Business.CliamMethod.CaseByCase': '건별 정산',
    'Business.CliamMethod.CardAuto': '월별 정산(카드 자동청구)',
    'Business.IsAllowCardRegist': '임직원 카드 직접 등록',
    'Business.Mid.Placeholder': '회원사 MID 선택',
    'Business.Mid': 'MID',
    'Business.remainTime': '이번달 잔여 약정시간',
    'Business.remainTimeInfo': '{hours}시간 {minutes}분 / {engagementTime}시간',

    // 차량관리 > 유지관리/정비이력
    'Location.WibleZone.CountVehicle': '스테이션 내 차량 : {count}',

    'Common.YN.Allow': '허용',
    'Common.YN.NotAllow': '허용 안함',

    'Code.Maintenance.MaintenanceItemCd.IN': '내부세차',
    'Code.Maintenance.MaintenanceItemCd.OUT': '외부세차',
    'Code.Maintenance.MaintenanceItemCd.CHARGING': '충전',
    'Code.Maintenance.MaintenanceItemCd.OIL': '주유',
    'Code.Maintenance.MaintenanceItemCd.CHECK': '소모품 점검',
    'Code.Maintenance.MaintenanceItemCd.EXCHANGE': '소모품 교환',
    'Code.Maintenance.MaintenanceItemCd.VEHICLE': '차량이동',
    'Code.Maintenance.MaintenanceItemCd.TC': '기타',

    // 구독내역
    'Subscribe.InsuranceFee': '보험료(자기부담금)',
    'Subscribe.SubscribeRound': '회차',
    'Subscribe.SubscribeRound.Period': '회차 기간',
    'Subscribe.SubscribeMonth.All': '회차 전체',
    'Subscribe.Additional.Info': '연장 상품 정보',
    'Subscribe.Additional.Payment.Fee': '연장 상품 결제 금액',
    'Subscribe.Additional.Payment.Status': '연장 상품 결제 상태',
    'Subscribe.Cancel.Info': '구독 취소 정보',
    'Subscribe.Cancel.Round': '취소 적용 회차',
    'Subscribe.CancelDate': '구독 취소 일자',
    'Subscribe.Cancel.Period': '{start}회차 부터 ~ {end}회차 까지',
    'Subscribe.Discount.Return.Charge.List': '할인 반환금 조회',
    'Subscribe.Discount.Return.Charge.Info': '할인 반환금 안내',
    'Subscribe.Discount.Return.Charge.Total': '총 할인 반환금: {value}円',
    'Subscribe.Discount.Return.Charge.Total.Green':
        '총 할인 반환금: <span>{value}</span>円',
    'Subscribe.Discount.Return.Charge.Null': '발생한 할인 반환금이 없습니다.',
    'Subscribe.DiscountRefundPay.comment':
        '회차 별 할인 반환금은 다음과 같습니다.',
    'Subscribe.InsuranceFee.Value': '{insuranceFee円 (자기부담금 {selfCost}円)',

    // 이용내역서
    'RD.Usagebill.CARD_AUTO': '{authDate} 월별정산-카드 자동청구', // 월별정산-카드 자동청구
    'RD.Usagebill.EXACT_CALCULATION': '{authDate} 별도정산', // 별도정산
    'RD.Usagebill.CASE_BY_CARD': '{authDate} 결제완료 ({cardName})', // 1. 건별정산 - 청구상태 X, 결제상태만 있음
    'RD.Usagebill.CASE_BY_CARD_NO_CARD': '{authDate} 결제완료', // 2. 건별정산 - 청구상태 X, 결제상태만 있음(cardName X)
    'RD.Usagebill.MANUAL_CALCULATION': '{authDate} 수기정산', // 수기정산 없음
    'RD.Usagebill.NON_PAYMENT': '{authDate} 미납 ({cardName})', // 1. 미납
    'RD.Usagebill.NON_PAYMENT_NO_CARD': '{authDate} 미납', // 2. 미납(cardName X)
    'RD.Usagebill.CANCELED': '{authDate} 취소 ({cardName})', // 1. 취소
    'RD.Usagebill.CANCELED_NO_CARD': '{authDate} 취소', // 2. 취소(cardName X)
    'RD.Usagebill.CANCELED.COMMISSION': '{authDate} 취소 수수료 ({cardName})', // 1. 취소 수수료
    'RD.Usagebill.CANCELED.COMMISSION_NO_CARD': '{authDate} 취소 수수료', // 2. 취소 수수료(cardName X)

    'Maintenance.ManageType.AllSelect': '관리대상 전체',

    //페널티
    'Penalty.Status': '페널티 상태',
    'Penalty.StatusCode.Regist': '등록완료',
    'Penalty.StatusCode.Claim': '청구완료',
    'Penalty.StatusCode.Complete': '처리완료',
    'Penalty.StatusCode.Default': '미납',
    'Penalty.StatusCode.Exemption': '면제처리',
    'Penalty.StatusCode.Select.All': '페널티 상태 전체',
    'Penalty.Payment.Target': '페널티 요금 부과 대상',
    'Penalty.Payment.Target.Placeholder': '페널티 요금 부과 대상 선택',
    'Penalty.Payment.CardInfo': '청구/결제 정보',
    'Penalty.User.Target.Business': '회원사',
    'Penalty.User.Target.Personal': '개인',
    'Penalty.Reservation.Placeholder': '대여번호 검색',
    'Penalty.Btn.ClearPayment': '페널티 요금 면제',
    'Penalty.Btn.ClearScore': '페널티 점수 면제',
    'Penalty.Btn.Clear': '페널티 전체 면제',
    'Penalty.NoPaymentInfo': '결제정보가 없습니다.',
    'Penalty.ClearPenaltyFee.Complete.1':
        '페널티 요금 면제 완료 (기존 페널티 요금 : ',
    'Penalty.ClearPenaltyFee.Complete.2': '(円)',
    'Penalty.ClearPenaltyPoint.Complete':
        '페널티 점수 면제 완료 (기존 페널티 점수 : {prevPenaltyPoint}점)',
    'Penalty.PaymentResultCode': '페널티 결제결과',
    'Penalty.PaymentResultCode.0000': '결제완료 :',
    'Penalty.PaymentResultCode.0000f': '정산완료 :',
    'Penalty.PaymentResultCode.2002': '결제취소 :',
    'Penalty.PaymentResultCode.9999': '결제실패 :',
    'Penalty.PaymentResultCode.3003': '카드미납',
    'Penalty.Representation.Yes': '(회사 대표카드)',

    'Penalty.ClaimDialog.Message':
        '페널티를 청구상태로 변경합니다.{br}청구된 금액은 고객에게 직접 청구되거나 회원사의 페널티 계약 설정에 따라 자동 결제, 별도 정산이 필요합니다.{br}{br}이대로 진행 하시겠습니까?',
    'Penalty.Dialog.Contents.Fee': '페널티 부과 요금 :',
    'Penalty.Dialog.Contents.Score': '페널티 점수 :',
    'Penalty.PaymentDialog.Message':
        '회원사에 부과된 페널티 요금을 결제합니다.{br}이대로 진행 하시겠습니까?{br}',

    'Penalty.ClearPenaltyDialog.Message':
        '페널티 면제 처리합니다.{br}면제할 경우 페널티 점수 및 페널티 요금은 부과대상에게 부과되지 않습니다.{br}이대로 진행 하시겠습니까?',
    'Penalty.ClearPenaltyFee.Message.Confirm':
        '페널티 요금을 차감합니다. 이대로 진행 하시겠습니까?',
    'Penalty.ClearPenaltyFee.Message.Failure':
        '페널티 요금 면제를 할 수 없습니다.',
    'Penalty.ClearPenaltyPoint.Message.Confirm':
        '페널티 점수를 차감합니다. 이대로 진행 하시겠습니까?',
    'Penalty.ClearPenaltyPoint.Message.Failure':
        '페널티 점수 면제를 할 수 없습니다.',
    'Penalty.Clear.Message.Failure': '페널티 면제를 할 수 없습니다.',
    'Penalty.Claim.Message.Failure': '페널티 청구를 할 수 없습니다.',
    'Penalty.Payment.Message.Failure': '페널티 요금 결제를 할 수 없습니다.',
    'Penalty.Message.Success': '정상 처리되었습니다.',
    'Penalty.EndPaymentDuoDate': '납부기한 종료',
    'Penalty.ExemptionDate': '(면제처리 : {exemptionDate})',

    'Code.MessageTemplate.MessageClassCd.4': 'MMS',
    'Common.Image.Title': '이미지',

    // 매출/정산 관리 > 미수금
    'Receivable.Stats': '총 {totalSum}円',
    'Receivable.RentalNo.Placeholder': '대여번호 입력',
    'Receivable.SubscriptionNo.Placeholder': '구독번호 입력',
    'Receivable.ReservationType': '유형',
    'Btn.Send.Alim': 'e-mail 발송',
    'Btn.Receivable.Complete': '납부 완료',
    'Receivable.Date.Occurrence': '미수금 발생 일시',
    'Receivable.Charge.Target': '미수금 부과 대상',
    'Receivable.Payment.Error': '결제 오류 코드',
    'Receivable.SubTitle.UserInfo': '회원 정보',
    'Receivable.DepartmentName': '부서명',
    'Receivable.BusinessEmail': '회사 이메일',
    'Receivable.WibleZone': '스테이션',
    'Receivable.Main.ProductName': '주 상품명',
    'Receivable.Extend.ProductName': '연장 상품명',
    'Receivable.SubTitle.UnpaidDetail': '미수금 상세',
    'Receivable.Dialog.SubTitle.Complate': '미수금 납부하기',
    'Receivable.Dialog.Desc.1': '결제방식을 설정하신 후 미수금을 납부하세요.',
    'Receivable.Dialog.ReceivableAmt': '미수금액',
    'Receivable.Dialog.ReceivableType': '결제 방식',
    'Receivable.Dialog.ReceivableCard': '결제 카드',
    270003: '버전 업데이트 시, 적용 시작 시점은 미래 시점으로 설정해야 합니다.',
    'PassiveMessage.Image.Upload.Desc':
        '이미지 파일 조건 설명 (1000 px * 1000 px 이하 / jpeg)',
    'Terms.View.UseDate.Endless': '현재까지 적용',

    // 고객청구서
    'RD.Customerbill.claimDueDate': '{year}년 {month}월 {date}일',

    'PassiveMessage.Image.Upload.Desc1':
        '권장: 176*144pixel, 20KB 미만의 jpeg 파일',
    'PassiveMessage.Image.Upload.Desc2':
        '최대: 1000*1000pixel, 1MB 미만의 jpeg 파일',
    'PassiveMessage.Image.Upload.Desc3':
        '휴대전화의 단말에 따라 전송 실패 될 수 있습니다.',
    'Common.User.Personal': '개인',
    'Receivable.SubTitle.BusinessInfo': '회원사/계약 정보',
    'Receivable.BusinessContract.BusinessName': '회원사명',
    'Receivable.BusinessContract.Condition': '업태/업종',
    'Receivable.BusinessContract.BusinessRegNum': '사업자 등록 번호',
    'Receivable.BusinessContract.MobileNumber': '전화번호',
    'Receivable.BusinessContract.Address': '주소',
    'Receivable.BusinessContract.LicenseTypeCd': '계약상품',
    'Receivable.BusinessContract.ContractTime': '계약 기간',
    'Receivable.Subscribe.SubTitle': '구독 정보',
    'Receivable.Subscribe.CaseBy': '건별 청구',
    'Receivable.Subscribe.Monthly': '월별 청구',
    'Receivable.Subscribe.Btn.Detail': '구독 상세',
    'Sales.RentalDelayTime.Won': '지연시간(円/분)',
    'Sales.AccidentFree': '사고처리 요금',
    'Sales.ExtendFree': '연장 요금',
    'Sales.CancelFree': '취소 요금',
    'Sales.ChargeFree': '대여 요금',
    'Sales.EtcFree': '기타 요금',
    'Sales.Stats': '총 {total}円',
    'Sales.MonthlyDetail.Subtitle': '대여 정보 및 매출 상세',
    'Sales.MonthlyDetail.Sum': '· 총 {feeType}: {sumDrivingFree}',
    'Receivable.MonthlyDetail.Subtitle': '대여 정보 및 미수금 상세',
    'Receivable.MonthlyDetail.DrivingFee.Sum':
        '· 총 {sumDrivingName}: {sumDrivingFree} {sumAddgoodsName}',

    'Receivable.CaseBy.ReservationTime': '개별 계약기간',
    'Receivable.Reservation.Btn.Detail': '예약 상세',
    'Receivable.VehicleRepair.Btn.Detail': '사고수리이력 조회',
    'Receivable.Penalty.Btn.Detail': '페널티 조회',
    'Btn.Transform.Mobile': '모바일로 보기',
    'Calculation.PaymentMethod.FMS': 'FMS 결제',
    'Message.Receivable.Confirm.Complate': '미수금을 납부 하시겠습니까?',
    'Message.Receivable.Success.Complate': '정상 처리 되었습니다.',
    'Message.Receivable.Empty.fileData': '증빙자료를 첨부해주시기 바랍니다.',
    'Message.Receivable.Empty.Card': '결제 카드를 선택 해주시기 바랍니다.',
    'Receivable.Success.Title': '결제 등록 완료',
    'Receivable.Success': '결제 등록이 완료되었습니다.',
    //사고수리 phase2
    'VehicleRepair.CashYn': '결제방식',
    'VehicleRepair.ClaimType': '청구방식',
    'VehicleRepair.ClaimType.App': 'App으로 청구',
    'VehicleRepair.ClaimType.Etc': '별도 청구',
    'VehicleRepair.AvailableYn': '대여 가능여부',
    'VehicleRepair.AvailableYn.Placeholder': '대여 가능여부 전체',
    'VehicleRepair.AvailableYn.Y': '대여 가능',
    'VehicleRepair.AvailableYn.N': '대여 불가능',
    'VehicleRepair.IncidentDriver': '사고운전자정보',
    'VehicleRepair.IncidentDriverEmail': '사고운전자ID',
    'VehicleRepair.incidentDriverName': '사고운전자명',
    'VehicleRepair.IncidentDriverEmail.Placeholder': '사고운전자ID 입력',
    'VehicleRepair.incidentDriverName.Placeholder': '사고운전자명 입력',
    'VehicleRepair.IncidentDriverInfo':
        '{incidentDriverName} ({incidentDriverEmail})',
    'VehicleRepair.ReservationKey': '대여번호',
    'VehicleRepair.ReservationKey.Placeholder': '대여번호 입력',
    'VehicleRepair.BusinessName': '회원사',
    'VehicleRepair.DepartmentName': '부서명',
    'VehicleRepair.IncidentJoinPathCd': '접수경로',
    'VehicleRepair.IncidentJoinPathDate': '접수일시',
    'VehicleRepair.IncidentJoinPathCd.App': 'App',
    'VehicleRepair.InsuranceName': '보험사',
    'VehicleRepair.RepairDay.Prefix': '휴차일 수 선택 ',
    'VehicleRepair.RepairDay.Placeholder': '숫자 입력',
    'VehicleRepair.ClaimInfo': '청구정보',
    'VehicleRepair.ClaimTarget': '청구대상',
    'VehicleRepair.ClaimPaymentType': '결제방식',
    'VehicleRepair.ClaimPaymentFile': '결제 증빙자료 첨부',
    'VehicleRepair.PaymentCash': '결제 증빙자료 첨부',
    'VehicleRepair.ClaimMsg':
        "*청구 대상 고객에게 '고객 청구서' 메일 발송이 필요합니다.",
    'VehicleRepair.ConfirmMsg': '상태를 변경하시겠습니까?',
    'VehicleRepair.ClaimDueDate': '청구일자',
    'VehicleRepair.PaymentDueDate': '납부기한',
    'VehicleRepair.PaymentDate': '결제일시',
    'VehicleRepair.PaymentConfirmDate': '이체 내역 확인 일시',
    'VehicleRepair.NoPaymentStatus': '(납부 기한 경과 : 미납처리)',
    'VehicleRepair.EmailClaim.Title': '청구 이메일 발송',
    'VehicleRepair.EmailClaim.Btn': '이메일 발송',
    'VehicleRepair.EmailClaim.Contents':
        '고객 청구서를 이메일로 발송합니다.{br}고객의 이메일을 입력하고 첨부 서류 업로드 후 발송하기 버튼을 선택해 주세요.{br}',
    'VehicleRepair.EmailClaim.SubTitle': '첨부 서류',
    'VehicleRepair.EmailClaim.UploaderTitle1': '점검정비 명세서',
    'VehicleRepair.EmailClaim.UploaderTitle2': '고객 청구서',
    'VehicleRepair.EmailClaim.PopupMsg':
        '고객 청구서 수신자 정보를 확인하세요.',
    'VehicleRepair.EmailClaim.PopupComfirm': '이관신청서가 전송 되었습니다.',
    'VehicleRepair.EmailClaim.SendBtn': '발송하기',
    'VehicleRepair.EmailClaim.DriverInfo': '운전자 정보',
    'VehicleRepair.EmailSend.Limit':
        '※ 첨부파일의 총 용량은 9MB까지 전송 가능합니다.',
    'VehicleRepair.SelfFee.Max.Message':
        '자기부담금(최대) 이하의 값만 입력 가능합니다.',
    'VehicleRepair.Processing.Alert':
        '[접수완료] > [처리중] 으로 변경하시겠습니까?',
    'VehicleRepair.Claim_Complete.Alert':
        '[처리중] > [청구] 으로 변경하시겠습니까?',
    'VehicleRepair.Claim_Complete.Alert.Customerbill':
        '[처리중] > [청구] 으로 변경하시겠습니까?{br}(변경 후 고객청구서를 업로드 해야합니다.)',
    'VehicleRepair.Payment.Alert': '[청구] > [처리완료] 으로 변경하시겠습니까?',
    'VehicleRepair.Payment.Alert2':
        '[미납] > [처리완료] 으로 변경하시겠습니까?',
    'VehicleRepair.Customerbill.Confirm': '고객 청구서를 전송 하시겠습니까?',
    'VehicleRepair.Customerbill.Complete': '고객 청구서가 전송 되었습니다.',
    'VehicleRepair.SelfFee.Select': '자기부담금 선택',
    'VehicleRepair.SelfFee.Percent.Message':
        '※ 자기부담금 비율이 없는 경우 0으로 입력하세요.',
    'VehicleRepair.Customerbill.Message': '고객청구서를 첨부 해야합니다.',
    'VehicleRepair.Processing.Alert.Success': '처리중으로 변경되었습니다.',
    'VehicleRepair.Claim_Complete.Alert.Success': '청구완료되었습니다.',
    'VehicleRepair.Claim_Complete.Alert.Success.Customerbill':
        '청구완료되었습니다. 고객청구서를 업로드해주세요.',
    'VehicleRepair.Payment.Alert.Success': '처리완료되었습니다.',
    'VehicleRepair.ReservationType': '유형',
    'VehicleRepair.ReservationType.All': '프로필 유형 전체',
    'VehicleRepair.EmployeeRegNum': '사고운전자 사번',
    'VehicleRepair.EmployeeRegNum.Placeholder': '사고운전자 사번 입력',

    'Common.Right.Bracket': ')',
    'Common.Left.Bracket': '(',
    'Dashboard.SubTitle.SysWeekUsing.Chart.Header':
        '전체: {total} | 업무용: {business} | 개인용: {personal}',
    'Common.Stats.Unit.Total': '전체: ',
    'Common.Stats.Unit.Business': '업무용: ',
    'Common.Stats.Unit.Personal': '개인용: ',
    'Common.CardName': '({cardBrand}){cardNumber}',
    'Dashboard.SubTitle.AppActivate.Desc': '*App OS에서 제공하는 정보로 구성',
    'Dashboard.SubTitle.AppActivate.Chart.Header.DAU': 'DAU: ',
    'Dashboard.SubTitle.AppActivate.Chart.Header.MAU': 'MAU: ',
    'Dashboard.SubTitle.AppActivate.Chart.Header.Stickiness': 'Stickiness: ',
    'Receivables.UnpaidStatus.COMPLETE': '납부 완료',
    'Receivables.UnpaidStatus.UN_CALCULATE': '미납',
    'Receivables.UnpaidStatus.ALL': '상태 전체',
    'Receivable.Payment.PaymentType': '미수금 결제 방식',
    'Receivable.Payment.CompleteTime': '미수금 납부 완료 일시',

    490002: '청구정보를 입력해 주세요.',
    490003: '해당 페이지에서는 별도정산인 경우에만 처리완료가 가능합니다.',
    490004: '이체 내역이 등록되지 않아 수기로 처리 완료할 수 없습니다.',
    490005: '이전 상태가 일치하지 않습니다.',
    'VehicleModel.Image.File.extension3': 'jpeg 파일 등록',
    'Receivable.receivable.Btn.Detail': '회원사 상세',
    'Common.Login.Pin.Number.Title': 'PIN 입력',
    'Common.Login.Pin.Number.Desc':
        '기아통합계정 가입시 설정하신 PIN 4자리를 입력하세요.',
    'Common.Login.Pin.Number.Desc2':
        '5회 이상 인증에 실패할 경우 시작 화면으로 돌아가서 10분 후 인증 재시도가 가능합니다.',
    'Common.Login.Pin.Number.Placeholder': '4자리 입력',
    510002: 'PIN이 올바르지 않습니다.{br}5회 오류 시 10분 동안 인증이 불가능 합니다. (현재 {failCnt}회)',
    'Common.Login.Pin.Reset.Retry.FailOver':
        'PIN 인증을 5회 실패하여 10분간 인증이 불가능합니다.{br}시작 화면으로 돌아갑니다.',
    'Common.Login.Pin.Reset.Btn': 'PIN 초기화',
    'Common.Login.Pin.Reset.Retry.Msg':
        'PIN을 초기화 하였습니다. 다시 로그인하여 PIN을 설정해 주시기 바랍니다.',
    'Common.Login.Pin.Reset.Retry.Comfirm': 'PIN을 초기화 하시겠습니까?',
    'Common.Login.Pin.Reset.Complate':
        'PIN을 초기화 했습니다.{br}다시 로그인 하여 PIN을 재설정 하시기 바랍니다.',
    'Message.Validator.OverByte': '입력 가능한 글자수를 초과 하였습니다.',
    'PassiveMessage.Unsubscribe': '수신거부 안내',
    690002: '발송 가능한 글자수를 초과하였습니다.',
    'Coupon.Detail.discountEndHour.Empty.Error':
        '시작시간을 선택해 주시기 바랍니다.',
    'Maintenance.WorkingDate.Between.ErrorMessage':
        '시작시간으로부터 최대 48시간까지 선택 가능합니다.',
    'Maintenance.BusinessName.Name': '위탁업체명',
    'Maintenance.MaintenanceTypeCd': '관리 항목',
    'VehicleRepair.Repair.Refer': '(사전 견적)',
    'VehicleRepair.Repair.RealRepair': '(최종 수리 비용)',
    'Input.File.Max.Size': '파일은 최대 10M까지 업로드 가능합니다.',
    'Code.CustomerUser.PenaltyYn.Y': '이용정지',
    'Code.CustomerUser.PenaltyYn.N': '이용정지 해제',
    'VehicleModel.HydrogenEfficiency': '연비(km/kg)',
    'Coupon.Unlimited': '무제한',

    // ----------------------- JP ---------------------------------------------
    'Common.EmployeeRegNum.Input': '사번 입력',
    'Common.Btn.UnSelect': '선택안함',
    'Reservation.Stats.Total': '총 {totalRecord}건',
    'Extra.Name.Placeholder.All': '부가상품명 전체',
    'Reservation.Addgoods.NoSearch': '부가상품 없음',
    'Extra.Placeholder.ID.All': '부가상품 전체',

    // 사고수리
    'VehicleRepair.Join.YN': 'NOC보상 가입여부',
    'VehicleRepair.Join.YN.Y': '가입',
    'VehicleRepair.Join.YN.N': '미 가입',
    'VehicleRepair.Delete.Alert': '[삭제] 상태로 변경하시겠습니까?',
    'VehicleRepair.Delete.Alert.Success': '삭제 처리되었습니다.',

    // 차량등록/조회
    // 'Vehicle.ServiceTime.Pickup.Day': '픽업 가능 요일',

    // 요금제
    'Charge.Camping.GuideInfo': '캠핑 용품 대여 안내',
    'Charge.GuideInfo.Language': '이용안내({value})',
    'Charge.EndTime.Alert': '종료시간을 입력해주세요',
    'Charge.EndTime.Space.Alert': '종료시간은 최대 180분 입니다.',
    'Charge.Min.Select.Placeholder': '분 선택',

    // 스테이션 관리
    'Location.Guide.Detail': '{value} 상세보기',

    // 콘텐츠 관리 > 스테이션 이용안내
    'GuideLocation.Language': '언어',

    // 부가상품
    'Title.ProductMgmt.Extra.GuideInfo': '이용 안내',

    // 대여/결제 관리
    'Reservation.Btn.ReservationInfo': '대여내역 조회',
    'Reservation.Btn.OperationInfo': '차량관리 조회',
    'Reservation.BusinessRentalDate': '기간 선택',
    'Reservation.BusinessRentalDate.Months.Label': '{night}박 {days}일',
    'Reservation.Space.EndTime.Placeholder': '종료시간 선택',
    'Reservation.Addgoods.Table.Title': '부가상품 정보',
    'Reservation.Addgoods.Table.AddgoodsReservation': '부가상품 대여',
    'Reservation.Addgoods.Table.AddgoodsName': '부가상품명',
    'Reservation.Addgoods.Table.ReservationProfile': '대여 프로필',
    'Reservation.Addgoods.Table.DefaultAmt': '기본 금액',
    'Reservation.Addgoods.Table.DelayAmt': '지연 금액',
    'Reservation.Insurance.RewardDrivingLoss.Label': 'NOC보상',
    'Reservation.Insurance.RewardDrivingLoss.Sign.Placeholder':
        '가입/미가입 선택',
    'Reservation.ChargeInfo.AddgoodsFree': '대여요금(부가상품)',
    'Reservation.ChargeInfo.DriveSumFree': '총 대여요금',
    'Reservation.ChargeInfo.AddgoodsFree.Subtitle': '부가상품 요금',
    'Reservation.ChargeInfo.BasicAmount': '기본 금액',
    'Reservation.ChargeInfo.DelayAmount': '지연 금액',
    'Reservation.ChargeTypeCd.Space': '공간요금제',
    'Reservation.ChargeTypeCd.Camping': '캠핑요금제',
    'Reservation.Btn.ChargeInfo.AddgoodsFree.Info': '부가상품요금 조회',
    'Reservation.ChargeGroupCd.WEEKDAY': '나이트 패키지',
    'Reservation.ChargeGroupCd.WEEKEND': '주말 패키지',
    'Reservation.Extend.PickupTime.ReturnTime': '픽업/반납 시간',
    'Reservation.PlusHour': '플러스 시간 : {hour} 시간',
    'Reservation.WorkDetail.Input': '업무내용 입력',
    'Reservation.Destination.Input': '목적지 입력',
    'Reservation.ChargeGroup.Select': '요금 그룹 선택',
    'Reservation.BusinessWork.Subtitle': '비즈니스 업무 정보',
    'Reservation.ConfirmationName': '확인자명',
    'Reservation.BreathalyzerYn': '감지기 사용유무',
    'Reservation.DrinkingYn': '음주 유무',
    'Reservation.ConfirmationCheck': '그 외의 확인 방법',
    'Reservation.Instruction': '확인자 지시사항',
    'Reservation.Remark': '비고',
    'Reservation.ConfirmationName.Input': '확인자명 입력',
    'Reservation.ConfirmationCheck.Input': '그 외의 확인 방법 입력',
    'Reservation.Instruction.Input': '확인자 지시사항 입력',
    'Reservation.Remark.Input': '비고 입력',
    'Reservation.DrinkingYn.Y.Check': '음주시 대여 불가합니다.',

    // 회원
    'CustomerUser.DriverLicense.Photo': '운전 면허증 사진',
    'CustomerUser.DriverLicense.Photo.View': '사진 보기',
    'CustomerUser.DriverLicense.Msg':
        '※ 운전면허 명의자 성명, 회원명, 사진 앞뒷면을 확인 후 승인 해 주세요.',
    'CustomerUser.DriverLicense.Name': '명의자 성명',
    'CustomerUser.DriverLicense.LicenseStatusCd': '운전면허 승인상태',
    'CustomerUser.DriverLicense.LicenseNo': '면허번호',
    'CustomerUser.DriverLicense.LicnConCode': '면허 종류',
    'CustomerUser.DriverLicense.ToDate': '만료일',
    'CustomerUser.DriverLicense.RegistDate': '최초 발급일',
    'CustomerUser.DriverLicense.Type': '일본/국제 운전면허 구분',
    'CustomerUser.DriverLicense.Type.Local': '일본 운전면허',
    'CustomerUser.DriverLicense.Type.National': '국제 운전면허',
    'CustomerUser.DriverLicense.DenyReason.Placeholder':
        '반려 사유를 입력하세요.',
    'CustomerUser.LicenseStatusCd': '운전면허 승인상태',
    'CustomerUser.LicenseStatusCd.All': '운전면허 승인상태 전체',
    'CustomerUser.Passport.Photo': '여권 사진',
    'CustomerUser.Passport.Msg':
        '※ 여권 성명, 회원명, 사진을 확인 후 승인 해 주세요.',
    'CustomerUser.Passport.Name': '성명',
    'CustomerUser.Passport.PassportStatusCd': '여권 승인상태',
    'CustomerUser.Passport.PassportNo': '여권번호',

    // 결제카드
    'Card.CVC': 'CVC',
    'Card.CVC.Placeholder': '3자리 입력',
    'Card.Token.Error': '토큰 생성에 실패하였습니다.', // 임시 문구

    // 단말기
    'Terminal.Device.Provider': '단말기 공급사',
    'Terminal.WifiAccessPoint': 'WIFI Access Point(AP) Name',
    'Terminal.Audio.Update': '오디오 파일 업데이트',
    'Terminal.FOTA.Setting': '설정값',
    'Message.Confirm.Terminal.AudioUpdate':
        '오디오파일을 업데이트 하시겠습니까?',
    'Message.Success.Terminal.AudioUpdate': '오디오파일을 업데이트 하였습니다.',
    'Message.Failure.Terminal.AudioUpdate':
        '오디오파일을 업데이트 할 수 없습니다.',

    // 공통
    'Common.Jp': '일본어',
    'Common.Kr': '한국어',
    'Common.Business.Placeholder': '회원사 전체',
    'Common.FuelLevel.Percentage': '충전량',

    // 코드
    'CodeManage.Name.Placeholder.Jp': '일본어 코드명 입력',
    'CodeManage.Name.Placeholder.Kr': '한국어 코드명 입력',

    // 매출/정산관리 - 쿠폰
    'Coupon.Detail.ActualIssuedNumber.Dialog.Title':
        '쿠폰 실 발행 매수 회원조회',
    'Coupon.Detail.ActualUsedNumber.Dialog.Title': '쿠폰 실 사용 매수 회원조회',
    'Coupon.CreateDate': '발행일자',

    'Message.Validator.BusinessRegNum': '사업자 번호를 정확하게 입력하세요.',

    // 콘텐츠 관리 > 서비스 소개/배너/팝업
    'BannerMgmt.Langs.All': '언어 전체',

    // 대시보드
    'Dashboard.Maintenance.Status.Complete': '작업확인',
    'Dashboard.ChargeWarning': '잔여 충전량 부족 알림',

    // 에러코드
    523113: '잘못된 사번 입니다.',

    // 매출/정산 관리 > 미수금
    'Receivable.RemainReceivableAmtOne': '잔여미수금(円)',
    'Btn.Receivable.Exemption': '납부 면제',
    'Receivable.Dialog.Exemption.SubTitle.Complate': '미수금 납부 면제',
    'Receivable.Dialog.Exemption.Desc.1':
        '면제 금액을 입력한 후 미수금 납부 면제를 완료하세요.',
    'Receivable.Dialog.Exemption.Desc.2':
        '- 면제 금액에 따라 미수금 상태가 미납으로 유지 또는 납부 면제로 변경됩니다.',
    'Receivable.Dialog.Exemption.Desc.3': '- 납부 면제는 1회만 가능합니다.',
    'Receivable.Dialog.Exemption.Amt': '면제 금액',
    'Receivable.Dialog.Exemption.Amt.Placeholder': '면제 금액 입력',
    'Message.Receivable.Empty.Exemption.Amt': '면제 금액을 입력하세요.',
    'Receivable.Dialog.Exemption.GuideInfo1':
        '* 미수금액과 같거나 작은 값을 입력해 주세요.',
    'Receivable.Dialog.Exemption.GuideInfo2':
        '* 예) 미수금이 2,000엔인 상태에서 부분면제 금액을 500으로 입력하면 미수금이 1,500엔으로 변경됩니다.',
    'Message.Receivable.Confirm.Exemption':
        '해당 미수금을 면제 합니다.{br}면제 처리는 1회만 가능합니다.{br}이대로 진행 하시겠습니까?',
    'Receivable.RentalDelay.Day': '지연시간(분)',
    'Receivable.RentalDelay.Extra.Fee': '지연요금(円/24시간)',
    'Receivable.Occurrence.Period': '미수금 발생 기간',
    'Receivable.Exemption.Title': '면제 정보',
    'Receivable.Exemption.Date.Occurrence': '면제 처리일시',
    'Receivable.Exemption.RemainFee': '잔여 미수금',
    'Receivable.Exemption.Success.Title': '미수금 납부 면제 완료',
    'Receivable.Exemption.Success': '미수금 납부 면제가 완료되었습니다.',
    'Receivable.Return.Failure.Exemption.Title': '미수금 납부 면제 실패',
    'Receivable.Failure.Exemption':
        '미수금 납부 면제에 실패하였습니다.{br}오류코드를 확인해 주세요.',

    // 매출/정산 관리 > 매출
    'Sales.NonPaymentTime': '미납 일시',
    'Sales.ReceivableClearTime': '미수금 면제 일시',
    'Sales.AddgoodsDelay': '부가상품 지연요금',

    // 차량 관리 > 차량 등록/조회
    'Vehicle.Insurance.Interpersonal.Assurance': '대인 보증',
    'Vehicle.Insurance.InterVehicle.Assurance': '대물 보증',
    'Vehicle.Insurance.Vehicle.Assurance': '차량 보증',
    'Vehicle.Insurance.Vehicle.GuideInfo': '(NOC보상 가입시 제공)',
    'Vehicle.Insurance.BodilyHarm.Assurance': '신체 손해보증',
    'Vehicle.Insurance.Placeholder': '무제한',
    'Vehicle.Insurance.GuideInfo':
        '*차량보증은 고객이 NOC보상에 가입하는 경우 보장되며, 가입하지 않는 경우 NOC 요금이 사고처리비용을 통해 청구 됩니다.',
    'Vehicle.ModelYear': '{year}년식',

    // 매출/정산관리 > 정산
    'Calculation.RegisterDate': '정산등록일',

    // 앱 콘텐츠 관리
    'GuideLocation.Language.Type': '언어',
    'GuideLocation.Language.All': '언어 전체',
    'GuideLocation.Language.Select': '언어 설정',
    'GuideLocation.ContentType.Guide':
        '*WIFI 테더링 콘텐츠는 단말기 별로 연결하지 않고, 언어별로 가장 최근에 등록된 콘텐츠로 자동 연결되어 제공됩니다.',

    // 콘텐츠 관리 > 차종별 이용안내
    'ModelInfo.Grade.Placeholder': '차급 입력',

    // GoogleAddress Search
    'Message.Alert.Google.Address.Fail': '검색된 주소를 선택해주세요.',

    // 고객센터 > 1:1상담
    'VocMgmt.VocID.Placeholder': '상담번호 입력',
    'VocMgmt.UserName.Placeholder': '회원명 입력 또는 검색',
    'VocMgmt.TextArea.Placeholder': '3,000자 입력 가능',

    // 회원사 관리 > 회원사/계약
    'Business.Registration.Placeholder': '사업자 등록증 선택',
    'Business.BankBookCopy.Placeholder': '통장사본 선택',
    'Business.MainImage.Placeholder': '대표 이미지 선택',
    'Business.Contract.PaperReg.Placeholder': '계약서 선택',
    'Business.Name.Placeholder.CutOff': '회원사',
    'Business.Registration.Upload': '사업자 등록증 등록',
    'Business.BankBookCopy.Upload': '통장사본 등록',
    'Business.MainImage.Upload': '대표 이미지 등록',

    // 콘텐츠 관리 > 알림 템플릿
    'MsgTemplate.MessageTemplate.Placeholder': '메시지 템플릿 입력',
    'MsgTemplate.UpdateHistory.Placeholder': '수정이력 입력',
    'MsgTemplate.MessageContents.Placeholder': '메시지 내용(예시) 입력',

    'Common.UseDate.Period.Placeholder': '적용기간 선택',

    // 운영 관리 > 버전
    'AppVersion.AppVersion.Placeholder': '앱버젼 입력',
    'AppVersion.BuildVersion.Placeholder': '빌드버젼 입력',
    'AppVersion.Description.Placeholder': '앱버젼 설명 입력',
    'AppVersion.AppLink.Placeholder': '업데이트 링크 입력',
    'AppVersion.UpdateMessage.Placeholder': '업데이트 메시지 입력',
    'AppVersion.AppTypeCd.All': '앱분류 전체',
    'AppVersion.AppPlatFormCd.All': 'OS 전체',
    'AppVersion.UseYn.All': '사용여부 전체',

    // 차량관리 > 매각
    'Trade.TradeAmount.Sale.Placeholder': '매각금액 입력',
    'Trade.TradeAmount.Scrap.Placeholder': '폐차금액 입력',
    'Trade.Note.Placeholder': '비고 입력',

    // 매출/정산 관리 > 과태료
    'Violation.PenaltyFee.Input': '범칙금 입력',

    // 운영 관리 > 어드민
    'Operator.RoleSelect.All': '역할 전체',
    'Operator.StatusSelect.All': '상태 전체',
    // ----------------------- JP 07.18 번역 요청 ---------------------------------------------

    // 고객센터 > 고객센터 공지
    'Title.CSCenter.Notice.View': '고객센터 공지 상세',

    //Report Designer > 차량운행일지
    'RD.Date.Duration': '{days}일 {hours}시간 {minutes}분',

    // 고객센터 > 고객센터 공지
    'CSCenterNotice.Subject.Name': '공지명',
    'CSCenterNotice.Contents.Etc': '비고사항',
    'CSCenterNotice.Contents.Etc.Placeholder': '비고사항 입력',

    'Title.ContentMgmt.Notice.Edit': '공지 수정',

    // 콘텐츠 관리 > 서비스 소개/배너/팝업
    'BannerMgmt.SubTitle.Info': '서비스 소개/배너/팝업 등록정보',

    // 매출/정산 관리 > 쿠폰
    'Coupon.Publish.StartDate.Placeholder': '시작일시',
    'Coupon.Publish.EndDate.Placeholder': '종료일시',
    'Coupon.Use.StartDate.Placeholder': '시작일시',
    'Coupon.Use.EndDate.Placeholder': '종료일시',
    'Coupon.Detail.Affiliate.Title': '제휴쿠폰 발행',
    'Coupon.Detail.Affiliate.StartDate.Placeholder': '제휴시작 일시',
    'Coupon.Detail.Affiliate.EndDate.Placeholder': '제휴종료 일시',
    'Coupon.Validator.UseDate': '사용기간은 발급시작일보다 미래로 입력하세요.',

    // 공통
    'Message.Validator.NumUpEn': '영문 및 숫자만 입력하세요.',
    'Message.Validator.File.Extention':
        '파일형식 및 확장자명이 잘못되어 첨부가 불가합니다.',
    'Common.Login.State.Error.Title': '로그인 장애 안내',
    'Message.Login.State.Error':
        '서비스에 일시적인 문제가 있습니다. 잠시 후 다시 시도해 보세요',

    // 대여/결제 관리 > 대여 관리
    'Reservation.ChargeInfo.RentalFee.Car': '대여요금(차량)',

    // 사고수리
    'VehicleModel.Image.File.extension4': 'png, jpg 파일 업로드',

    // 매출/정산 관리 > 미수금
    'Receivable.ExemptionAmount': '미수금 면제',

    // 대여/결제관리 > 대여관리
    'Reservation.ChargeInfo.FreeDistanceCharge.Km': '무과금 주행거리',
    'Reservation.ImageTypeCdGroup.Front': '전면',
    'Reservation.ImageTypeCdGroup.Back': '후면',
    'Reservation.ImageTypeCdGroup.Left': '좌측면',
    'Reservation.ImageTypeCdGroup.Right': '우측면',
    'Reservation.ImageTypeCdGroup.More': '추가',
    'Reservation.ImageTypeCdGroup.Internal1': '내부1',
    'Reservation.ImageTypeCdGroup.Internal2': '내부2',

    // 사고수리
    'VehicleRepair.IncidentDriver.Search': '사고 운전자 검색',

    // 회원/부서관리 > 회원사 권한
    'Title.UserMgmt.BusinessOperator.List': '회원사 권한 조회',
    'BusinessOperator.Delete.Message': '가입정보가 삭제되었습니다.',

    // 차량 관리 > 단말기
    'Terminal.Audio.Version': 'Audio ver.',

    // 차량 관리 > 유지보수
    'Maintenance.Vehicle.Dialog.Title': '[스테이션 : {locationName}]',

    // 차량 관리 > 단말기
    'Terminal.InitialSetting.Dialog.Title': '단말기 장착 초기설정',

    // 회원/부서 관리 > 회원
    'CustomerUser.Button.TargetReceiver': '메시지수신대상',

    'Vehicle.Keybox.PWD': '키박스 비밀번호', // 鍵保管ボックスパスワード
    'Operator.SystemType.Company': '소속사',
    'Alert.Request.Timeout':
        '응답이 지연되고 있습니다. 잠시 후 다시 시도해 주세요.',
    'Alert.Business.Profile.Not.Found':
        '비즈니스 프로필이 없는 회원이므로, 회원사에 페널티를 부과할 수 없습니다.',
};

export default language;
